// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { FaTrash } from "react-icons/fa"; // Import Trash Icon
// import { Modal } from "react-bootstrap"; // Import Bootstrap Modal
// import { UseAuth } from "../../src/store/auth";

// const API_URL = process.env.REACT_APP_API_URL;
// const API_KEY = process.env.REACT_APP_API_KEY;

// const AdminAllTickets = () => {
//   const { authorizationToken } = UseAuth();

//   const [tickets, setTickets] = useState([]);
//   const [error, setError] = useState(null);
//   const [users, setUsers] = useState([]);
//   const [resolutionNote, setResolutionNote] = useState("");
//   const [activeTicketId, setActiveTicketId] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchTickets = async () => {
//       try {
//         const res = await axios.get(`${API_URL}/admin/tickets`, {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken, // Assuming you have the token available here
//             "Content-Type": "application/json", // Adjust as necessary
//           },
//         });
//         setTickets(res.data);
//       } catch (err) {
//         setError("Failed to load tickets");
//         toast.error("Failed to load tickets");
//       }
//     };

//     const fetchUsers = async () => {
//       try {
//         const res = await axios.get(`${API_URL}/users/support-team`, {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         });
//         setUsers(res.data);
//       } catch (err) {
//         setError("Failed to load users");
//         toast.error("Failed to load users");
//       }
//     };

//     fetchTickets();
//     fetchUsers();
//   }, [authorizationToken]); // Include authorizationToken in the dependency array if it can change

//   const handleStatusChange = async (ticketId, newStatus) => {
//     try {
//       await axios.patch(
//         `${API_URL}/admin/${ticketId}/status`,
//         { status: newStatus },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       setTickets(
//         tickets.map((ticket) =>
//           ticket._id === ticketId ? { ...ticket, status: newStatus } : ticket
//         )
//       );
//       toast.success("Status Updated");
//     } catch (err) {
//       setError("Failed to update status");
//       console.error(err);
//     }
//   };

//   const handleDelete = async (ticketId) => {
//     try {
//       await axios.delete(`${API_URL}/admin/${ticketId}`, {
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });
//       setTickets(tickets.filter((ticket) => ticket._id !== ticketId));
//       toast.success("Ticket deleted successfully");
//     } catch (err) {
//       setError("Failed to delete ticket");
//     }
//   };

//   const handleAssignTicket = async (ticketId, userId) => {
//     if (!userId) {
//       return toast.error("Please select a team member to assign the ticket.");
//     }
//     try {
//       await axios.patch(
//         `${API_URL}/${ticketId}/assign`,
//         { userId },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       toast.success("Ticket assigned successfully");
//       const updatedTickets = tickets.map((ticket) =>
//         ticket._id === ticketId ? { ...ticket, assignedTo: userId } : ticket
//       );
//       setTickets(updatedTickets);
//     } catch (err) {
//       setError("Failed to assign ticket");
//       toast.error("Failed to assign ticket");
//     }
//   };

//   const handleResolutionNoteSubmit = async (ticketId) => {
//     try {
//       await axios.patch(
//         `${API_URL}/${ticketId}/resolutionNote`,
//         { resolutionNote },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       toast.success("Resolution note updated successfully");
//       setIsModalOpen(false);
//       setResolutionNote("");
//       setActiveTicketId(null);
//     } catch (err) {
//       setError("Failed to update resolution note");
//       toast.error("Failed to update resolution note");
//     }
//   };

//   const openModal = (ticket) => {
//     setActiveTicketId(ticket._id);
//     setResolutionNote(ticket.resolutionNote || "");
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setActiveTicketId(null);
//     setResolutionNote("");
//   };

//   const handleDownload = async (attachmentPath) => {
//     try {
//       const response = await fetch(`https://tc-app-go2e.onrender.com/${attachmentPath}`, {
//         method: "GET",
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });

//       if (!response.ok) {
//         throw new Error("Failed to download the file");
//       }

//       const blob = await response.blob();
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to download the file
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", attachmentPath.split("/").pop()); // Filename
//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link); // Clean up

//       // Optionally revoke the URL after some time
//       setTimeout(() => window.URL.revokeObjectURL(url), 100);
//     } catch (error) {
//       console.error(error);
//       alert("Error downloading the file");
//     }
//   };

//   return (
//     <div className="container mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
//       <h3> Admin Ticket Dashboard</h3>
//       {error && <p className="text-red-500 mb-4">{error}</p>}
//       <table className="table table-bordered">
//         <thead>
//           <tr className="table-primary">
//             <th>Ticket Number</th>
//             <th>Issue Type</th>
//             <th>Criticality</th>
//             <th>Status</th>
//             <th>Description</th>
//             <th>Department</th>
//             <th>Attachment</th>
//             <th>Assign to</th>
//             <th>Resolution Note</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {tickets.map((ticket) => (
//             <tr key={ticket._id}>
//               <td>{ticket.ticketNumber}</td>
//               <td>{ticket.issueType}</td>
//               <td>{ticket.criticality}</td>
//               <td>
//                 <select
//                   value={ticket.status}
//                   onChange={(e) =>
//                     handleStatusChange(ticket._id, e.target.value)
//                   }
//                   className="form-select"
//                 >
//                   {ticket.status === "Validated" ? (
//                     <option value="Closed">Closed</option>
//                   ) : (
//                     <>
//                       <option value="Reopened">Reopened</option>
//                       <option value="Closed">Closed</option>
//                     </>
//                   )}
//                   <option value="Open">Open</option>
//                   <option value="WIP">WIP</option>
//                   <option value="Completed but not validated">
//                     Completed but not validated
//                   </option>
//                 </select>
//               </td>
//               <td>{ticket.description}</td>
//               <td>{ticket.department}</td>
//               <td>
//                 {ticket.attachment ? (
//                   <button
//                     onClick={() => handleDownload(ticket.attachment)}
//                     className="btn btn-link"
//                   >
//                     View
//                   </button>
//                 ) : (
//                   "No Attachment"
//                 )}
//               </td>

//               <td>
//                 <select
//                   onChange={(e) =>
//                     handleAssignTicket(ticket._id, e.target.value)
//                   }
//                   className="form-select"
//                 >
//                   <option value="">Assign to...</option>
//                   {users.map((user) => (
//                     <option key={user._id} value={user._id}>
//                       {" "}
//                       {/* Use user._id if that's where the MongoDB ObjectId is stored */}
//                       {user.name}
//                     </option>
//                   ))}
//                 </select>
//               </td>
//               <td>
//                 <button
//                   onClick={() => openModal(ticket)}
//                   className="btn btn-success mx-2"
//                 >
//                   Add Note
//                 </button>
//               </td>
//               <td>
//                 <button
//                   onClick={() => handleDelete(ticket._id)}
//                   className="btn btn-danger mx-2"
//                 >
//                   <FaTrash />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Bootstrap Modal */}
//       <Modal show={isModalOpen} onHide={closeModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add Resolution Note</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <textarea
//             value={resolutionNote}
//             onChange={(e) => setResolutionNote(e.target.value)}
//             placeholder="Enter resolution note"
//             className="form-control"
//           ></textarea>
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             onClick={() => handleResolutionNoteSubmit(activeTicketId)}
//             className="btn btn-primary"
//           >
//             Submit
//           </button>
//           <button onClick={closeModal} className="btn btn-secondary">
//             Cancel
//           </button>
//         </Modal.Footer>
//       </Modal>

//       <ToastContainer />
//     </div>
//   );
// };

// export default AdminAllTickets;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTrash } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { UseAuth } from "../../src/store/auth";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const AdminAllTickets = () => {
  const { authorizationToken } = UseAuth();

  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [resolutionNote, setResolutionNote] = useState("");
  const [activeTicketId, setActiveTicketId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Filter states
  const [issueType, setIssueType] = useState("");
  const [status, setStatus] = useState("");
  const [timePeriod, setTimePeriod] = useState("");

  useEffect(() => {
    fetchTickets();
    fetchUsers();
  }, [authorizationToken]);

  useEffect(() => {
    fetchFilteredTickets();
  }, [issueType, status, timePeriod ,tickets]);

  const fetchTickets = async () => {
    try {
      const res = await axios.get(`${API_URL}/admin/tickets`, {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
          "Content-Type": "application/json",
        },
      });
      setTickets(res.data);
      setFilteredTickets(res.data); // Initial filtered set is all tickets
    } catch (err) {
      setError("Failed to load tickets");
      toast.error("Failed to load tickets");
    }
  };

  const fetchUsers = async () => {
    try {
      const res = await axios.get(`${API_URL}/users/support-team`, {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      setUsers(res.data);
    } catch (err) {
      setError("Failed to load users");
      toast.error("Failed to load users");
    }
  };

  const fetchFilteredTickets = () => {
    let filtered = [...tickets];
  
  
    if (issueType) {
      console.log("Filtering by Issue Type:", issueType);
      filtered = filtered.filter((ticket) => ticket.issueType === issueType);
      console.log("Tickets after issue type filter:", filtered);
    }
  
 
  
    if (timePeriod) {
      const now = new Date();
      const timeLimit = new Date();
      switch (timePeriod) {
        case "monthly":
          timeLimit.setMonth(now.getMonth() - 1);
          break;
        case "3-months":
          timeLimit.setMonth(now.getMonth() - 3);
          break;
        case "6-months":
          timeLimit.setMonth(now.getMonth() - 6);
          break;
        case "yearly":
          timeLimit.setFullYear(now.getFullYear() - 1);
          break;
        default:
          break;
      }
      filtered = filtered.filter(
        (ticket) => new Date(ticket.dateOpened) >= timeLimit
      );
      console.log("Tickets after time period filter:", filtered);
    }
  
    setFilteredTickets(filtered);
    console.log("Final Filtered Tickets:", filtered);
  };
  

  const handleStatusChange = async (ticketId, newStatus) => {
    try {
      await axios.patch(
        `${API_URL}/admin/${ticketId}/status`,
        { status: newStatus },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      setTickets(
        tickets.map((ticket) =>
          ticket._id === ticketId ? { ...ticket, status: newStatus } : ticket
        )
      );
      fetchFilteredTickets(); // Re-apply filters after status change
      toast.success("Status Updated");
    } catch (err) {
      setError("Failed to update status");
      console.error(err);
    }
  };

  const handleDelete = async (ticketId) => {
    try {
      await axios.delete(`${API_URL}/admin/${ticketId}`, {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      setTickets(tickets.filter((ticket) => ticket._id !== ticketId));
      fetchFilteredTickets(); // Re-apply filters after deletion
      toast.success("Ticket deleted successfully");
    } catch (err) {
      setError("Failed to delete ticket");
    }
  };

  const handleAssignTicket = async (ticketId, userId) => {
    if (!userId) {
      return toast.error("Please select a team member to assign the ticket.");
    }
    try {
      await axios.patch(
        `${API_URL}/${ticketId}/assign`,
        { userId },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      toast.success("Ticket assigned successfully");
      const updatedTickets = tickets.map((ticket) =>
        ticket._id === ticketId ? { ...ticket, assignedTo: userId } : ticket
      );
      setTickets(updatedTickets);
      fetchFilteredTickets(); // Re-apply filters after assignment
    } catch (err) {
      setError("Failed to assign ticket");
      toast.error("Failed to assign ticket");
    }
  };

  const handleResolutionNoteSubmit = async (ticketId) => {
    try {
      await axios.patch(
        `${API_URL}/${ticketId}/resolutionNote`,
        { resolutionNote },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      toast.success("Resolution note updated successfully");
      setIsModalOpen(false);
      setResolutionNote("");
      setActiveTicketId(null);
    } catch (err) {
      setError("Failed to update resolution note");
      toast.error("Failed to update resolution note");
    }
  };

  const openModal = (ticket) => {
    setActiveTicketId(ticket._id);
    setResolutionNote(ticket.resolutionNote || "");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveTicketId(null);
    setResolutionNote("");
  };

  const handleDownload = async (attachmentPath) => {
    try {
      const response = await fetch(`https://tc-app-go2e.onrender.com/${attachmentPath}`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download the file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachmentPath.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error(error);
      alert("Error downloading the file");
    }
  };

  return (
    <div className="container mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
      <h3>Admin Ticket Dashboard</h3>
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Filter Section */}
      <div className="mb-4">
        <label>Issue Type: </label>
        <select
          value={issueType}
          onChange={(e) => setIssueType(e.target.value)}
        >
          <option value="">All</option>
          <option value="Network">Network</option>
          <option value="Software">Software</option>
          <option value="Hardware">Hardware</option>
        </select>

    

        <label>Time Period: </label>
        <select
          value={timePeriod}
          onChange={(e) => setTimePeriod(e.target.value)}
        >
          <option value="">All</option>
          <option value="monthly">Monthly</option>
          <option value="3-months">3 Months</option>
          <option value="6-months">6 Months</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr className="table-primary">
            <th>Ticket Number</th>
            <th>Issue Type</th>
            <th>Criticality</th>
            <th>Status</th>
            <th>Description</th>
            <th>Department</th>
            <th>Attachment</th>
            <th>Assign to</th>
            <th>Resolution Note</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTickets.map((ticket) => (
            <tr key={ticket._id}>
              <td>{ticket.ticketNumber}</td>
              <td>{ticket.issueType}</td>
              <td>{ticket.criticality}</td>
              <td>
                <select
                  value={ticket.status}
                  onChange={(e) =>
                    handleStatusChange(ticket._id, e.target.value)
                  }
                  className="form-select"
                >
                  <option value="Open">Open</option>
                  <option value="WIP">WIP</option>
                  <option value="Completed but not validated">
                    Completed but not validated
                  </option>
                  <option value="Closed">Closed</option>
                </select>
              </td>
              <td>{ticket.description}</td>
              <td>{ticket.department}</td>
              <td>
                {ticket.attachment ? (
                  <button
                    onClick={() => handleDownload(ticket.attachment)}
                    className="btn btn-link"
                  >
                    View
                  </button>
                ) : (
                  "No Attachment"
                )}
              </td>
              <td>
                <select
                  onChange={(e) =>
                    handleAssignTicket(ticket._id, e.target.value)
                  }
                  className="form-select"
                >
                  <option value="">Assign to...</option>
                  {users.map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <button
                  onClick={() => openModal(ticket)}
                  className="btn btn-success mx-2"
                >
                  Add Note
                </button>
              </td>
              <td>
                <button
                  onClick={() => handleDelete(ticket._id)}
                  className="btn btn-danger mx-2"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Resolution Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            value={resolutionNote}
            onChange={(e) => setResolutionNote(e.target.value)}
            placeholder="Enter resolution note"
            className="form-control"
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => handleResolutionNoteSubmit(activeTicketId)}
            className="btn btn-primary"
          >
            Submit
          </button>
          <button onClick={closeModal} className="btn btn-secondary">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default AdminAllTickets;
