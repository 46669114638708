import React from "react";
import LandingNavbar from "./LandingNavbar";
import YouTubeVideo from "./YouTubeVideo";

const LandingPage = () => {
  return (
    <div>
      <LandingNavbar />
      <div>
        {/* Hero Section */}
        <section className="py-5 text-center bg-primary text-white">
          <div className="container">
            <h1 className="display-4">
              Enhance Team Collaboration with TC App
            </h1>
            <p className="lead">
              Streamline Your Team’s Workflow and Boost Productivity
            </p>
            <a href="#features" className="btn btn-light btn-lg mx-2">
              Explore Features
            </a>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-5 " id="features">
          <div className="container mt-3">
            <h2 className="text-center">Core Features</h2>
            <div className="row mt-5">
              <div className="col-md-6">
                <h4>1. Comprehensive Task Management</h4>
                <p>
                  Easily create, assign, and track tasks within the app. Define
                  clear objectives and responsibilities to ensure every team
                  member knows their role.
                </p>
              </div>
              <div className="col-md-6">
                <h4>2. Task Deadlines & Reminders</h4>
                <p>
                  Set deadlines for each task and receive automated reminders as
                  due dates approach. It's mandatory to provide a valid reason
                  if a task isn't completed on time.
                </p>
              </div>
              <div className="col-md-6">
                <h4>3. Collaborative Workspace</h4>
                <p>
                  Work together efficiently by uploading documents and notes
                  with tasks. Super Admin can centralize all project resources
                  for easy access.
                </p>
              </div>
              <div className="col-md-6">
                <h4>4. Assign Roles and Departments</h4>
                <p>
                  After registration, team members can only log in once the
                  Super Admin assigns their role and department.
                </p>
              </div>
              <div className="col-md-6">
                <h4>5. Project Summary</h4>
                <p>
                  View assigned tasks, team members, task dates, and the current
                  status for any project in one glance.
                </p>
              </div>
              <div className="col-md-6">
                <h4>6. Reports</h4>
                <p>
                  See a summary of completed project tasks with details like who
                  was assigned which task and the current status. Reports can be
                  exported in Excel format.
                </p>
              </div>
              <div className="col-md-6">
                <h4>7. Search and Filter</h4>
                <ul>
                  <li>
                    Task Search by Assignee: Find tasks by specific team
                    members.
                  </li>
                  <li>
                    Advanced Report Filtering: Filter by department, project,
                    date, and status.
                  </li>
                  <li>
                    Keyword Search: Quickly find specific tasks or reports.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h4>8. User-Friendly Interface</h4>
                <p>
                  Enjoy a streamlined, intuitive design that simplifies task
                  management and team coordination.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Why TC App Section */}
        <section className="py-5 bg-light" id="why-tc-app">
          <div className="container">
            <h2 className="text-center">Why Choose TC App</h2>
            <p className="lead text-center">
              At TC App, we understand the importance of efficient task
              management and timely project completion. It provides all the
              tools you need to enhance team coordination, streamline task
              tracking, and meet deadlines effectively.
            </p>
          </div>
        </section>
        <YouTubeVideo videoId="h-jl59kSOlg" title="Explore TC App" />

        {/* Footer */}
        <footer className="py-4 bg-dark text-white text-center">
          <div className="container">
            <p>&copy; 2024 TC App. All Rights Reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
