import React, { useState, useEffect } from "react";
import Timer from "./Timer";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminTaskToUser.css";
import { Link } from "react-router-dom";

const TaskPage = () => {
  const [tasks, setTasks] = useState([]);
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  const [showReadMoreRepliesModal, setShowReadMoreRepliesModal] =
    useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTaskNotes, setCurrentTaskNotes] = useState([]);
  const [currentTaskNotesReplies, setCurrentTaskNotesReplies] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }
      const response = await fetch(`${API_URL}/tasks`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch tasks");
      }

      const data = await response.json();
      setTasks(data.reverse());
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleStatusChange = (taskId, status) => {
    if (status === "Completed/Not Validated") {
      if (!tasks.find((task) => task._id === taskId).note.length) {
        toast.error(
          "Please fill out a note before changing the status to Completed/Not Validated."
        );
        setCurrentTaskId(taskId);
        setShowModal(true);
        return;
      }
    }
    updateTaskStatus(taskId, status);
  };

  const updateTaskStatus = async (taskId, status) => {
    try {
      const response = await axios.put(
        `${API_URL}/tasks/${taskId}`,
        { status },
        {
          headers: {
            "x-api-key": API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update task status");
      }

      const updatedTasks = tasks.map((task) => {
        if (task._id === taskId) {
          return { ...task, status };
        }
        return task;
      });
      setTasks(updatedTasks);
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const handleModalOpen = (taskId) => {
    setCurrentTaskId(taskId);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentTaskId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const trimmedNote = note.replace(/\s+/g, "");

    if (trimmedNote.length < 50) {
      toast.error("Note must be at least 50 characters long, excluding spaces");
      return;
    }
    if (!note.trim()) {
      toast.error("Note cannot be empty ");
      return;
    }
    if (!currentTaskId) {
      console.error("Task ID is missing");
      return;
    }

    setIsSubmitting(true);

    try {
      const task = tasks.find((t) => t._id === currentTaskId);
      const assignByEmail = task?.assignedByEmail;

      if (!assignByEmail) {
        console.error("Assigned by email is missing");
        toast.error("Failed to submit note, missing assignBy email.");
        return;
      }

      const response = await axios.post(
        `${API_URL}/tasks/${currentTaskId}/note`,
        { note, assignByEmail },
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );

      toast.success("Note submitted successfully");
      setNote("");
      setShowModal(false);
      fetchTasks();
    } catch (error) {
      console.error("Error submitting note:", error);
      toast.error("Failed to submit note");
    } finally {
      setIsSubmitting(false);
    }
  };

  const sortedNotes = (notes) => {
    return notes.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const sortedNotesReplies = (noteReplies) => {
    return noteReplies
      .slice()
      .sort((a, b) => new Date(b.repliedAt) - new Date(a.repliedAt));
  };

  const getTodaysNotes = (notes) => {
    const today = new Date().toISOString().split("T")[0];
    return notes.filter((note) => note.date.startsWith(today));
  };

  const getTodaysNotesReplies = (noteReplies) => {
    const today = new Date().toISOString().split("T")[0];
    return noteReplies.filter(
      (note) => new Date(note.repliedAt).toISOString().split("T")[0] === today
    );
  };

  const handleOpenModalForReadMore = (taskId) => {
    const task = tasks.find((task) => task._id === taskId);
    setCurrentTaskId(taskId);
    setCurrentTaskNotes(task.note);
    setShowReadMoreModal(true);
  };

  const handleOpenModalForReadMoreReplies = (taskId) => {
    const task = tasks.find((task) => task._id === taskId);
    setCurrentTaskId(taskId);
    setCurrentTaskNotesReplies(task.noteReplies);
    setShowReadMoreRepliesModal(true);
  };

  const handleModalCloseReadMore = () => {
    setShowReadMoreModal(false);
    setCurrentTaskId(null);
    setCurrentTaskNotes([]);
  };
  const handleModalCloseReadMoreReplies = () => {
    setShowReadMoreRepliesModal(false);
    setCurrentTaskId(null);
    setCurrentTaskNotes([]);
  };

  const formatDate = (date) => {
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const handleReadMoreDescription = (description) => {
    setCurrentDescription(description);
    setShowDescriptionModal(true);
    setIsExpanded(true);
  };
  const handleReadLessDescription = () => {
    setIsExpanded(false);
  };
  return (
    <>
 <div>
  <Navbar />
</div>
<ToastContainer />
<div className="content">
  <h2 className="assigntask">Tasks Assigned to You</h2>
  <div style={{ overflowY: "auto" }}>
    <table className="tableFixHead2 tablecontent">
      <thead>
        <tr>
          <th>Serial No</th>
          <th>Task</th>
          <th>Description</th>
          <th>Assigned By</th>
          <th>Priority</th>
          <th>Status</th>
          <th>Deadline</th>
          <th>Write a Note</th>
          <th>Notes</th>
          <th>Note Reply from TL</th>
          <th>Time Left/Comments</th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td className="inline-table">
              <Link
                to={`/task/usertaskdetailspage/${task._id}`}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {task.task}
              </Link>
            </td>
            <td style={{ maxWidth: "300px", overflow: "hidden" }}>
              {isExpanded ? (
                <>
                  <span>{task.description}</span>
                  <button
                    onClick={handleReadLessDescription}
                    className="read-more-btn"
                  >
                    Read Less
                  </button>
                </>
              ) : (
                <>
                  <span className="ellipsis-text">{task.description}</span>
                  {task.description.length > 100 && (
                    <button
                      onClick={handleReadMoreDescription}
                      className="read-more-btn"
                    >
                      Read More
                    </button>
                  )}
                </>
              )}
            </td>
            <td>{task.assignedByName}</td>
            <td>{task.priority}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>


      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Write a Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows="4"
              cols="50"
              placeholder="Write your note here..."
            ></textarea>
            <br />
            <button type="submit" disabled={isSubmitting}>
              Submit Note
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDescriptionModal} onHide={() => setShowDescriptionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Task Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{currentDescription}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDescriptionModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showReadMoreModal} onHide={handleModalCloseReadMore}>
        <Modal.Header closeButton>
          <Modal.Title>All Notes for Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ wordWrap: "break-word" }}>
            {currentTaskNotes && currentTaskNotes.length > 0 ? (
              sortedNotes(currentTaskNotes).map((noteObj, index) => (
                <li key={index} style={{ marginBottom: "30px" }}>
                  {noteObj.note} - {formatDate(noteObj.date)}
                </li>
              ))
            ) : (
              <span>No notes available</span>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseReadMore}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showReadMoreRepliesModal} onHide={handleModalCloseReadMore}>
        <Modal.Header closeButton>
          <Modal.Title>All Notes Replies for Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ wordWrap: "break-word" }}>
            {currentTaskNotesReplies && currentTaskNotesReplies.length > 0 ? (
              sortedNotesReplies(currentTaskNotesReplies).map(
                (noteObj, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    {noteObj.reply} -{formatDate(noteObj.repliedAt)}
                  </li>
                )
              )
            ) : (
              <span>No notes Replies available</span>
            )}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseReadMoreReplies}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const textareaStyle = {
  width: "100%",
  height: "100px",
  padding: "10px",
  margin: "10px 0",
  borderRadius: "4px",
  border: "1px solid #ccc",
  fontSize: "16px",
  resize: "vertical",
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  color: "#fff",
  backgroundColor: "#000",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  transition: "background-color 0.3s",
};

export default TaskPage;
