import React, { useState } from "react";
import { NavLink, Navigate, Outlet } from "react-router-dom";
import { UseAuth } from "../store/auth";
import { FaSignOutAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const capitalizeEachWord = (string) => {
  if (typeof string !== "string" || string.length === 0) return string;
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const Admin_Layout = () => {
  const { user, isLoadding } = UseAuth();
  const [isOpen, setIsOpen] = useState(true);

  if (isLoadding) {
    return <h6 className="text-center mt-5">Loading...</h6>;
  }

  const userRoles = user.roles.map((role) => role.role);
  const hasSupportRole = userRoles.includes("SupportTeam");
  const hasAdminRole = userRoles.includes("SupportTeamAdmin");

  if (!user.isAdmin && !hasSupportRole && !hasAdminRole) {
    return <Navigate to="/logout" />;
  }

  const displayName = capitalizeEachWord(user.name);
  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="AdminPage">
        <div className="header">
          <div className="header-left">
            <img src="user-profile-image-url" alt="User Profile" className="profile-pic" />
            {isOpen && <h4 className="userlogin text-center">{displayName}</h4>}
          </div>
        </div>
      </div>
      <div className="admin-layout d-flex">
        <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
          <button className="btn toggle-btn" onClick={toggleSidebar}>
            {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </button>
          <NavLink to="/task">
            <h3 className="logo">
              {isOpen && (
                <span className="ml-2">
                  {user.isAdmin ? "Team Lead" : "Support"}{" "}
                  {user?.department?.departmentName || "No Department Available"}{" "}
                  {user?.roles?.length > 0 &&
                    `(${user.roles.map((roleObj) => roleObj.role).join(", ")})`}
                </span>
              )}
            </h3>
          </NavLink>

          <ul className="nav">
            <li>
              <NavLink to="/admin/projects" activeClassName="active">
                <span className="material-symbols-outlined" title="Projects Dashboard">
                  dashboard
                </span>
                {isOpen && <span className="ml-2">Projects Dashboard</span>}
              </NavLink>
            </li>

            <li>
              <NavLink to="/admin/users" activeClassName="active">
                <span className="material-symbols-outlined" title="Team Members">
                  groups
                </span>
                {isOpen && <span className="ml-2">Team Members</span>}
              </NavLink>
            </li>

            {user.isAdmin && (
              <>
                <li>
                  <NavLink to="/admin/taskToTeamMembers" activeClassName="active">
                    <span className="material-symbols-outlined" title="Assign Task">
                      list_alt_add
                    </span>
                    {isOpen && <span className="ml-2">Assign Task</span>}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/tasklist" activeClassName="active">
                    <span className="material-symbols-outlined" title="Task List">
                      list_alt
                    </span>
                    {isOpen && <span className="ml-2">Task List</span>}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/reports" activeClassName="active">
                    <span className="material-symbols-outlined" title="Project Reports">
                      quick_reference_all
                    </span>
                    {isOpen && <span className="ml-2">Project Reports</span>}
                  </NavLink>
                </li>
              </>
            )}

            {hasSupportRole && (
              <li>
                <NavLink to="/admin/supportteammembertickets" activeClassName="active">
                  <span className="material-symbols-outlined" title="Assigned Tickets">
                    assignment_turned_in
                  </span>
                  {isOpen && <span className="ml-2">Assigned Tickets</span>}
                </NavLink>
              </li>
            )}

            {hasAdminRole && (
              <li>
                <NavLink to="/admin/adminalltickets" activeClassName="active">
                  <span className="material-symbols-outlined" title="Admin All Tickets">
                    task_alt
                  </span>
                  {isOpen && <span className="ml-2">Admin All Tickets</span>}
                </NavLink>
              </li>
            )}
          </ul>

          <div className="logout-container">
            <NavLink to="/" className="btn text-white">
              <FaSignOutAlt title="LogOut" />
              {isOpen && <span className="ml-2">LogOut</span>}
            </NavLink>
          </div>
        </div>

        <div className="content flex-grow-1">
          <Outlet />
        </div>

        {/* Sidebar Styles */}
        <style jsx="true">{`
          .admin-layout {
            display: flex;
          }
          .sidebar {
            width: ${isOpen ? "250px" : "80px"};
            transition: width 0.3s ease;
            background-color: #163365;
            min-height: 100vh;
            padding-top: 20px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
          }
          .content {
            margin-left: ${isOpen ? "250px" : "80px"};
            padding: 20px;
            overflow-y: auto;
            width: calc(100% - ${isOpen ? "250px" : "80px"});
            min-height: 100vh;
          }
          .sidebar.closed .nav span {
            display: none;
          }
          .sidebar.open .nav span {
            display: inline;
          }
          .sidebar .nav {
            list-style-type: none;
            padding-left: 0;
          }
          .sidebar .nav li {
            margin: 10px 0;
          }
          .sidebar .nav li a {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 10px;
            color: white;
            flex-direction: row;
            position: relative;
          }
          .sidebar .nav li a:hover .material-symbols-outlined::after {
            content: attr(title);
            position: absolute;
            left: 60px;
            background: #333;
            color: #fff;
            padding: 5px;
            border-radius: 4px;
            white-space: nowrap;
            display: ${isOpen ? "none" : "block"};
          }
          .sidebar .nav li a.active {
            background-color: #0b284f;
            font-weight: bold;
          }
          .logo {
            margin-top: 50px !important;
            display: flex;
            align-items: center;
            padding: 15px;
            color: white;
          }
          .toggle-btn {
            background: none;
            border: none;
            color: white;
            font-size: 1.5rem;
            cursor: pointer;
            margin: 10px;
          }
          .logout-container {
            position: absolute;
            bottom: 20px;
            width: 100%;
            text-align: center;
          }
          .ml-2 {
            margin-left: 10px;
          }
        `}</style>
      </div>
    </>
  );
};

export default Admin_Layout;
