import React from "react";

const LandingNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container d-flex justify-content-between">
        <a className="navbar-brand" href="/">
          <img src="/TC_Logo.png" alt="TCApp Logo" width="40" height="40" className="d-inline-block align-text-top" />
         
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto d-flex align-items-center">
            <li className="nav-item">
              <a className="nav-link mx-3" href="/">
                TC App Features
              </a>
            </li>

            <li className="nav-item ms-auto">
              <a className="btn btn-outline-dark mx-3" href="/login">
                Login / Register
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default LandingNavbar;
