// import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
// import { showNotification } from "./notificationUtils";
// import { UseAuth } from "../store/auth";
// import {
//   Container,
//   Form,
//   Button,
//   Row,
//   Col,
//   ListGroup,
//   Card,
//   Modal,
//   Dropdown,
// } from "react-bootstrap";
// import Navbar from "./Navbar";
// import {
//   FaPenSquare,
//   FaTrashAlt,
//   FaStar,
//   FaShareAlt,
//   FaRegStar,
//   FaBell,
//   FaTimes,
// } from "react-icons/fa";
// import DatePicker from "react-datepicker";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
// import "react-datepicker/dist/react-datepicker.css";
// import ToDoReminder from "./ToDoReminder";
// import GroupToDoFetch from "./GroupToDoFetch";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const API_URL = process.env.REACT_APP_API_URL;
// const API_KEY = process.env.REACT_APP_API_KEY;

// export default function ToDo({ todo }) {
//   const [todos, setTodos] = useState([]);
//   const [newTodo, setNewTodo] = useState("");
//   const [newDescription, setNewDescription] = useState("");
//   const [dueDate, setDueDate] = useState(null);
//   const [currentOverdueTodo, setCurrentOverdueTodo] = useState(null);
//   const [editModalShow, setEditModalShow] = useState(false);
//   const [currentTodo, setCurrentTodo] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [dateFilter, setDateFilter] = useState("");
//   const [showReminderPicker, setShowReminderPicker] = useState(false);
//   const [currentReminder, setCurrentReminder] = useState(null);
//   const { authorizationToken } = UseAuth();
//   const [visibleTodos, setVisibleTodos] = useState(5);
//   const [showAll, setShowAll] = useState(false);
//   const [completedFilter, setCompletedFilter] = useState("all");
//   const [users, setUsers] = useState([]);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [dropdownVisible, setDropdownVisible] = useState(false);
//   const [projectname, setnewprojectname] = useState("");
//   const [Projects, setProjects] = useState([]);
//   const [searchTermtodo, setSearchTermtodo] = useState("");

//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     // Handle clicks outside the dropdown
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownVisible(false);
//       }
//     };

//     if (dropdownVisible) {
//       document.addEventListener("mousedown", handleClickOutside);
//     }

//     // Cleanup event listener on component unmount or when dropdown is closed
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [dropdownVisible]);

//   const fetchAssignedTasks = async () => {
//     try {
//       const response = await fetch(`${API_URL}/admin/tasks`, {
//         method: "GET",
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });
//       const data = await response.json();

//       // Extract unique project names
//       const uniqueProjects = [
//         ...new Set(data.map((task) => task.projectname).filter((name) => name)),
//       ];

//       setProjects(
//         uniqueProjects.map((project) => ({ value: project, label: project }))
//       );
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchAssignedTasks();
//   }, [authorizationToken]);

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get(`${API_URL}/users`, {
//         headers: {
//           "x-api-key": API_KEY,
//         },
//       });
//       setUsers(response.data.reverse());
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleUserChange = (userId) => {
//     setSelectedUsers((prevSelectedUsers) =>
//       prevSelectedUsers.includes(userId)
//         ? prevSelectedUsers.filter((id) => id !== userId)
//         : [...prevSelectedUsers, userId]
//     );
//   };
//   const handleShareTodo = async (todo) => {
//     setDropdownVisible(true);
//     if (!todo || !todo._id) {
//       toast.error("Todo object is invalid or missing _id.");
//       return;
//     }

//     if (!selectedUsers || selectedUsers.length === 0) {
//       toast.error("No users selected to share the todo.");
//       return;
//     }

//     try {
//       await axios.patch(
//         `${API_URL}/admin/todo/${todo._id}/share`,
//         { users: selectedUsers },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );

//       toast.success("Todo shared successfully!");
//       setDropdownVisible(false);
//       fetchTodos();
//     } catch (error) {
//       toast.error("Error sharing todo.");
//     }
//   };

//   const filteredUsers = users.filter((user) =>
//     user.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleToggleExpand = () => {
//     if (showAll) {
//       setVisibleTodos(5);
//     } else {
//       setVisibleTodos(filteredTodos.length);
//     }
//     setShowAll(!showAll);
//   };

//   // Fetch todos from the backend
//   const fetchTodos = async () => {
//     try {
//       const response = await axios.get(`${API_URL}/admin/todo`, {
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });
//       setTodos(response.data.reverse());
//     } catch (error) {
//       console.error("Error fetching todos:", error);
//     }
//   };

//   // Add a new todo
//   const handleAddTodo = async (e) => {
//     e.preventDefault();
//     const todoData = {
//       title: newTodo,
//       description: newDescription,
//       projectname,
//       dueDate,
//       reminder: currentReminder,
//     };

//     try {
//       await axios.post(`${API_URL}/admin/todo`, todoData, {
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });
//       toast.success("Todo added successfully!");
//       setNewTodo("");
//       setNewDescription("");
//       setDueDate(null);
//       setnewprojectname("");
//       setCurrentReminder(null);
//       fetchTodos();
//     } catch (error) {
//       toast.error("Error adding todo.");
//     }
//   };

//   // Toggle the completion status of a todo
//   const handleToggleComplete = async (id, completed) => {
//     try {
//       await axios.patch(
//         `${API_URL}/admin/todo/${id}`,
//         { completed: !completed },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       toast.success("Todo status updated!");
//       fetchTodos();
//       checkOverdueTodos();
//     } catch (error) {
//       toast.error("Error updating todo status.");
//     }
//   };

//   // Toggle the importance of a todo
//   const handleToggleImportant = async (id, important) => {
//     try {
//       await axios.patch(
//         `${API_URL}/admin/todo/${id}/imp`,
//         { important: !important },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       fetchTodos(); // Refresh the todos list after updating
//     } catch (error) {
//       console.error("Error updating todo importance:", error);
//     }
//   };

//   // Delete a todo
//   const handleDeleteTodo = async (id) => {
//     try {
//       await axios.delete(`${API_URL}/admin/todo/${id}`, {
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });
//       toast.success("Todo deleted successfully!");
//       fetchTodos();
//     } catch (error) {
//       toast.error("Error deleting todo.");
//     }
//   };

//   // Show edit modal and set current todo
//   const handleShowEditModal = (todo) => {
//     setCurrentTodo(todo);
//     setEditModalShow(true);
//   };

//   // Update a todo
//   const handleEditTodo = async (e) => {
//     e.preventDefault();
//     const updatedData = {
//       title: currentTodo.title,
//       description: currentTodo.description,
//       dueDate: currentTodo.dueDate,
//     };

//     try {
//       await axios.patch(
//         `${API_URL}/admin/todo/${currentTodo._id}/editToDo`,
//         updatedData,
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       toast.success("Todo updated successfully!");
//       fetchTodos();
//       setEditModalShow(false);
//     } catch (error) {
//       toast.error("Error updating todo.");
//     }
//   };
//   const checkOverdueTodos = () => {
//     const currentTime = new Date().getTime();
//     const overdue = todos.filter((todo) => {
//       const dueTime = new Date(todo.dueDate).getTime();
//       return currentTime > dueTime && !todo.completed;
//     });

//     overdue.forEach((todo) => {
//       showNotification(
//         "Overdue Task",
//         `The task "${todo.title}" is overdue. Please check your To-Do list.`,
//         "/TC_logo.png",
//         todo._id,
//         todo.completed,
//         todo.dueDate
//       );
//     });
//   };

//   useEffect(() => {
//     fetchTodos();
//   }, []);

//   useEffect(() => {
//     checkOverdueTodos();
//   }, [todos]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       fetchTodos();
//       checkOverdueTodos();
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   // Snooze a todo by 15 minutes
//   const handleSnoozeTodo = async (id, currentDueDate) => {
//     const newDueDate = new Date(
//       new Date(currentDueDate).getTime() + 15 * 60000
//     ); // Add 15 minutes
//     try {
//       await axios.patch(
//         `${API_URL}/admin/todo/${id}/snooze`,
//         { dueDate: newDueDate },
//         {
//           headers: {
//             "x-api-key": API_KEY,
//             Authorization: authorizationToken,
//           },
//         }
//       );
//       fetchTodos();
//       checkOverdueTodos(); // Check for the next overdue todo
//     } catch (error) {
//       console.error("Error snoozing todo", error);
//     }
//   };

//   const handleDateFilterChange = (e) => {
//     setDateFilter(e.target.value);
//   };
//   const handleCompletedFilterChange = (e) => {
//     setCompletedFilter(e.target.value);
//   };

//   const filterByDate = (todo) => {
//     if (!dateFilter) return true; // No filter applied

//     const now = new Date();
//     const dueDate = new Date(todo.dueDate);

//     switch (dateFilter) {
//       case "today":
//         return dueDate.toDateString() === now.toDateString();
//       case "week":
//         const startOfWeek = new Date();
//         startOfWeek.setDate(now.getDate() - now.getDay());
//         const endOfWeek = new Date();
//         endOfWeek.setDate(now.getDate() + (6 - now.getDay()));
//         return dueDate >= startOfWeek && dueDate <= endOfWeek;
//       case "month":
//         return (
//           dueDate.getMonth() === now.getMonth() &&
//           dueDate.getFullYear() === now.getFullYear()
//         );
//       case "quarter":
//         const startOfQuarter = new Date(
//           now.getFullYear(),
//           Math.floor(now.getMonth() / 3) * 3,
//           1
//         );
//         const endOfQuarter = new Date(
//           now.getFullYear(),
//           Math.floor(now.getMonth() / 3) * 3 + 3,
//           0
//         );
//         return dueDate >= startOfQuarter && dueDate <= endOfQuarter;
//       case "sixMonths":
//         const startOfSixMonthsAgo = new Date();
//         startOfSixMonthsAgo.setMonth(now.getMonth() - 6);
//         return dueDate >= startOfSixMonthsAgo;
//       case "year":
//         const lastYear = new Date();
//         lastYear.setFullYear(now.getFullYear() - 1);
//         return dueDate >= lastYear && dueDate <= now;
//       default:
//         return true;
//     }
//   };
//   // Filter todos based on search term and date filter
//   const filteredTodos = todos
//     .filter(
//       (todo) =>
//         todo.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         todo.description.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//     .filter(filterByDate)
//     .filter((todo) => {
//       // Filter by completion status
//       if (completedFilter === "completed") {
//         return todo.completed;
//       }
//       if (completedFilter === "incomplete") {
//         return !todo.completed;
//       }
//       return true; // "all" option
//     });

//   // Close the overdue modal and proceed to the next overdue todo
//   const handleModalClose = () => {
//     setCurrentOverdueTodo(null);
//     checkOverdueTodos(); // Update to the next overdue todo if available
//   };

//   const handleSaveReminder = (type, dateTime) => {
//     setCurrentReminder({ type, dateTime }); // Set current reminder with date and time
//   };
//   // Get the current date and time
//   const now = new Date();

//   const calculateNextReminderDate = (reminder) => {
//     const now = new Date();
//     let nextReminderDate = new Date(reminder.dateTime);

//     switch (reminder.type) {
//       case "daily":
//         nextReminderDate.setDate(nextReminderDate.getDate() + 1);
//         break;
//       case "weekly":
//         nextReminderDate.setDate(nextReminderDate.getDate() + 7);
//         break;
//       case "monthly":
//         nextReminderDate.setMonth(nextReminderDate.getMonth() + 1);
//         break;
//       case "yearly":
//         nextReminderDate.setFullYear(nextReminderDate.getFullYear() + 1);
//         break;
//       case "custom":
//         return new Date(reminder.dateTime);
//       default:
//         return null;
//     }

//     // Ensure that the calculated reminder date is always in the future
//     return nextReminderDate > now
//       ? nextReminderDate
//       : new Date(nextReminderDate.getTime() + 1); // Advance by 1 ms if the date is not in the future
//   };

//   const checkReminders = () => {
//     const now = new Date();

//     todos.forEach((todo) => {
//       if (!todo.completed && todo.reminder) {
//         const nextReminderDate = calculateNextReminderDate(todo.reminder);

//         // Define a 5-minute window around the next reminder date
//         const windowMinutes = 1; // Adjust this if needed
//         const oneMinuteBefore = new Date(
//           nextReminderDate.getTime() - windowMinutes * 60000
//         );
//         const oneMinuteAfter = new Date(
//           nextReminderDate.getTime() + windowMinutes * 60000
//         );

//         if (now >= oneMinuteBefore && now <= oneMinuteAfter) {
//           if (Notification.permission === "granted") {
//             new Notification("TC App", {
//               // Replace 'Company Name' with your company's name
//               body: `Reminder for "${todo.title}": ${todo.reminder.type} reminder.`,
//               icon: "/TC_logo.png", // Replace with the path to your company logo
//             });
//           } else {
//             console.log("Notification permission not granted");
//           }
//         } else {
//           console.log("Not within reminder window");
//         }
//       }
//     });
//   };

//   useEffect(() => {
//     if (Notification.permission !== "granted") {
//       Notification.requestPermission();
//     }
//   }, []);

//   useEffect(() => {
//     checkReminders();
//   }, [todos]);
//   // Call checkReminders periodically, e.g., every minute
//   useEffect(() => {
//     const interval = setInterval(() => {
//       checkReminders();
//     }, 60000); // Check every minute

//     return () => clearInterval(interval);
//   }, []);

//   const closeDropdown = () => {
//     setDropdownVisible(null);
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="contentmlth " >
//         <Container style={{ margin: '50px' }}>
//           <Row>
//             <Col xs={12} md={8} lg={6} className="mx-auto">
//               <Card className="p-4 shadow-sm">
//                 <Card.Title className="text-center mb-4">Todo</Card.Title>
//                 <Form onSubmit={handleAddTodo}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Todo</Form.Label>
//                     <Form.Control
//                       type="text"
//                       value={newTodo}
//                       onChange={(e) => setNewTodo(e.target.value)}
//                       placeholder="Add new todo"
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Select Existing Project</Form.Label>
//                     <Form.Control
//                       as="select"
//                       value={projectname}
//                       onChange={(e) => setnewprojectname(e.target.value)}
//                     >
//                       <option value="">Select a project</option>
//                       {Projects.map((project) => (
//                         <option key={project._id} value={project._id}>
//                           {project.label}
//                         </option>
//                       ))}
//                     </Form.Control>
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Description</Form.Label>
//                     <Form.Control
//                       type="text"
//                       value={newDescription}
//                       onChange={(e) => setNewDescription(e.target.value)}
//                       placeholder="Add description"
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Due Date</Form.Label>
//                     <DatePicker
//                       selected={dueDate}
//                       onChange={(date) => setDueDate(date)}
//                       showTimeSelect
//                       placeholderText="Set due date & time"
//                       dateFormat="dd/MM/yyyy p"
//                       className="form-control"
//                       calendarClassName="bootstrap-datepicker"
//                       minDate={now} // Set minDate if custom reminder
//                       required
//                     />
//                   </Form.Group>
//                   <div className="d-flex align-items-center">
//                     <FaBell
//                       className={`text-secondary me-5 ms-2 ${
//                         currentReminder ? "text-warning" : ""
//                       }`} // Change icon color if reminder is set
//                       onClick={() => setShowReminderPicker(true)}
//                     />
//                     <Button
//                       variant="primary"
//                       type="submit"
//                       className="w-50 me-2 mx-2"
//                     >
//                       Add Todo
//                     </Button>
//                   </div>

//                   {currentReminder && (
//                     <div className="mt-3">
//                       <h5>Reminder Set:</h5>
//                       <p>
//                         {`Type: ${currentReminder.type} | Date & Time: ${
//                           currentReminder.type === "custom"
//                             ? new Date(currentReminder.dateTime).toLocaleString(
//                                 "en-GB",
//                                 {
//                                   day: "2-digit",
//                                   month: "2-digit",
//                                   year: "2-digit",
//                                   hour: "2-digit",
//                                   minute: "2-digit",
//                                   second: "2-digit",
//                                   hour12: true,
//                                 }
//                               )
//                             : new Date(
//                                 currentReminder.dateTime
//                               ).toLocaleTimeString("en-GB", {
//                                 hour: "2-digit",
//                                 minute: "2-digit",
//                                 second: "2-digit",
//                                 hour12: true,
//                               })
//                         }`}
//                       </p>
//                     </div>
//                   )}

//                   {/* Reminder Picker Component */}
//                   <ToDoReminder
//                     show={showReminderPicker}
//                     onClose={() => setShowReminderPicker(false)}
//                     onSave={handleSaveReminder}
//                   />
//                 </Form>
//               </Card>
          
//                 <GroupToDoFetch />
           
//             </Col>
//             <Col xs={10} md={8} lg={6} className="mx-auto">
//               {/* Search input */}
//               <Row className="bigmb-4 align-items-center">
//                 <Col xs={10}>
//                   <div className="select-wrapper">
//                     <Form.Control
//                       type="text"
//                       placeholder="Search todos..."
//                       value={searchTerm}
//                       onChange={(e) => setSearchTerm(e.target.value)}
//                       className="form-control mt-5 h-50"
//                     />
//                   </div>
//                 </Col>
//                 <Col xs={10}>
//                   <div className="col-md-9 mb-1">
//                     <label className="mx-2 mb-1 mt-1" htmlFor="dateFilter">
//                       Filter by Date:
//                     </label>
//                     <div className="select-wrapper">
//                       <select
//                         id="dateFilter"
//                         onChange={handleDateFilterChange}
//                         className="form-control mt-3 "
//                       >
//                         <option value="">All Dates</option>
//                         <option value="today">Today</option>
//                         <option value="week">This Week</option>
//                         <option value="month">This Month</option>
//                         <option value="quarter">This Quarter</option>
//                         <option value="sixMonths">Last 6 Months</option>
//                         <option value="year">Last 12 Months</option>
//                       </select>
//                       <FontAwesomeIcon
//                         icon={faAngleDown}
//                         className="select-icon"
//                       />
//                     </div>
//                   </div>
//                 </Col>

//                 <Col xs={10}>
//                   <div className="col-md-9  mt-1">
//                     <label className="mx-2" htmlFor="completedFilter">
//                       Filter by Completed:
//                     </label>
//                     <div className="select-wrapper">
//                       <select
//                         id="completedFilter"
//                         onChange={handleCompletedFilterChange}
//                         className="form-control mt-3 "
//                       >
//                         <option value="all">All Todos</option>
//                         <option value="completed">Completed</option>
//                         <option value="incomplete">Incomplete</option>
//                       </select>
//                       <FontAwesomeIcon
//                         icon={faAngleDown}
//                         className="select-icon"
//                       />
//                     </div>
//                   </div>
//                 </Col>
//               </Row>

//               <ListGroup className="mt-4">
//                 {filteredTodos.slice(0, visibleTodos).map((todo) => (
//                   <ListGroup.Item
//                     key={todo._id}
//                     className="d-flex justify-content-between  align-items-center border-0 shadow-sm mb-2"
//                   >
//                     <div>
//                       <Form.Check
//                         type="checkbox"
//                         checked={todo.completed}
//                         onChange={() =>
//                           handleToggleComplete(todo._id, todo.completed)
//                         }
//                         label={
//                           <div>
//                             <h5
//                               className={`mb-0 ${
//                                 todo.completed
//                                   ? "text-decoration-line-through"
//                                   : ""
//                               }`}
//                             >
//                               {todo.title}
//                             </h5>
//                             <h6>
//                               Project Name:{" "}
//                               {todo.projectname ? todo.projectname : null}
//                             </h6>
//                             <h6 className="mb-0 text-muted">
//                               Description : {todo.description}
//                               <span
//                                 className="mx-2 text-muted"
//                                 style={{ fontSize: "0.75rem" }}
//                               >
//                                 -{" "}
//                                 {new Date(todo.createdAt).toLocaleString(
//                                   "en-GB",
//                                   {
//                                     day: "2-digit",
//                                     month: "2-digit",
//                                     year: "2-digit",
//                                     hour: "2-digit",
//                                     minute: "2-digit",
//                                     second: "2-digit",
//                                     hour12: true,
//                                   }
//                                 )}
//                               </span>
//                             </h6>
//                           </div>
//                         }
//                       />
//                     </div>

//                     <div className="">
//                       {todo.important ? (
//                         <FaStar
//                           onClick={() =>
//                             handleToggleImportant(todo._id, todo.important)
//                           }
//                           className="text-warning mx-2"
//                         />
//                       ) : (
//                         <FaRegStar
//                           onClick={() =>
//                             handleToggleImportant(todo._id, todo.important)
//                           }
//                           className="text-secondary mx-2"
//                         />
//                       )}
//                       <FaPenSquare
//                         onClick={() => handleShowEditModal(todo)}
//                         className="text-success mx-2"
//                       />
//                       <FaTrashAlt
//                         onClick={() => handleDeleteTodo(todo._id)}
//                         className="text-danger mx-2"
//                       />
//                       <FaShareAlt
//                         onClick={() =>
//                           setDropdownVisible(
//                             dropdownVisible === todo._id ? null : todo._id
//                           )
//                         }
//                         className="text-primary mx-2"
//                         style={{ cursor: "pointer" }}
//                       />
//                       <div className="dropdowntodo">
//                         {dropdownVisible === todo._id && (
//                           <div ref={dropdownRef} className="dropdowntodo">
//                             <Dropdown show>
//                               <Dropdown.Menu className="p-3">
//                                 <Button
//                                   variant="link"
//                                   onClick={() => setDropdownVisible(null)}
//                                   className="position-absolute top-0 end-0 p-2"
//                                 >
//                                   <FaTimes />
//                                 </Button>
//                                 <Dropdown.Header>
//                                   Share with Users
//                                 </Dropdown.Header>

//                                 {/* Search Bar for Users */}
//                                 <Form.Group className="mb-3">
//                                   <Form.Control
//                                     type="text"
//                                     placeholder="Search users by name"
//                                     value={searchTermtodo}
//                                     onChange={(e) =>
//                                       setSearchTermtodo(e.target.value)
//                                     }
//                                   />
//                                 </Form.Group>

//                                 {/* Filter and Display Users */}
//                                 {filteredUsers
//                                   .filter((user) =>
//                                     user.name
//                                       .toLowerCase()
//                                       .includes(searchTermtodo.toLowerCase())
//                                   )
//                                   .map((user) => (
//                                     <Dropdown.Item
//                                       key={user._id}
//                                       onClick={() => handleUserChange(user._id)}
//                                       active={selectedUsers.includes(user._id)}
//                                     >
//                                       {user.name}
//                                     </Dropdown.Item>
//                                   ))}

//                                 {/* Share Button */}
//                                 <Button
//                                   variant="primary"
//                                   className="mt-3 mx-5"
//                                   onClick={() => handleShareTodo(todo)}
//                                 >
//                                   Share
//                                 </Button>
//                               </Dropdown.Menu>
//                             </Dropdown>
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </ListGroup.Item>
//                 ))}
//               </ListGroup>
//               {filteredTodos.length > 5 && (
//                 <div className="text-center mt-3">
//                   <Button variant="link" onClick={handleToggleExpand}>
//                     {showAll ? "Show Less" : "Read More"}
//                   </Button>
//                 </div>
//               )}
//             </Col>
//           </Row>
//         </Container>
//       </div>

//       {/* Edit Todo Modal */}
//       {currentTodo && (
//         <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Edit Todo</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleEditTodo}>
//               <Form.Group className="mb-3">
//                 <Form.Label>Todo</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={currentTodo.title}
//                   onChange={(e) =>
//                     setCurrentTodo({ ...currentTodo, title: e.target.value })
//                   }
//                   placeholder="Edit todo title"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Description</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={currentTodo.description}
//                   onChange={(e) =>
//                     setCurrentTodo({
//                       ...currentTodo,
//                       description: e.target.value,
//                     })
//                   }
//                   placeholder="Edit description"
//                   required
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Due Date</Form.Label>
//                 <DatePicker
//                   selected={new Date(currentTodo.dueDate)}
//                   onChange={(date) =>
//                     setCurrentTodo({ ...currentTodo, dueDate: date })
//                   }
//                   showTimeSelect
//                   placeholderText="Edit due date & time"
//                   dateFormat="dd/MM/yyyy p"
//                   className="form-control"
//                   calendarClassName="bootstrap-datepicker"
//                 />
//               </Form.Group>
//               <Button variant="primary" type="submit" className="mx-2">
//                 Save Changes
//               </Button>
//             </Form>
//           </Modal.Body>
//         </Modal>
//       )}
//       <ToastContainer />
//     </>
//   );
// }


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { showNotification } from "./notificationUtils";
import { UseAuth } from "../store/auth";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  ListGroup,
  Card,
  Modal,
  Dropdown,
  DropdownButton,
  Table
} from "react-bootstrap";
import Navbar from "./Navbar";
import {
  FaPenSquare,
  FaTrashAlt,
  FaPen,
  FaStar,
  FaShareAlt,
  FaRegStar,
  FaBell,
  FaTimes,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import ToDoReminder from "./ToDoReminder";
import GroupToDoFetch from "./GroupToDoFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { faCalendarAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function ToDo({ todo }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [currentOverdueTodo, setCurrentOverdueTodo] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [currentTodo, setCurrentTodo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [showReminderPicker, setShowReminderPicker] = useState(false);
  const [currentReminder, setCurrentReminder] = useState(null);
  const { authorizationToken } = UseAuth();
  const [visibleTodos, setVisibleTodos] = useState(5);
  const [showAll, setShowAll] = useState(false);
  const [completedFilter, setCompletedFilter] = useState("all");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [projectname, setnewprojectname] = useState("");
  const [Projects, setProjects] = useState([]);
  const [searchTermtodo, setSearchTermtodo] = useState("");
 // State variables for reminder input
 const [reminderInput, setReminderInput] = useState('');
 const [showReminderDetails, setShowReminderDetails] = useState(false);
 const [reminderType, setReminderType] = useState('custom');
 const [reminderTime, setReminderTime] = useState(null);
  const dropdownRef = useRef(null);
  const [sharingTodoId, setSharingTodoId] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isColumnCollapsed, setIsColumnCollapsed] = useState(false);
  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  useEffect(() => {
    // Handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount or when dropdown is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const fetchAssignedTasks = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();

      // Extract unique project names
      const uniqueProjects = [
        ...new Set(data.map((task) => task.projectname).filter((name) => name)),
      ];

      setProjects(
        uniqueProjects.map((project) => ({ value: project, label: project }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignedTasks();
  }, [authorizationToken]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: {
          "x-api-key": API_KEY,
        },
      });
      setUsers(response.data.reverse());
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserChange = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };
  const handleShareTodo = async (todo) => {
    setDropdownVisible(true);
    if (!todo || !todo._id) {
      toast.error("Todo object is invalid or missing _id.");
      return;
    }

    if (!selectedUsers || selectedUsers.length === 0) {
      toast.error("No users selected to share the todo.");
      return;
    }

    try {
      await axios.patch(
        `${API_URL}/admin/todo/${todo._id}/share`,
        { users: selectedUsers },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );

      toast.success("Todo shared successfully!");
      setDropdownVisible(false);
      fetchTodos();
    } catch (error) {
      toast.error("Error sharing todo.");
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggleExpand = () => {
    if (showAll) {
      setVisibleTodos(5);
    } else {
      setVisibleTodos(filteredTodos.length);
    }
    setShowAll(!showAll);
  };

  // Fetch todos from the backend
  const fetchTodos = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/todo`, {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
 
      // Reverse the response to display in the order you need
      const todos = response.data.reverse();
      setTodos(todos);
 
      // Check for reminders that match today's date and show toast
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison
 
      todos.forEach((todo) => {
        const reminderDate = new Date(todo.reminder.dateTime);
 
        // Normalize the reminder date to midnight to compare dates
        reminderDate.setHours(0, 0, 0, 0);
 
        // Check if the reminder date matches today's date
        if (reminderDate.getTime() === today.getTime()) {
          const reminderMessage = `Reminder for today: ${todo.title} `;
         
          // Show a toast notification for the reminder
          toast.info(reminderMessage);
        }
      });
    } catch (error) {
      console.error("Error fetching todos:", error);
    }
  };
  const handleExpandColumn = () => {
    setIsColumnCollapsed(false); // To re-expand the first column when needed
  };
  // Add a new todo
  const handleAddTodo = async (e) => {
    e.preventDefault();
    setIsColumnCollapsed(true);
    const todoData = {
      title: newTodo,
      description: newDescription,
      projectname,
      dueDate,
      reminder: currentReminder,
 
    };

    try {
      await axios.post(`${API_URL}/admin/todo`, todoData, {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      toast.success("Todo added successfully!");
      setNewTodo("");
      setNewDescription("");
      setDueDate(null);
      setnewprojectname("");
      setCurrentReminder(null);
      fetchTodos();
    } catch (error) {
      toast.error("Error adding todo.");
    }
  };

  // Toggle the completion status of a todo
  const handleToggleComplete = async (id, completed) => {
    try {
      await axios.patch(
        `${API_URL}/admin/todo/${id}`,
        { completed: !completed },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      toast.success("Todo status updated!");
      fetchTodos();
      checkOverdueTodos();
    } catch (error) {
      toast.error("Error updating todo status.");
    }
  };

  // Toggle the importance of a todo
  const handleToggleImportant = async (id, important) => {
    try {
      await axios.patch(
        `${API_URL}/admin/todo/${id}/imp`,
        { important: !important },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      fetchTodos(); // Refresh the todos list after updating
    } catch (error) {
      console.error("Error updating todo importance:", error);
    }
  };

  // Delete a todo
  const handleDeleteTodo = async (id) => {
    try {
      await axios.delete(`${API_URL}/admin/todo/${id}`, {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      toast.success("Todo deleted successfully!");
      fetchTodos();
    } catch (error) {
      toast.error("Error deleting todo.");
    }
  };

  // Show edit modal and set current todo
  const handleShowEditModal = (todo) => {
    setCurrentTodo(todo);
    setEditModalShow(true);
  };

  // Update a todo
  const handleEditTodo = async (e) => {
    e.preventDefault();
    const updatedData = {
      title: currentTodo.title,
      description: currentTodo.description,
      dueDate: currentTodo.dueDate,
    };

    try {
      await axios.patch(
        `${API_URL}/admin/todo/${currentTodo._id}/editToDo`,
        updatedData,
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      toast.success("Todo updated successfully!");
      fetchTodos();
      setEditModalShow(false);
    } catch (error) {
      toast.error("Error updating todo.");
    }
  };
  const checkOverdueTodos = () => {
    const currentTime = new Date().getTime();
    const overdue = todos.filter((todo) => {
      const dueTime = new Date(todo.dueDate).getTime();
      return currentTime > dueTime && !todo.completed;
    });

    overdue.forEach((todo) => {
      showNotification(
        "Overdue Task",
        `The task "${todo.title}" is overdue. Please check your To-Do list.`,
        "/TC_logo.png",
        todo._id,
        todo.completed,
        todo.dueDate
      );
    });
  };

  useEffect(() => {
    fetchTodos();
  }, []);

  useEffect(() => {
    checkOverdueTodos();
  }, [todos]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchTodos();
      checkOverdueTodos();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  // Snooze a todo by 15 minutes
  const handleSnoozeTodo = async (id, currentDueDate) => {
    const newDueDate = new Date(
      new Date(currentDueDate).getTime() + 15 * 60000
    ); // Add 15 minutes
    try {
      await axios.patch(
        `${API_URL}/admin/todo/${id}/snooze`,
        { dueDate: newDueDate },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      fetchTodos();
      checkOverdueTodos(); // Check for the next overdue todo
    } catch (error) {
      console.error("Error snoozing todo", error);
    }
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };
  const handleCompletedFilterChange = (e) => {
    setCompletedFilter(e.target.value);
  };

  const filterByDate = (todo) => {
    if (!dateFilter) return true; // No filter applied

    const now = new Date();
    const dueDate = new Date(todo.dueDate);

    switch (dateFilter) {
      case "today":
        return dueDate.toDateString() === now.toDateString();
      case "week":
        const startOfWeek = new Date();
        startOfWeek.setDate(now.getDate() - now.getDay());
        const endOfWeek = new Date();
        endOfWeek.setDate(now.getDate() + (6 - now.getDay()));
        return dueDate >= startOfWeek && dueDate <= endOfWeek;
      case "month":
        return (
          dueDate.getMonth() === now.getMonth() &&
          dueDate.getFullYear() === now.getFullYear()
        );
      case "quarter":
        const startOfQuarter = new Date(
          now.getFullYear(),
          Math.floor(now.getMonth() / 3) * 3,
          1
        );
        const endOfQuarter = new Date(
          now.getFullYear(),
          Math.floor(now.getMonth() / 3) * 3 + 3,
          0
        );
        return dueDate >= startOfQuarter && dueDate <= endOfQuarter;
      case "sixMonths":
        const startOfSixMonthsAgo = new Date();
        startOfSixMonthsAgo.setMonth(now.getMonth() - 6);
        return dueDate >= startOfSixMonthsAgo;
      case "year":
        const lastYear = new Date();
        lastYear.setFullYear(now.getFullYear() - 1);
        return dueDate >= lastYear && dueDate <= now;
      default:
        return true;
    }
  };
  // Filter todos based on search term and date filter
  const filteredTodos = todos
    .filter(
      (todo) =>
        todo.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        todo.description.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(filterByDate)
    .filter((todo) => {
      // Filter by completion status
      if (completedFilter === "completed") {
        return todo.completed;
      }
      if (completedFilter === "incomplete") {
        return !todo.completed;
      }
      return true; // "all" option
    });

  // Close the overdue modal and proceed to the next overdue todo
  const handleModalClose = () => {
    setCurrentOverdueTodo(null);
    checkOverdueTodos(); // Update to the next overdue todo if available
  };

  const handleSaveReminder = (type, dateTime) => {
    setCurrentReminder({ type, dateTime }); // Set current reminder with date and time
  };
  // Get the current date and time
  const now = new Date();

  const calculateNextReminderDate = (reminder) => {
    const now = new Date();
    let nextReminderDate = new Date(reminder.dateTime);

    switch (reminder.type) {
      case "daily":
        nextReminderDate.setDate(nextReminderDate.getDate() + 1);
        break;
      case "weekly":
        nextReminderDate.setDate(nextReminderDate.getDate() + 7);
        break;
      case "monthly":
        nextReminderDate.setMonth(nextReminderDate.getMonth() + 1);
        break;
      case "yearly":
        nextReminderDate.setFullYear(nextReminderDate.getFullYear() + 1);
        break;
      case "custom":
        return new Date(reminder.dateTime);
      default:
        return null;
    }

    // Ensure that the calculated reminder date is always in the future
    return nextReminderDate > now
      ? nextReminderDate
      : new Date(nextReminderDate.getTime() + 1); // Advance by 1 ms if the date is not in the future
  };

  const checkReminders = () => {
    const now = new Date();

    todos.forEach((todo) => {
      if (!todo.completed && todo.reminder) {
        const nextReminderDate = calculateNextReminderDate(todo.reminder);

        // Define a 5-minute window around the next reminder date
        const windowMinutes = 1; // Adjust this if needed
        const oneMinuteBefore = new Date(
          nextReminderDate.getTime() - windowMinutes * 60000
        );
        const oneMinuteAfter = new Date(
          nextReminderDate.getTime() + windowMinutes * 60000
        );

        if (now >= oneMinuteBefore && now <= oneMinuteAfter) {
          if (Notification.permission === "granted") {
            new Notification("TC App", {
              // Replace 'Company Name' with your company's name
              body: `Reminder for "${todo.title}": ${todo.reminder.type} reminder.`,
              icon: "/TC_logo.png", // Replace with the path to your company logo
            });
          } else {
            console.log("Notification permission not granted");
          }
        } else {
          console.log("Not within reminder window");
        }
      }
    });
  };

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    checkReminders();
  }, [todos]);
  // Call checkReminders periodically, e.g., every minute
  useEffect(() => {
    const interval = setInterval(() => {
      checkReminders();
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const closeDropdown = () => {
    setDropdownVisible(null);
  };

  return (
    <>
      <Navbar />
      <div className="contentmlth " >
        <Container style={{ margin: '50px' }}>
        <Row className="mt-5 align-items-center">
      {/* Search Input */}
      <Col md={3} sm={12} className="mb-6">
        <Form.Control
          type="text"
          placeholder="Search here"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
          style={{width:'124%'}}
        />
      </Col>

      {/* Date Filter Dropdown */}
      <Col md={2} sm={12} className="mb-3" style={{marginLeft:'60px'}}>
      <DropdownButton
  title={
    <span>
      <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
      <span style={{ marginLeft: '5px' }}>Filter by Date: </span>
    </span>
  }
  variant="outline-secondary"
  className="w-100 d-flex align-items-center"
  onSelect={handleDateFilterChange} // Passes the eventKey to this function
>
  <Dropdown.Item eventKey="All Dates">All Dates</Dropdown.Item>
  <Dropdown.Item eventKey="Today">Today</Dropdown.Item>
  <Dropdown.Item eventKey="This Week">This Week</Dropdown.Item>
  <Dropdown.Item eventKey="This Month">This Month</Dropdown.Item>
  <Dropdown.Item eventKey="This Quarter">This Quarter</Dropdown.Item>
  <Dropdown.Item eventKey="Last 6 Months">Last 6 Months</Dropdown.Item>
  <Dropdown.Item eventKey="Last 12 Months">Last 12 Months</Dropdown.Item>
</DropdownButton>

      </Col>

      {/* Completed Filter Dropdown */}
      <Col md={3} sm={12} className="mb-3">
  <DropdownButton
    title={
      <span>
        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
        <span style={{ marginLeft: '5px' }}>Filter by Completed: </span>
      </span>
    }
    variant="outline-secondary"
    className="w-100 d-flex align-items-center"
    onSelect={handleCompletedFilterChange}
    style={{ width: '100%' }} // Ensure dropdown button takes full width
  >
    <Dropdown.Item eventKey="All Todos" style={{ minWidth: '100%' }}>All Todos</Dropdown.Item>
    <Dropdown.Item eventKey="Completed" style={{ minWidth: '100%' }}>Completed</Dropdown.Item>
    <Dropdown.Item eventKey="Incomplete" style={{ minWidth: '100%' }}>Incomplete</Dropdown.Item>
  </DropdownButton>
</Col>



      {/* Avatar Group */}
      <Col md={2} sm={12} className="d-flex justify-content-end mb-3">
        <div className="avatar-group d-flex align-items-center">
          <img src="" alt="Avatar" className="rounded-circle border border-white mr-1" />
          <img src="" alt="Avatar" className="rounded-circle border border-white mr-1" />
          <img src="" alt="Avatar" className="rounded-circle border border-white mr-1" />
          <div className="rounded-circle border border-white bg-secondary text-white d-flex align-items-center justify-content-center" style={{ width: '32px', height: '32px' }}>
            +2
          </div>
        </div>
      </Col>
    </Row>
    <Row className="">
        
          
<Col xs={12} sm={12} md={5} lg={3} className="column1">
  <Card className="todoscard">
    <Card.Title className="text-center mb-4">Todo</Card.Title>
    <Form onSubmit={handleAddTodo}>
      {/* Todo Input */}
      <Form.Group className="mb-3">
        <Form.Label>Todo</Form.Label>
        <Form.Control
          type="text"
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          placeholder="Add new todo"
          required
        />
      </Form.Group>

      {/* Project Selection */}
      <Form.Group className="mb-3 position-relative">
    <Form.Label>Select Existing Project</Form.Label>
    <Form.Control
      as="select"
      value={projectname}
      onChange={(e) => setnewprojectname(e.target.value)}
      className="custom-select-dropdown"
    >
      <option value="">Select a project</option>
      {Projects.map((project) => (
        <option key={project._id} value={project._id}>
          {project.label}
        </option>
      ))}
    </Form.Control>
    <i className="fa fa-chevron-down dropdown-icon"></i> {/* Dropdown arrow icon */}
  </Form.Group>

      {/* Description Input */}
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          placeholder="Add description"
          required
        />
      </Form.Group>

      {/* Due Date Picker */}
      <Form.Group className="mb-3">
  <Form.Label>Due Date</Form.Label>
  <DatePicker
    selected={dueDate}
    onChange={(date) => setDueDate(date)}
    showTimeSelect
    placeholderText="Set due date & time"
    dateFormat="dd/MM/yyyy p"
    className="form-control"
    calendarClassName="custom-datepicker" 
    minDate={now}
    required
  />
      <i className="fa fa-chevron-down dropdown-icons"></i> {/* Dropdown arrow icon */}

</Form.Group>

      {/* Remind Me Input Section */}
      <Form.Group className="mb-3">
        <Form.Label>Remind Me</Form.Label>
        <Form.Control
          type="text"
          placeholder="Set Reminder"
          value={reminderInput}
          onClick={() => setShowReminderDetails(true)} // Show reminder details when clicked
          onChange={(e) => setReminderInput(e.target.value)} // Handle input change
        />
      </Form.Group>

      {/* Reminder Details Section */}
      {showReminderDetails && (
        <div className="reminder-details mt-3">
          <Form.Group className="mb-3">
            <Form.Label>Reminder Type</Form.Label>
            <Form.Control
              as="select"
              value={reminderType}
              onChange={(e) => setReminderType(e.target.value)}
            >
              <option value="custom">Custom</option>
              <option value="time-based">Time-Based</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Reminder Time</Form.Label>
            <DatePicker
              selected={reminderTime}
              onChange={(date) => setReminderTime(date)}
              showTimeSelect
              dateFormat="dd/MM/yyyy p"
              className="form-control"
            />
          </Form.Group>

          {/* Buttons for Save and Cancel */}
          <div className="todosbuttonscontainer">
          <Button
  variant="secondary"
  onClick={() => setShowReminderDetails(false)} // Cancel reminder details
  style={{
    width: '30%', 
    marginBottom: '8px', 
    marginLeft:'50px'// Margin bottom for the Cancel button
  }}
>
  Cancel
</Button>

<Button
  variant="primary"
  onClick={handleSaveReminder} // Save reminder details
  style={{
    width: '60%', 
    marginBottom: '8px', 
    marginLeft:'30px'
  }}
>
  Save Reminder
</Button>

          </div>
        </div>
      )}

      {/* Submit Button */}
      <div className="d-flex justify-content-center mt-3">
        <Button
          variant="primary"
          type="submit"
          className="w-50 mx-2"
        >
          Add Todo
        </Button>
      </div>
    </Form>
  </Card>
</Col>


<Col xs={12} md={8} lg={8} className="column2 responsive-container">
  {/* Table wrapped in a div for scroll */}
  <div className="responsive-table-container mt-3">
    <Table className="mt-4">
      <thead className="todotable-header">
        <tr>
          <th style={{ whiteSpace: "nowrap" }}>To Do's Name</th>
          <th style={{ whiteSpace: "nowrap" }}>Project Name</th>
          <th style={{ whiteSpace: "nowrap" }}>Due Date</th>
          <th style={{ whiteSpace: "nowrap" }}>Description</th>
          <th style={{ whiteSpace: "nowrap" }}>Important</th>
          <th style={{ whiteSpace: "nowrap" }}>Action</th>
          <th style={{ whiteSpace: "nowrap" }}>Shared To-Dos</th>
        </tr>
      </thead>
      <tbody>
        {filteredTodos.slice(0, visibleTodos).map((todo) => {
          const date = new Date(todo.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          });
          const time = new Date(todo.createdAt).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }).replace(/\s/g, '').toLowerCase();

          const toggleShareInput = (id) => setSharingTodoId(sharingTodoId === id ? null : id);
          const filteredUsers = users.filter((user) =>
            user.name.toLowerCase().includes(searchTermtodo.toLowerCase())
          );

          return (
            <tr key={todo._id}>
              <td style={{ maxWidth: "150px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                <Form.Check
                  type="checkbox"
                  checked={todo.completed}
                  onChange={() => handleToggleComplete(todo._id, todo.completed)}
                  label={
                    <div className={`mb-0 ${todo.completed ? "text-decoration-line-through" : ""}`}>
                      {todo.title}
                    </div>
                  }
                />
              </td>
              <td style={{ maxWidth: "150px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                {todo.projectname || ""}
              </td>
              <td style={{ maxWidth: "100px", textAlign: "center" }}>
                <div>{date}</div>
                <div>{time}</div>
              </td>
              <td style={{ maxWidth: "200px", whiteSpace: "normal", wordWrap: "break-word", textAlign: "center" }}>
                <div style={{ maxHeight: expandedDescriptions[todo._id] ? "none" : "50px", overflow: "hidden" }}>
                  {todo.description}
                </div>
                {todo.description.length > 100 && (
                  <Button variant="link" onClick={() => toggleDescription(todo._id)} style={{ padding: 0 }}>
                    {expandedDescriptions[todo._id] ? "Read Less" : "Read More"}
                  </Button>
                )}
              </td>
              <td style={{ textAlign: "center" }}>
                {todo.important ? (
                  <FaStar onClick={() => handleToggleImportant(todo._id, todo.important)} className="text-warning" />
                ) : (
                  <FaRegStar onClick={() => handleToggleImportant(todo._id, todo.important)} className="text-secondary" />
                )}
              </td>
              <td style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", borderBottom:"none" }}>
                {/* Edit Icon (Pencil) */}
                <FaPen
                  onClick={() => handleShowEditModal(todo)}
                  className="text-black mx-2"
                  style={{ cursor: "pointer", background: "none", border: "none", padding: "0" }}
                />
                {/* Delete Icon (Trash) */}
                <FaTrashAlt
                  onClick={() => handleDeleteTodo(todo._id)}
                  className="text-black mx-2"
                  style={{ cursor: "pointer" }}
                />
              </td>
              <td style={{ maxWidth: "200px", whiteSpace: "nowrap", textAlign: "center" }}>
                {sharingTodoId === todo._id ? (
                  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '150px', overflow: 'hidden' }}>
                    <Form.Control
                      type="text"
                      placeholder="Search users by name"
                      value={searchTermtodo}
                      onChange={(e) => setSearchTermtodo(e.target.value)}
                      className="mb-2"
                    />
                    {filteredUsers.map((user) => (
                      <div key={user._id} onClick={() => handleUserChange(user._id)}>
                        {user.name}
                      </div>
                    ))}
                    <Button variant="primary" className="mt-2" onClick={() => handleShareTodo(todo)}>
                      Share
                    </Button>
                    <Button variant="link" className="mt-1" onClick={() => setSharingTodoId(null)}>
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <FaShareAlt
                    onClick={() => toggleShareInput(todo._id)}
                    className="text-primary mx-2"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </div>

  {/* Dropdown to filter Completed/Not Completed */}
  <DropdownButton
  id="dropdown-completed"
  title="Completed"
  variant="outline-secondary"
  className="w-100 mt-3"
  onSelect={handleCompletedFilterChange} // Function to handle filter change
  drop="down" // Forces dropdown to open downward
>
  <Dropdown.Item eventKey="completed">Completed</Dropdown.Item>
  <Dropdown.Item eventKey="incomplete">Not Completed</Dropdown.Item>
</DropdownButton>

</Col>
          </Row>
        </Container>
      </div>

      {/* Edit Todo Modal */}
      {currentTodo && (
        <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Todo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditTodo}>
              <Form.Group className="mb-3">
                <Form.Label>Todo</Form.Label>
                <Form.Control
                  type="text"
                  value={currentTodo.title}
                  onChange={(e) =>
                    setCurrentTodo({ ...currentTodo, title: e.target.value })
                  }
                  placeholder="Edit todo title"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={currentTodo.description}
                  onChange={(e) =>
                    setCurrentTodo({
                      ...currentTodo,
                      description: e.target.value,
                    })
                  }
                  placeholder="Edit description"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Due Date</Form.Label>
                <DatePicker
                  selected={new Date(currentTodo.dueDate)}
                  onChange={(date) =>
                    setCurrentTodo({ ...currentTodo, dueDate: date })
                  }
                  showTimeSelect
                  placeholderText="Edit due date & time"
                  dateFormat="dd/MM/yyyy p"
                  className="form-control"
                  calendarClassName="bootstrap-datepicker"
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mx-2">
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      <ToastContainer />
    </>
  );
}










