import React, { useEffect, useState } from "react";
import { UseAuth } from "../store/auth";
import "./AdminTaskToUser.css";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileHandler from "./FileHandler";
import { FaEdit, FaFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function AdminTaskList() {
  const navigate = useNavigate(); // Initialize navigate
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const { authorizationToken } = UseAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [replies, setReplies] = useState({});
  const [showArchived, setShowArchived] = useState(false); // New state for archived tasks
  const [modalImageSrc, setModalImageSrc] = useState(""); // State for the modal image
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  const [currentTaskNotes, setCurrentTaskNotes] = useState([]);
  const [error, setError] = useState("");
  const [currentTask, setCurrentTask] = useState(null);
  const [users, setUsers] = useState([]); // User options for multi-select

  useEffect(() => {
    fetchAssignedTasks();
  }, []);

  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_URL}/admin/users`, {
          method: "GET",
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        });
        const data = await response.json();
        setUsers(
          data.map((user) => ({
            value: user._id,
            label: user.name,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };


    fetchUsers();
  }, [authorizationToken]);

  const fetchAssignedTasks = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setAssignedTasks(data);
      setFilteredTasks(data.reverse());
    } catch (error) {
      setError("Failed to fetch assigned tasks.");
    }
  };

  const deleteTask = async (id) => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks/delete/${id}`, {
        method: "DELETE",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      if (response.ok) {
        setAssignedTasks((prevTasks) =>
          prevTasks.filter((task) => task._id !== id)
        );
      } else {
        setError("Failed to delete task.");
      }
    } catch (error) {
      console.log(error);
      setError("Failed to delete task.");
    }
  };
  const updateTaskPriority = async (taskId, newPriority) => {
    try {
      const response = await fetch(
        `${API_URL}/admin/tasks/updatePriority/${taskId}`,
        {
          method: "PUT",
          headers: {
            "x-api-key": API_KEY,
            "Content-Type": "application/json",
            Authorization: authorizationToken,
          },
          body: JSON.stringify({
            priority: newPriority,
          }),
        }
      );

      if (response.ok) {
        fetchAssignedTasks(); // Refresh the task list after updating
      } else {
        console.error("Failed to update priority");
        setError("Failed to update priority.");
      }
    } catch (error) {
      console.error("Error updating priority:", error.message);
      setError("Error updating priority.");
    }
  };



  const updateTaskValidationStatus = async (taskId, newValidationStatus) => {
    try {
      const response = await fetch(
        `${API_URL}/admin/tasks/${taskId}/validationStatus`,
        {
          method: "PUT",
          headers: {
            "x-api-key": API_KEY,
            "Content-Type": "application/json",
            Authorization: authorizationToken,
          },
          body: JSON.stringify({
            validationStatus: newValidationStatus,
            validationTime: new Date().toISOString(), // Record the validation time
          }),
        }
      );

      if (response.ok) {
        fetchAssignedTasks(); // Refresh the task list after updating
      } else {
        console.error("Failed to update validation status");
        setError("Failed to update validation status.");
      }
    } catch (error) {
      console.error("Error updating validation status:", error.message);
      setError("Error updating validation status.");
    }
  };
  const calculateHoursDifference = (createdAt, validatedAt) => {
    const createdOnDate = new Date(createdAt);
    const validatedOnDate = new Date(validatedAt);
    const totalMinutesDifference =
      Math.abs(validatedOnDate - createdOnDate) / 60000; // Convert milliseconds to minutes

    const hours = Math.floor(totalMinutesDifference / 60);
    const minutes = Math.floor(totalMinutesDifference % 60);

    return `${hours} hours and ${minutes} minutes`;
  };

  const archiveTask = async (id) => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks/archive/${id}`, {
        method: "PATCH",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ archived: true }),
      });
      if (response.ok) {
        fetchAssignedTasks(); // Refresh the task list after archiving
      } else {
        console.error("Failed to archive task");
        setError("Failed to archive task.");
      }
    } catch (error) {
      console.error("Error archiving task:", error.message);
      setError("Error archiving task.");
    }
  };

  const unarchiveTask = async (id) => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks/archive/${id}`, {
        method: "PATCH",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ archived: false }),
      });
      if (response.ok) {
        fetchAssignedTasks(); // Refresh the task list after unarchiving
      } else {
        console.error("Failed to unarchive task");
        setError("Failed to unarchive task.");
      }
    } catch (error) {
      console.error("Error unarchiving task:", error.message);
      setError("Error unarchiving task.");
    }
  };

  const handleImageClick = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc("");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEditTask = (task) => {
    navigate(`/admin/edit-task/${task._id}`, { state: { task } });
  };

  useEffect(() => {
    const filtered = assignedTasks.filter((task) => {
      const searchString = searchQuery.toLowerCase();
      const nameMatch = task.assignedTo.some((user) =>
        user.name.toLowerCase().includes(searchString)
      );
      const emailMatch = task.emailTo.some((email) =>
        email.toLowerCase().includes(searchString)
      );
      return (
        (nameMatch || emailMatch) &&
        (showArchived ? task.archived : !task.archived)
      );
    });
    setFilteredTasks(filtered);
  }, [searchQuery, assignedTasks, showArchived]);

  if (error) return <div>Error: {error}</div>;


  const handleReplySubmit = async (taskId) => {
    const reply = replies[taskId];

    if (!reply) {
      toast.error("Reply cannot be empty.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/task/note/${taskId}/reply`, {
        method: "POST",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify({ noteReply: reply, }),
      });

      if (response.ok) {
        toast.success("Reply submitted successfully");
        setReplies((prevReplies) => ({
          ...prevReplies,
          [taskId]: "",
        }));
      } else {
        toast.error("Failed to submit reply");
      }
    } catch (error) {
      toast.error("Error submitting reply");
    }
  };

  // Function to sort notes by date (most recent first)
  const sortedNotes = (notes) => {
    return notes.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  // Filter today's notes
  const getTodaysNotes = (notes) => {
    const today = new Date().toISOString().split("T")[0];
    return notes.filter((note) => note.date.startsWith(today));
  };

  const handleModalOpenReadMore = (notes) => {
    setCurrentTaskNotes(notes);
    setShowReadMoreModal(true);
  };

  // Function to handle closing the modal
  const handleModalCloseReadMore = () => {
    setShowReadMoreModal(false);
    setCurrentTaskNotes([]);
  };

  const handleFieldChange = (e) => {
    setCurrentTask({
      ...currentTask,
      [e.target.name]: e.target.value,
    });
  };

  const handleAssigneesChange = (selectedOptions) => {
    setCurrentTask({
      ...currentTask,
      assignedTo: selectedOptions.map((option) => ({
        _id: option.value,
        name: option.label,
      })),
    });
  };

  const handleUpdateTask = async (e) => {
    e.preventDefault();
    if (!currentTask) return;

    try {
      const response = await fetch(
        `${API_URL}/admin/tasks/${currentTask._id}`,
        {
          method: "PATCH", // Use PUT if you want to replace the entire resource
          headers: {
            "x-api-key": API_KEY,
            "Content-Type": "application/json",
            Authorization: authorizationToken,
          },
          body: JSON.stringify(currentTask),
        }
      );

      if (response.ok) {
        fetchAssignedTasks(); // Refresh task list

        toast.success("Task updated successfully");
      } else {
        throw new Error("Failed to update task");
      }
    } catch (error) {
      toast.error("Error updating task: " + error.message);
    }
  };

  // Helper function to format the date
  const formatDate = (date) => {
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  return (
    <>

      <div className="AdminUsersSection">
        <div className="AdminUsers">
          <div className="search-container">
            <span className="material-symbols-outlined">search</span>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search here"
              className="search-input"
            />
          </div>

          <button className="archived-button" onClick={() => setShowArchived(!showArchived)}>
            {showArchived ? "Show Unarchived" : "Show Archived"}
          </button>
        </div>
      </div>
      <div className="table-wrapper">
        <table className="tableFixHead2 tablecontent">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Task</th>
              <th>Description</th>
              <th>Assigned By</th>
              <th>Assignee</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Created On</th>
              <th>Due Date</th>
              <th>Hours To Complete</th>
              <th>Note</th>
              <th>Note Reply</th>
              <th>Challenges/issues</th>
              <th>Uploads</th>
              <th>Archive</th>
              <th>Actions</th>
            </tr>
          </thead>
          {filteredTasks.length > 0 ? (
            <tbody>
              {filteredTasks.map((assignedTask, index) => {
                // Calculate the difference in hours between Created On and Due Date
                const createdOnDate = new Date(assignedTask.createdAt);
                const dueDate = new Date(assignedTask.deadline);
                const hoursDifference = Math.abs(
                  (dueDate - createdOnDate) / 36e5
                ).toFixed(2); // Convert milliseconds to hours

                return (
                  <tr key={index}>
                    <td className="">{index + 1}</td>
                    <td
                      className=""
                      style={{
                        width: "50px",
                        overflow: "auto",
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => navigate(`/taskdetails/${assignedTask._id}`)}
                    >
                      {assignedTask.task}
                    </td>
                    <td
                      style={{
                        width: "50px",
                        overflow: "auto",
                        overflow: "auto",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {assignedTask.description}
                    </td>
                    <td>{assignedTask.assignedByName}</td>
                    <td>
                      {assignedTask.assignedTo
                        ? assignedTask.assignedTo
                          .map((user) => user.name)
                          .join(", ")
                        : "N/A"}
                    </td>
                    <td>
                      <select
                        value={assignedTask.priority}
                        onChange={(e) =>
                          updateTaskPriority(assignedTask._id, e.target.value)
                        }
                      >
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </select>
                    </td>
                    <td>
                      {assignedTask.status === "Completed/Not Validated" ? (
                        <select
                          value={assignedTask.validationStatus}
                          onChange={(e) =>
                            updateTaskValidationStatus(
                              assignedTask._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="validation pending">
                            Validation Pending
                          </option>
                          <option value="validated">Validated</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      ) : (
                        assignedTask.status
                      )}
                    </td>

                    <td>
                      {new Date(assignedTask.createdAt).toLocaleString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                        }
                      )}
                    </td>

                    <td>
                      {new Date(assignedTask.deadline).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </td>

                    {/* Display the hours difference here */}

                    <td>
                      {assignedTask.validatedAt && assignedTask.createdAt ? (
                        <>
                          {calculateHoursDifference(
                            assignedTask.createdAt,
                            assignedTask.validatedAt
                          )}{" "}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>

                    <td
                      style={{
                        minWidth: "200px",
                        maxWidth: "200px",
                        overflow: "auto",
                        wordWrap: "break-word",
                      }}
                    >
                      <div>
                        {assignedTask.note && assignedTask.note.length > 0 ? (
                          <>
                            <ul>
                              {getTodaysNotes(
                                sortedNotes(assignedTask.note)
                              ).map((noteObj, index) => (
                                <li key={index}>
                                  {noteObj.note} - {formatDate(noteObj.date)}
                                </li>
                              ))}
                            </ul>
                            <p
                              onClick={() =>
                                handleModalOpenReadMore(assignedTask.note)
                              }
                              className="replybtn"
                            >
                              Read More
                            </p>
                          </>
                        ) : (
                          <span>No notes</span>
                        )}
                      </div>
                    </td>
                    <td style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <textarea
                        value={replies[assignedTask._id] || ""}
                        onChange={(e) =>
                          setReplies({
                            ...replies,
                            [assignedTask._id]: e.target.value,
                          })
                        }
                        placeholder="Reply to Note"
                        style={{
                          backgroundColor: '#f0f0f0',
                          fontFamily: 'Roboto, sans-serif',
                          fontSize: '16px',
                          color: '#000',
                          border: 'none',
                          outline: 'none',
                          padding: '10px',
                          borderRadius: '4px'
                        }}
                      />
                      <button
                        onClick={() => handleReplySubmit(assignedTask._id)}
                        className="replybutton"
                      >
                        Reply
                      </button>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        wordWrap: "break-word",
                      }}
                    >
                      {assignedTask.completionNote}
                    </td>
                    <td>
                      {assignedTask.files && assignedTask.files.length > 0
                        ? assignedTask.files.map((file, fileIndex) => (
                          <FileHandler
                            key={fileIndex}
                            file={file}
                            index={fileIndex}
                            handleImageClick={handleImageClick}
                          />
                        ))
                        : "No files"}
                    </td>

                    <td>
                      {assignedTask.archived ? (
                        <span
                          className="material-symbols-outlined"
                          onClick={() => unarchiveTask(assignedTask._id)}
                        >
                          unarchive
                        </span>
                      ) : (
                        <span
                          className="material-symbols-outlined"
                          onClick={() => archiveTask(assignedTask._id)}
                        >
                          archive
                        </span>
                      )}
                    </td>
                    <td>
                      <>
                        <span
                          className="material-symbols-outlined"
                          onClick={() => deleteTask(assignedTask._id)}
                        >
                          delete
                        </span>
                        <span>
                          <FaEdit
                            style={{
                              cursor: "pointer",
                              color: "black",
                              fontSize: "18px",
                              marginTop: "-15px",
                              marginLeft: "15px",
                            }}
                            onClick={() => handleEditTask(assignedTask)}
                          />
                        </span>
                      </>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <div>No tasks assigned.</div>
          )}
        </table>
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <span className="close-btn" onClick={closeModal}>
                &times;
              </span>
              <img src={modalImageSrc} alt="Enlarged" className="modal-image" />
            </div>
          </div>
        )}
        <Modal show={showReadMoreModal} onHide={handleModalCloseReadMore}>
          <Modal.Header closeButton>
            <Modal.Title>All Notes for Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul style={{ wordWrap: "break-word" }}>
              {currentTaskNotes && currentTaskNotes.length > 0 ? (
                sortedNotes(currentTaskNotes).map((noteObj, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    {noteObj.note} - {formatDate(noteObj.date)}
                  </li>
                ))
              ) : (
                <span>No notes available</span>
              )}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button className="closebutton" onClick={handleModalCloseReadMore}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
