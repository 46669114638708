// import React, { useState } from "react";
// import TicketRaiseForm from "./TicketRaiseForm";
// import TicketTable from "./TicketTable";
// import { ToastContainer } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
// import Navbar  from "../Navbar";

// const Udash = () => {
//   const [showTickets, setShowTickets] = useState(false);
//   const [showRaiseForm, setShowRaiseForm] = useState(false);
//   const [ticketToEdit, setTicketToEdit] = useState(null);

//   const handleToggleTickets = () => {
//     setShowTickets((prevShowTickets) => !prevShowTickets);
//   };

//   const handleToggleRaiseForm = () => {
//     setTicketToEdit(null); // Reset when creating a new ticket
//     setShowRaiseForm((prevShowRaiseForm) => !prevShowRaiseForm);
//   };

//   const handleEditTicket = (ticket) => {
//     setTicketToEdit(ticket);
//     setShowRaiseForm(true);
//   };

//   return (
//     <><Navbar/>
//     <div className="d-flex h-100 ">
      
//       <div className="flex-grow-1 p-5 bg-light  tikcetraiseform">
//         <ToastContainer />
//         <div className="d-flex mb-4 px-5">
//           <button
//             onClick={handleToggleRaiseForm}
//             className="btn btn-success me-3"
//           >
//             {showRaiseForm ? "Hide Ticket Form" : "Create Ticket"}
//           </button>

//           <button
//             onClick={handleToggleTickets}
//             className="btn btn-primary px-3 mx-2"
//           >
//             {showTickets ? "Hide Your Tickets" : "Show Your Tickets"}
//           </button>
//         </div>

//         {/* Ticket Table */}
//         {showTickets && <TicketTable onEditTicket={handleEditTicket} />}

       
//         {showRaiseForm && (
//           <div className="bg-white rounded shadow p-4 w-100 max-w-75 ">
//             <div className="d-flex justify-content-between align-items-center mb-3">
//               <h2 className="h5 fw-bold">{ticketToEdit ? "Edit Ticket" : "Raise a New Ticket"}</h2>
//             </div>
//             <TicketRaiseForm onClose={handleToggleRaiseForm} ticketToEdit={ticketToEdit} />
//           </div>
//         )}
//       </div>
//     </div></>
//   );
// };

// export default Udash;


import React, { useState } from "react";
import TicketRaiseForm from "./TicketRaiseForm";
import TicketTable from "./TicketTable";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbar";

const Udash = () => {
  const [showTickets, setShowTickets] = useState(false);
  const [showRaiseForm, setShowRaiseForm] = useState(false);
  const [ticketToEdit, setTicketToEdit] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleToggleTickets = () => {
    setShowTickets(!showTickets);
  };

  const handleSubmitTicket = () => {
    // Trigger form submission and adjust layout
    setIsSubmitted(true); // Set isSubmitted to true after submission
  };

  const handleToggleRaiseForm = () => {
    setShowRaiseForm(!showRaiseForm);
    if (!showRaiseForm) {
      // Reset the isSubmitted state when the form is reopened
      setIsSubmitted(false);
    }
  };

  const handleEditTicket = (ticket) => {
    setTicketToEdit(ticket);
    setShowRaiseForm(true);
    setIsSubmitted(false); // Reset isSubmitted when editing
  };

  return (
    <>
      <Navbar />
      <div className="d-flex h-100">
        <div className="flex-grow-1 p-5 bg-light tikcetraiseform">
          <ToastContainer />

          {/* Buttons Container */}
          <div className="buttonscontainers mb-3">
            <div className="row">
              <div className="col-6 d-flex justify-content-end">
                <button onClick={handleToggleRaiseForm} className="createticket">
                  {showRaiseForm ? "Hide Ticket Form" : "Create Ticket"}
                </button>
              </div>
              <div className="col-6 d-flex justify-content-start">
                <button onClick={handleToggleTickets} className="showticket">
                  {showTickets ? "Hide Your Tickets" : "Show Your Tickets"}
                </button>
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="row" style={{ width: '100%', margin: '0 auto' }}>
            {/* Ticket Raise Form Column */}
            {showRaiseForm && (
              <div className={isSubmitted ? "col-md-3" : "col-md-5"}>
                <div className="bg-white rounded w-100">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2 className="h5 fw-bold">
                      {ticketToEdit ? "" : "Create Ticket"}
                    </h2>
                  </div>
                  <TicketRaiseForm
                    onClose={handleToggleRaiseForm}
                    onSubmit={handleSubmitTicket} // Pass the submit handler here
                    ticketToEdit={ticketToEdit}
                  />
                </div>
              </div>
            )}

            {/* Ticket Table Column */}
            {showTickets && (
              <div className={isSubmitted ? "col-md-9" : "col-md-7"} style={{ overflow: "hidden" }}>
                <div style={{ maxWidth: "100%", overflowX: "auto", paddingBottom: "1px" }}>
                  <TicketTable onEditTicket={handleEditTicket} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Udash;
