// import React, { useContext, useState, useEffect } from "react";
// import { UseAuth } from "../../store/auth";
// import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;
// const API_KEY = process.env.REACT_APP_API_KEY;

// const TicketRaiseForm = ({ onClose, ticketToEdit }) => {
//   const { authorizationToken } = UseAuth();

//   const [ticketDetails, setTicketDetails] = useState({
//     issueType: ticketToEdit?.issueType || "",
//     description: ticketToEdit?.description || "",
//     criticality: ticketToEdit?.criticality || "",
//     notes: ticketToEdit?.notes || "",
//     department: ticketToEdit?.department || "",
//     attachment: null,
//   });

//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setTicketDetails({ ...ticketDetails, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     setTicketDetails({ ...ticketDetails, attachment: e.target.files[0] });
//   };

//   useEffect(() => {
//     if (successMessage) {
//       const timer = setTimeout(() => {
//         setSuccessMessage("");
//       }, 2000);

//       return () => clearTimeout(timer);
//     }
//   }, [successMessage]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("issueType", ticketDetails.issueType);
//     formData.append("description", ticketDetails.description);
//     formData.append("criticality", ticketDetails.criticality);
//     formData.append("notes", ticketDetails.notes);
//     formData.append("department", ticketDetails.department);
//     if (ticketDetails.attachment) {
//       formData.append("attachment", ticketDetails.attachment);
//     }
  
//     try {
//       const config = {
//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//           "Content-Type": "multipart/form-data", // Important for file uploads
//         },
//       };
  
//       let res;
//       if (ticketToEdit) {
//         res = await axios.put(`${API_URL}/api/tickets/${ticketToEdit._id}`, formData, config);
//       } else {
//         res = await axios.post(`${API_URL}/create`, formData, config);
//       }
  
//       if (res.status === 200 || res.status === 201) {
//         setSuccessMessage(ticketToEdit ? "Ticket updated successfully!" : "Ticket submitted successfully!");
//         setErrorMessage("");
//       } else {
//         throw new Error(`Unexpected status code: ${res.status}`);
//       }
//     } catch (err) {
//       console.error("Error submitting ticket:", err);
//       setErrorMessage("Error submitting ticket. Please try again.");
//       setSuccessMessage("");
//     }
//   };
  

//   return (
//     <div className="modal-content p-4 shadow-sm ">
//       <h1 className="modal-title fs-4 text-center mb-4">
//         {ticketToEdit ? "Edit Ticket" : "Raise a New Ticket"}
//       </h1>

//       <form onSubmit={handleSubmit} className="row g-3">
//         {/* Issue Type */}
//         <div className="col-md-6">
//           <label className="form-label">Issue Type</label>
//           <select
//             name="issueType"
//             value={ticketDetails.issueType}
//             onChange={handleInputChange}
//             className="form-select"
//             required
//           >
//             <option value="" disabled>
//               Select issue type
//             </option>
//             <option value="Hardware">Hardware</option>
//             <option value="Software">Software</option>
//             <option value="Network">Network</option>
//             <option value="Other">Other</option>
//           </select>
//         </div>

//         {/* Criticality */}
//         <div className="col-md-6">
//           <label className="form-label">Criticality</label>
//           <select
//             name="criticality"
//             value={ticketDetails.criticality}
//             onChange={handleInputChange}
//             className="form-select"
//           >
//             <option value="Low">Low</option>
//             <option value="Medium">Medium</option>
//             <option value="High">High</option>
//           </select>
//         </div>

//         {/* Department */}
//         <div className="col-md-6">
//           <label className="form-label">Department</label>
//           <select
//             name="department"
//             value={ticketDetails.department}
//             onChange={handleInputChange}
//             className="form-select"
//             required
//           >
//             <option value="" disabled>
//               Select department
//             </option>
//             <option value="HR">HR</option>
//             <option value="Development Team">Development Team</option>
//             <option value="IBP">IBP</option>
//             <option value="Digital Marketing">Digital Marketing</option>
//           </select>
//         </div>

//         {/* Description */}
//         <div className="col-md-12">
//           <label className="form-label">Description</label>
//           <textarea
//             name="description"
//             value={ticketDetails.description}
//             onChange={handleInputChange}
//             placeholder="Describe the issue"
//             className="form-control"
//             required
//           ></textarea>
//         </div>

//         {/* Notes */}
//         <div className="col-md-12">
//           <label className="form-label">Additional Notes</label>
//           <textarea
//             name="notes"
//             value={ticketDetails.notes}
//             onChange={handleInputChange}
//             placeholder="Add any notes (optional)"
//             className="form-control"
//           ></textarea>
//         </div>

//         {/* File Upload */}
//         <div className="col-md-12">
//           <label className="form-label">Attach File (optional)</label>
//           <input
//             type="file"
//             onChange={handleFileChange}
//             className="form-control"
//           />
//         </div>

//         {/* Submit Button */}
//         <div className="col-md-12 text-center">
//           <button type="submit" className="btn btn-primary">
//             {ticketToEdit ? "Update Ticket" : "Submit Ticket"}
//           </button>
//         </div>
//       </form>

//       {/* Success and Error Messages */}
//       {successMessage && (
//         <div className="mt-4 text-success text-center">{successMessage}</div>
//       )}
//       {errorMessage && (
//         <div className="mt-4 text-danger text-center">{errorMessage}</div>
//       )}
//     </div>
//   );
// };

// export default TicketRaiseForm;

import React, { useState, useEffect } from "react";
import { UseAuth } from "../../store/auth";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const TicketRaiseForm = ({ onClose, ticketToEdit, onSubmit }) => {
  const { authorizationToken } = UseAuth();

  const [ticketDetails, setTicketDetails] = useState({
    issueType: ticketToEdit?.issueType || "",
    description: ticketToEdit?.description || "",
    criticality: ticketToEdit?.criticality || "",
    notes: ticketToEdit?.notes || "",
    department: ticketToEdit?.department || "",
    attachment: null,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
  };

  const handleFileChange = (e) => {
    setTicketDetails({ ...ticketDetails, attachment: e.target.files[0] });
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("issueType", ticketDetails.issueType);
    formData.append("description", ticketDetails.description);
    formData.append("criticality", ticketDetails.criticality);
    formData.append("notes", ticketDetails.notes);
    formData.append("department", ticketDetails.department);
    if (ticketDetails.attachment) {
      formData.append("attachment", ticketDetails.attachment);
    }

    try {
      const config = {
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      };

      let res;
      if (ticketToEdit) {
        res = await axios.put(`${API_URL}/api/tickets/${ticketToEdit._id}`, formData, config);
      } else {
        res = await axios.post(`${API_URL}/create`, formData, config);
      }

      if (res.status === 200 || res.status === 201) {
        setSuccessMessage(ticketToEdit ? "Ticket updated successfully!" : "Ticket submitted successfully!");
        setErrorMessage("");
        onSubmit(); // Call the parent submit handler to adjust layout
      } else {
        throw new Error(`Unexpected status code: ${res.status}`);
      }
    } catch (err) {
      console.error("Error submitting ticket:", err);
      setErrorMessage("Error submitting ticket. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="modal-content p-4 shadow-sm">
      <h1 className="modal-title fs-4 text-center mb-4">
        {ticketToEdit ? "Edit Ticket" : "Raise a New Ticket"}
      </h1>

      <form onSubmit={handleSubmit} className="row g-3">
  {/* Issue Type */}
  <div className="col-12">
    <label className="form-label">Issue Type</label>
    <select
      name="issueType"
      value={ticketDetails.issueType}
      onChange={handleInputChange}
      className="form-select w-100"
      required
    >
      <option value="" disabled>Select issue type</option>
      <option value="Hardware">Hardware</option>
      <option value="Software">Software</option>
      <option value="Network">Network</option>
      <option value="Other">Other</option>
    </select>
  </div>

  {/* Criticality */}
  <div className="col-12">
    <label className="form-label">Criticality</label>
    <select
      name="criticality"
      value={ticketDetails.criticality}
      onChange={handleInputChange}
      className="form-select w-100"
    >
      <option value="Low">Low</option>
      <option value="Medium">Medium</option>
      <option value="High">High</option>
    </select>
  </div>

  {/* Department */}
  <div className="col-12">
    <label className="form-label">Department</label>
    <select
      name="department"
      value={ticketDetails.department}
      onChange={handleInputChange}
      className="form-select w-100"
      required
    >
      <option value="" disabled>Select department</option>
      <option value="HR">HR</option>
      <option value="Development Team">Development Team</option>
      <option value="IBP">IBP</option>
      <option value="Digital Marketing">Digital Marketing</option>
    </select>
  </div>

  {/* Description */}
  <div className="col-12">
    <label className="form-label">Description</label>
    <textarea
      name="description"
      value={ticketDetails.description}
      onChange={handleInputChange}
      className="form-control w-100"
      rows="3"
      required
    ></textarea>
  </div>

  {/* Additional Notes */}
  <div className="col-12">
    <label className="form-label">Additional Notes (Optional)</label>
    <textarea
      name="notes"
      value={ticketDetails.notes}
      onChange={handleInputChange}
      className="form-control w-100"
      rows="3"
    ></textarea>
  </div>

  {/* Attachment */}
  <div className="col-12">
    <label className="form-label">Attachment (Optional)</label>
    <input
      type="file"
      onChange={handleFileChange}
      className="form-control w-100"
    />
  </div>

  {/* Success or Error Message */}
  {successMessage && <div className="alert alert-success col-12 w-100">{successMessage}</div>}
  {errorMessage && <div className="alert alert-danger col-12 w-100">{errorMessage}</div>}

  {/* Submit Button */}
  <div className="col-12">
    <button type="submit" className="btn btn-primary w-100">
      {ticketToEdit ? "Update Ticket" : "Submit Ticket"}
    </button>
  </div>
</form>


    </div>
  );
};

export default TicketRaiseForm;
