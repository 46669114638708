import React, { useEffect, useState } from "react";
import axios from "axios";
import { UseAuth } from "../../store/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const TicketTable = ({ onEditTicket }) => {
  const { user, authorizationToken } = UseAuth();
  const [tickets, setTickets] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${API_URL}/my-tickets`, {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        });
        setTickets(
          response.data.sort(
            (a, b) => new Date(b.dateOpened) - new Date(a.dateOpened)
          )
        );
      } catch (err) {
        setError("Error fetching tickets");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [authorizationToken, API_KEY]);

  const calculateTargetDate = (dateOpened, criticality) => {
    const daysToAdd = criticality === "High" ? 1 : criticality === "Medium" ? 2 : 3;
    const targetDate = new Date(dateOpened);
    targetDate.setDate(targetDate.getDate() + daysToAdd);
    return targetDate.toLocaleDateString("en-GB");
  };

  const updateTicketStatus = async (ticketId, newStatus) => {
    try {
      const response = await axios.patch(
        `${API_URL}/admin/${ticketId}/status`,
        { status: newStatus },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      setTickets(
        tickets.map((ticket) =>
          ticket._id === ticketId
            ? { ...ticket, status: response.data.status }
            : ticket
        )
      );
      toast.success(
        `Ticket status updated to "${response.data.status}" successfully!`
      );
    } catch (err) {
      console.error("Error updating ticket status", err);
      toast.error("Failed to update ticket status.");
    }
  };

  if (loading) return <div className="text-center py-6">Loading...</div>;
  if (error) return <div className="text-center py-6 text-danger">{error}</div>;

  return (
    <div className="table-responsive  rounded">
      <table className="table table-striped table-hover table-bordered">
        <thead className="table-light">
          <tr>
            <th>Ticket Number</th>
            <th>Issue Type</th>
            <th>Description</th>
            <th>Department</th>
            <th>Criticality</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Target Completion Date</th>
            <th>Actions</th>
            <th>Resolution Note</th>
            <th>Resolution Date</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) => (
            <tr key={ticket.ticketNumber}>
              <td>{ticket.ticketNumber}</td>
              <td>{ticket.issueType}</td>
              <td>{ticket.description}</td>
              <td>{ticket.department}</td>
              <td>{ticket.criticality}</td>
              <td>{ticket.status}</td>
              <td>
                {new Date(ticket.dateOpened).toLocaleDateString("en-GB")}{" "}
                {new Date(ticket.dateOpened).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })}
              </td>
              <td>{calculateTargetDate(ticket.dateOpened, ticket.criticality)}</td>
              <td>
                <button
                  onClick={() => onEditTicket(ticket)}
                  className="btn btn-warning btn-sm mx-2"
                >
                  Edit
                </button>
                {ticket.status === "Completed but not validated" && (
                  <div className="mt-2">
                    <button
                      onClick={() =>
                        updateTicketStatus(ticket._id, "Validated")
                      }
                      className="btn btn-success btn-sm mx-2"
                    >
                      Validate
                    </button>
                    <button
                      onClick={() => updateTicketStatus(ticket._id, "Reopened")}
                      className="btn btn-danger btn-sm mx-2 mt-1"
                    >
                      Reopen
                    </button>
                  </div>
                )}
              </td>
              <td>{ticket.resolutionNote}</td>
              <td>
                {new Date(ticket.dateResolved).toLocaleDateString("en-GB")}{" "}
                {new Date(ticket.dateResolved).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default TicketTable;
