import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Timer from "./Timer";
import Navbar from './Navbar'


const UserTaskDetails = () => {
    const { taskId } = useParams(); // Get taskId from URL
    const [tasks, setTasks] = useState([]); // Initialize tasks as an empty array
    const [showModal, setShowModal] = useState(false);
    const [note, setNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [currentTaskId, setCurrentTaskId] = useState(null);

    const API_URL = process.env.REACT_APP_API_URL;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const trimmedNote = note.replace(/\s+/g, ""); // Remove all whitespace characters

        if (trimmedNote.length < 50) {
            toast.error("Note must be at least 50 characters long, excluding spaces");
            return;
        }
        if (!note.trim()) {
            toast.error("Note cannot be empty ");
            return;
        }
        if (!currentTaskId) {
            console.error("Task ID is missing");
            return;
        }

        setIsSubmitting(true);

        try {
            const task = tasks.find((t) => t._id === currentTaskId);
            const assignByEmail = task?.assignedByEmail;

            if (!assignByEmail) {
                console.error("Assigned by email is missing");
                toast.error("Failed to submit note, missing assignBy email.");
                return;
            }

            const response = await axios.post(
                `${API_URL}/tasks/${currentTaskId}/note`,
                { note, assignByEmail },  // Add assignByEmail to request body
                {
                    headers: {
                        "x-api-key": API_KEY,
                    },
                }
            );

            toast.success("Note submitted successfully");
            setNote("");
            setShowModal(false);

        } catch (error) {
            console.error("Error submitting note:", error);
            toast.error("Failed to submit note");
        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        const fetchTaskDetails = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    throw new Error("Token not found");
                }

                const response = await axios.get(`${API_URL}/tasks`, { // Assuming this fetches a list of tasks
                    headers: {
                        "x-api-key": API_KEY,
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status !== 200) {
                    throw new Error("Failed to fetch task details");
                }

                setTasks(response.data); // Set tasks to the response data (assuming it's an array)
            } catch (error) {
                console.error("Error fetching task details:", error);
                toast.error("Failed to fetch task details.");
            }
        };

        fetchTaskDetails();
    }, [API_URL, API_KEY]);

    if (tasks.length === 0) {
        // If no tasks are fetched, show loading message
        return <div>Loading tasks...</div>;
    }

    const formatDate = (date) => {
        const options = {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        return new Date(date).toLocaleDateString("en-GB", options);
    };
    const updateTaskStatus = async (taskId, status) => {
        try {
            const response = await axios.put(
                `${API_URL}/tasks/${taskId}`,
                { status },
                {
                    headers: {
                        "x-api-key": API_KEY,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to update task status");
            }

            // Update the tasks list
            const updatedTasks = tasks.map((task) => {
                if (task._id === taskId) {
                    return { ...task, status };
                }
                return task;
            });
            setTasks(updatedTasks);
        } catch (error) {
            console.error("Error updating task status:", error);
        }
    };
    const handleStatusChange = (taskId, status) => {
        if (status === "Completed/Not Validated") {
            if (!tasks.find((task) => task._id === taskId).note.length) {
                toast.error(
                    "Please fill out a note before changing the status to Completed/Not Validated."
                );
                setCurrentTaskId(taskId);
                setShowModal(true);
                return;
            }
        }
        updateTaskStatus(taskId, status);
    };
    const handleModalOpen = (taskId) => {
        setCurrentTaskId(taskId);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setCurrentTaskId(null);
    };


    return (

        <>
            <Navbar />
            <ToastContainer />
            <div className="container user-task-details">
                <h2 className="my-4">Task Details</h2>
                {tasks.map((task) => (
                    <div key={task.id} className="row task-card mb-4 p-4 border rounded">
                        {/* Task Info Section */}
                        <div className="col-md-8">
                            <div className="task-info">
                                <p><strong>Task Name:</strong> {task.task}</p>
                                <p><strong>Description:</strong> {task.description}</p>
                                <p><strong>Assigned By:</strong> {task.assignedByName}</p>
                                <p><strong>Priority:</strong> {task.priority}</p>
                                <p><strong>Status:</strong> {task.status}</p>
                                <p><strong>Deadline:</strong> {formatDate(task.deadline)}</p>
                            </div>
                        </div>
                        <td>
                            {task.status === 'Completed/Not Validated' ? (
                                <>
                                    {task.validationStatus === 'rejected' ? (
                                        <>
                                            <div className="message" style={{ color: 'orange' }}>
                                                admin is validating your task again, please do another task
                                            </div>
                                            <select
                                                value={task.status}
                                                onChange={(e) => handleStatusChange(task._id, e.target.value)}
                                                style={{
                                                    color: handleStatusChange === 'Not Started' ? 'blue' : 'yellow',
                                                }}
                                            >
                                                <option value="Not Started" style={{ backgroundColor: 'blue' }}>
                                                    Not Started
                                                </option>
                                                <option value="Completed/Not Validated" style={{ backgroundColor: 'orange' }}>
                                                    Completed/Not Validated
                                                </option>
                                                <option value="WIP" style={{ backgroundColor: 'yellow' }}>
                                                    WIP
                                                </option>
                                            </select>
                                        </>
                                    ) : (
                                        <span
                                            className="message"
                                            style={{
                                                color: task.validationStatus === 'validated' ? 'green' : 'orange',
                                            }}
                                        >
                                            {task.validationStatus}.
                                        </span>
                                    )}
                                </>
                            ) : (
                                <>
                                    {task.validationStatus === 'rejected' && task.status === 'WIP' && (
                                        <div className="message" style={{ color: 'red' }}>
                                            Task validation status is rejected. Please complete it and update status again.
                                        </div>
                                    )}
                                    {task.validationStatus === 'validated' && task.status !== 'Completed/Not Validated' && (
                                        <div className="message" style={{ color: 'orange' }}>
                                            Task validation status is validated.
                                        </div>
                                    )}
                                    <select value={task.status} onChange={(e) => handleStatusChange(task._id, e.target.value)}>
                                        <option value="Not Started" style={{ backgroundColor: 'blue' }}>
                                            Not Started
                                        </option>
                                        <option value="WIP" style={{ backgroundColor: 'yellow' }}>
                                            WIP
                                        </option>
                                        <option value="Completed/Not Validated" style={{ backgroundColor: 'orange' }}>
                                            Completed/Not Validated
                                        </option>
                                    </select>
                                </>
                            )}
                        </td>
                        {/* Timer Section */}
                        <div className="col-md-4 d-flex justify-content-center align-items-start">
                            <label>Timer : </label>
                            <Timer
                                deadline={task.deadline}
                                taskId={task._id}
                                status={task.status}
                                taskName={task.task}
                                commentSubmited={task.commentSubmitted}

                            />
                        </div>
                        <div>
                            <a href="#!" onClick={() => handleModalOpen(task._id)} style={{ cursor: 'pointer', color: 'blue' }}>
                                Write a Note
                            </a>
                        </div>
                        {/* Notes Section */}
                        <div className="col-12 mt-4">
                            <h3>Notes</h3>
                            <div className="task-notes">
                                {task.note && task.note.length > 0 ? (
                                    task.note.map((noteObj, index) => (
                                        <div key={index} className="note-item mb-2">
                                            <p>{noteObj.note}</p>
                                            <span className="note-date">{formatDate(noteObj.date)}</span>
                                        </div>
                                    ))
                                ) : (
                                    <p>No notes available</p>
                                )}
                            </div>
                        </div>

                        {/* Note Replies Section */}
                        <div className="col-12 mt-4">
                            <h3>Note Replies</h3>
                            <div className="task-note-replies">
                                {task.noteReplies && task.noteReplies.length > 0 ? (
                                    task.noteReplies.map((replyObj, index) => (
                                        <div key={index} className="reply-item mb-2">
                                            <p>{replyObj.reply}</p>
                                            <span className="reply-date">{formatDate(replyObj.repliedAt)}</span>
                                        </div>
                                    ))
                                ) : (
                                    <p>No replies available</p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Write a Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} style={formStyle}>
                        <textarea
                            type="text"
                            placeholder="Ask me anything regarding this task..."
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            style={textareaStyle}
                        />
                        <button type="submit" style={buttonStyle} disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserTaskDetails;


const formStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

const textareaStyle = {
    width: "100%",
    height: "100px",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
    resize: "vertical",
};

const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#000",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
};
