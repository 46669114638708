import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseAuth } from "../store/auth";
import Select from 'react-select'; // Import this if you're using react-select for assignees

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;


function AdminEditTask() {
  const { authorizationToken } = UseAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [task, setTask] = useState(state?.task || {});
  const [users, setUsers] = useState([]); // Assuming you have a way to fetch or set users
  const [showAll, setShowAll] = useState(false);
  
  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_URL}/admin/users/by-current-user-department`, {
          method: "GET",
          headers: {
            'x-api-key': API_KEY,
            Authorization: authorizationToken,
          },
        });
        const data = await response.json();
        setUsers(data.map(user => ({
          value: user._id,
          label: user.name
        })));
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
  
    fetchUsers();
  }, [authorizationToken]);
  
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setTask((prevTask) => ({ ...prevTask, [name]: value }));
  };

  const handleAssigneesChange = (selectedOptions) => {
    const assignedTo = selectedOptions.map(option => ({ _id: option.value, name: option.label }));
    setTask((prevTask) => ({ ...prevTask, assignedTo }));
  };

  const handleUpdateTask = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/admin/tasks/${task._id}`, {
        method: "PATCH",
        headers: {
          'x-api-key': API_KEY,
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify(task),
      });
      if (response.ok) {
        navigate('/admin/taskList'); 
      } else {
        console.error("Failed to update task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  // Helper function to format the date
const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true};
    return new Date(date).toLocaleDateString('en-GB', options);
  };



  
  
    // Function to toggle the view
    const toggleShowAll = () => {
      setShowAll(!showAll);
    };
  
    const sortedAndReversedNotes = task.note
    ? [...task.note].sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];
    
    // Get the notes to display
    const notesToDisplay = showAll ? sortedAndReversedNotes : sortedAndReversedNotes.slice(0, 3);

  
  return (
    <div className="container">
        <div className="row">
            <div className="col-6">
      <h2>Edit Task</h2>
      <form onSubmit={handleUpdateTask}>
        <div className="mb-3">
          <label className="form-label">Task Name</label>
          <input
            type="text"
            className="form-control"
            name="task"
            value={task.task || ""}
            onChange={handleFieldChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Task Description</label>
          <textarea
            className="form-control"
            name="description"
            value={task.description || ""}
            onChange={handleFieldChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Priority</label>
          <select
            className="form-control"
            name="priority"
            value={task.priority || ""}
            onChange={handleFieldChange}
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Assignees</label>
          <Select
            isMulti
            name="assignees"
            options={users}
            value={task.assignedTo ? task.assignedTo.map(user => ({
              value: user._id,
              label: user.name
            })) : []}
            onChange={handleAssigneesChange}
          />
          
        </div>
        <div className="mb-3">
          <label className="form-label">Status</label>
          <select
            className="form-control"
            name="status"
            value={task.status || ""}
            onChange={handleFieldChange}
          >
            <option value="Not Started">Not Started</option>
            <option value="WIP">WIP</option>
            <option value="Incomplete">Incomplete</option>
            <option value="Completed/Not Validated">Completed/Not Validated</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Deadline</label>
          <input
            type="datetime-local"
            className="form-control"
            name="deadline"
            value={task.deadline || ""}
            onChange={handleFieldChange}
          />
        </div>
      
        <button type="submit" className="btn btn-primary mx-3 custom-btn">Update Task</button>
      </form>
    </div>
    <div className="col-6 mt-5">
        <div>
        <label className="form-label">Notes</label>
       
        <ul>
              {sortedAndReversedNotes.length > 0 ? (
                notesToDisplay.map((noteObj, index) => (
                  <li key={index}>
                    {noteObj.note} - {formatDate(noteObj.date)}
                  </li>
                ))
              ) : (
                <li>No notes available</li>
              )}
            </ul>
      {task.note.length > 3 && (
        <button onClick={toggleShowAll} className="btn btn-primary mx-3 custom-btn">
          {showAll ? 'Show Less' : 'Read More'}
        </button>
      )}
      </div>
      <div>
      <label className="form-label">Comment:-</label>
        {task.completionNote}
      </div>
      {/* <div>
      <label className="form-label">Uploads:-</label>
        {task.files}
      </div> */}
        </div>
    </div>
    </div>
  );
}

export default AdminEditTask;
