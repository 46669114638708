import React, { useContext, useEffect, useState } from "react";
import { UseAuth } from "../../src/store/auth";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const SupportTeamMember = () => {
  const { user, authorizationToken } = UseAuth();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resolutionNote, setResolutionNote] = useState("");
  const [activeTicketId, setActiveTicketId] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${API_URL}/getallassignedtickets?assignedTo=${user._id}`,
          {
            headers: {
              "x-api-key": API_KEY,
              Authorization: authorizationToken,
            },
          }
        );
        setTickets(response.data);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch tickets");
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
    // eslint-disable-next-line
  }, [user]);

  // Function to handle status changes
  const handleStatusChange = async (ticketId, newStatus) => {
    try {
      await axios.patch(
        `${API_URL}/admin/${ticketId}/status`,
        { status: newStatus },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );

      setTickets(
        tickets.map((ticket) =>
          ticket._id === ticketId ? { ...ticket, status: newStatus } : ticket
        )
      );

      toast.success("Status Updated");
    } catch (err) {
      setError("Failed to update status");
      console.error(err);
    }
  };

  // Function to handle resolution note submission
  const handleResolutionNoteSubmit = async (ticketId) => {
    try {
      await axios.patch(
        `${API_URL}/${ticketId}/resolutionNote`,
        {
          resolutionNote,
        },
        {
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );

      setTickets(
        tickets.map((ticket) =>
          ticket._id === ticketId ? { ...ticket, resolutionNote } : ticket
        )
      );

      toast.success("Resolution note added");
      setResolutionNote(""); // Reset resolution note input
      setActiveTicketId(null); // Close the active note field
    } catch (err) {
      setError("Failed to add resolution note");
      console.error(err);
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-danger text-center">{error}</div>;

  const handleDownload = async (attachmentPath) => {
    try {
      const response = await fetch(`https://tc-app-go2e.onrender.com/${attachmentPath}`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download the file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachmentPath.split("/").pop()); // Filename
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up

      // Optionally revoke the URL after some time
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error(error);
      alert("Error downloading the file");
    }
  };

  return (
    <div className="d-flex">
      <ToastContainer />
      <div className="flex-fill p-4">
        <h2 className="h2 mb-4">Support Team Assign Member Dashboard </h2>
        {tickets.length === 0 ? (
          <p>No tickets assigned.</p>
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead className="table-light">
                <tr>
                  <th>Ticket Number</th>
                  <th>Issue Type</th>
                  <th>Priority</th>
                  <th>Description</th>
                  <th>Attachment</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => (
                  <tr key={ticket.ticketNumber}>
                    <td>{ticket.ticketNumber}</td>
                    <td>{ticket.issueType}</td>
                    <td>{ticket.criticality}</td>
                    <td>{ticket.description}</td>
                    <td>
                      {ticket.attachment ? (
                        <button
                          onClick={() => handleDownload(ticket.attachment)}
                          className="btn btn-link"
                        >
                          View
                        </button>
                      ) : (
                        "No Attachment"
                      )}
                    </td>
                    <td>
                      <select
                        value={ticket.status}
                        onChange={(e) =>
                          handleStatusChange(ticket._id, e.target.value)
                        }
                        className="form-select"
                      >
                        {ticket.status === "Validated" ? (
                          <option value="Closed">Closed</option>
                        ) : (
                          <>
                            <option value="Reopened">Reopened</option>
                          </>
                        )}
                        <option value="Open">Open</option>
                        <option value="WIP">WIP</option>
                        <option value="Completed but not validated">
                          Completed but not validated
                        </option>
                      </select>
                    </td>
                    <td>
                      {activeTicketId === ticket._id ? (
                        <div>
                          <textarea
                            value={resolutionNote}
                            onChange={(e) => setResolutionNote(e.target.value)}
                            placeholder="Add resolution note"
                            className="form-control mb-2"
                          ></textarea>
                          <div className="d-flex">
                            <button
                              onClick={() =>
                                handleResolutionNoteSubmit(ticket._id)
                              }
                              className="btn btn-primary mx-2"
                            >
                              Submit
                            </button>
                            <button
                              onClick={() => setActiveTicketId(null)}
                              className="btn btn-secondary mx-2"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <button
                          onClick={() => setActiveTicketId(ticket._id)}
                          className="btn btn-success mx-2"
                        >
                          Add Note
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportTeamMember;
