import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import RegistrationForm from "./components/Register";
import Login from "./components/Login";
import "./App.css";
import Logout from "./components/Logout";
import Admin_Layout from "./layouts/Admin_Layout";
import Admin_Users from "./components/Admin_Users";
import ForgotPassword from "./components/ForgotPassword";
import AdminTaskToUser from "./components/AdminTaskToUser";
import TaskPage from "./components/TaskPage";
import AdminProjects from "./components/AdminProjects";
import UserProfile from "./components/UserProfile";
import Reports from "./components/Reports";
import PrivateRoute from "./components/PrivateRoute";
import AdminTaskList from "./components/AdminTaskList";
import AdminEditTask from "./components/AdminEditTask";
import ToDo from "./components/ToDo";
import { requestNotificationPermission } from "./components/notificationUtils";
import LandingPage from "./components/LandingPage";
import ProjectDetails from "./components/ProjectDetails";
import Udash from "./components/Tickeapp/Udash";
import AdminAlltickets from "./components/AdminAlltickets";
import SupportTeamMember from "./components/SupportTeamMember";
import TaskDetails from "./components/TaskDetail";
import UserTaskDetails from "./components/UserTaskdetails";

function App() {
  useEffect(() => {
    const askNotificationPermission = async () => {
      const permission = await requestNotificationPermission();
    };

    askNotificationPermission();
  }, []);
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/login" element={<Login />} />

          <Route path="/logout" element={<Logout></Logout>} />
          <Route
            path="/task"
            element={
              <PrivateRoute>
                <TaskPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/task/usertaskdetailspage/:taskid"
            element={
              <PrivateRoute>
                <UserTaskDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/taskdetails/:taskId"
            element={
              <PrivateRoute>
                <TaskDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/todo"
            element={
              <PrivateRoute>
                <ToDo />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/raiseticket"
            element={
              <PrivateRoute>
                <Udash />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/userprofile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />

          <Route
            path="/forgotpassword"
            element={<ForgotPassword></ForgotPassword>}
          ></Route>

          <Route
            path="/admin/"
            element={
              <PrivateRoute>
                <Admin_Layout />
              </PrivateRoute>
            }
          >
            <Route
              path="users"
              element={
                <PrivateRoute>
                  <Admin_Users />
                </PrivateRoute>
              }
            />
            <Route
              path="adminalltickets"
              element={
                <PrivateRoute>
                  <AdminAlltickets />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="supportteammembertivkets"
              element={
                <PrivateRoute>
                  <SupportTeamMember />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="prodetails"
              element={
                <PrivateRoute>
                  <ProjectDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="taskToTeamMembers"
              element={
                <PrivateRoute>
                  <AdminTaskToUser />
                </PrivateRoute>
              }
            />
            <Route
              path="reports"
              element={
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="taskList"
              element={
                <PrivateRoute>
                  <AdminTaskList />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="projects"
              element={
                <PrivateRoute>
                  <AdminProjects />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="edit-task/:taskId"
              element={
                <PrivateRoute>
                  <AdminEditTask />
                </PrivateRoute>
              }
            ></Route>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
