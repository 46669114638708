import React from 'react';

const YouTubeVideo = ({ videoId, title }) => {
  return (
    <div className="container my-5">
      <h2 className="text-center">{title}</h2>
      <div className="embed-responsive embed-responsive-16by9 ">
        <iframe
          className="embed-responsive-item rounded"
          src={`https://www.youtube.com/embed/${videoId}`}
          title={title}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeVideo;
