import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { UseAuth } from "../store/auth";
import { UseNotifications } from "../store/NotificationProvider"; // Import Notifications Context
import { Button } from "react-bootstrap";
import {
  FaTasks,
  FaSignOutAlt,
  FaUserAlt,
  FaClipboardList,
  FaBell,
  FaBars,
} from "react-icons/fa"; // Import icons
import "./NavbarWithProfileModal.css";
import ProfilePopup from "./ProfilePopup";

const API_URL = process.env.REACT_APP_API_URL;

const Navbar = () => {
  const { isLoggedIn, LogoutUser, user } = UseAuth();
  const {
    notifications,
    unreadCount,
    markNotificationAsRead,
  } = UseNotifications(); // Notifications Context
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Notifications Sidebar
  const [isOpen, setIsOpen] = useState(true); // Main Sidebar

  const handleTogglePopup = () => setIsPopupOpen(!isPopupOpen);
  const toggleSidebar = () => setIsOpen(!isOpen);
  const toggleNotificationsSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleNavLinkClick = () => {
    if (isOpen && window.innerWidth < 768) {
      toggleSidebar(); // Close sidebar if it's open
    }
  };

  const capitalizeEachWord = (string) => {
    if (typeof string !== "string" || string.length === 0) return string;
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const displayName = capitalizeEachWord(user.name);
  

  return (
    <div className="admin-layout">
      {/* Main Sidebar */}
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <button className="btn toggle-btn" onClick={toggleSidebar}>
          <FaBars style={{ color: "#fff" }} />
        </button>

        {/* Notification Icon */}
        <div className="notification-icon" onClick={toggleNotificationsSidebar}>
          <FaBell style={{ color: "#fff", cursor: "pointer" }} />
          {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
        </div>

        {/* Profile Image or Initial */}
        <div className="d-flex justify-content-center align-items-center mta-2">
          {user.profileImage ? (
            <img
              src={`${API_URL}${user.profileImage}`}
              alt="Profile"
              className="profile-logo"
              onClick={handleTogglePopup}
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            />
          ) : (
            <div
              className="initials-circle d-flex align-items-center justify-content-center"
              onClick={handleTogglePopup}
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                background: "gray",
                color: "white",
              }}
            >
              {user.name.charAt(0).toUpperCase()}
            </div>
          )}
        </div>

        {/* Sidebar Header */}
        {/* Sidebar Header */}
<div className="sidebar-header mb-4 text-center">
  {isLoggedIn && isOpen && (
    <h4 className="text-warning text-center">{displayName}</h4>
  )}
</div>


        {/* Navigation Links */}
        <ul className="nav">
          {!isLoggedIn ? (
            <>
              <li>
                <NavLink className="nav-link" to="/login">
                  <FaUserAlt />
                  {isOpen && <span className="ml-2">Login</span>}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/register">
                  <FaUserAlt />
                  {isOpen && <span className="ml-2">Register</span>}
                </NavLink>
              </li>
            </>
          ) : (
            <>
              {user.isAdmin && (
                <li>
                  <NavLink className="nav-link" to="/admin/users">
                    <FaUserAlt />
                    {isOpen && (
                      <span className="ml-2 mx-2" onClick={handleNavLinkClick}>
                        Team Lead
                      </span>
                    )}
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink className="nav-link" to="/todo">
                  <FaClipboardList />
                  {isOpen && (
                    <span className="ml-2 mx-2" onClick={handleNavLinkClick}>
                      ToDo
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/task">
                  <FaTasks />
                  {isOpen && (
                    <span className="ml-2 mx-2" onClick={handleNavLinkClick}>
                      Assigned Task
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/raiseticket">
                  <FaClipboardList />
                  {isOpen && (
                    <span className="ml-2 mx-2" onClick={handleNavLinkClick}>
                     Raise Ticket 
                    </span>
                  )}
                </NavLink>
              </li>
            </>
          )}
        </ul>

        {/* Logout Button */}
        <div className="d-flex justify-content-center mt-auto">
          {isLoggedIn && (
            <button className="btn text-white" onClick={LogoutUser}>
              <FaSignOutAlt />
              {isOpen && <span className="ml-2">Logout</span>}
            </button>
          )}
        </div>

        {/* Profile Popup */}
        <ProfilePopup isOpen={isPopupOpen} onClose={handleTogglePopup} />
      </div>

      {/* Notifications Sidebar */}
      <div className={`notifications-sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="notifications-header">
          <h4>Notifications</h4>
          <button onClick={toggleNotificationsSidebar}>&times;</button>
        </div>
        <div className="notifications-list">
          {notifications.map((notification) => (
            <div
              key={notification._id}
              className={`notification-item ${
                notification.isRead ? "" : "unread"
              }`}
              onClick={() => markNotificationAsRead(notification._id)}
            >
              {notification.message}
            </div>
          ))}
        </div>
      </div>

      {/* Styles */}
      <style jsx="true">{`
        .admin-layout {
          display: flex;
        }
        .sidebar {
          width: ${isOpen ? "250px" : "80px"};
          transition: width 0.3s ease;
          background-color: #163365;
          min-height: 100vh;
          position: fixed;
          z-index: 1000;
        }
/* Notification icon styling */
/* Notification icon styling */
.notification-icon {
  position: relative;
  margin: 10px;
  cursor: pointer;
}

/* Badge styling */
.badge {
  position: absolute;
  top: 6px;
  right: -10px; /* Position the badge to the top-right of the bell icon */
  background-color: red; /* Red background for the badge */
  color: white; /* White text color */
  border-radius: 50%; /* Circular badge */
  padding: 5px 10px;
  font-size: 14px;
  z-index: 2; /* Ensure the badge is above the bell icon */
}

/* Sidebar Styling */
.sidebar {
  width: ${isOpen ? "250px" : "80px"};
  transition: width 0.3s ease;
  background-color: #163365;
  min-height: 100vh;
  position: fixed;
  z-index: 1000;
}

/* Styling for the notifications sidebar */
.notifications-sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 2000;
}
.notifications-sidebar.open {
  right: 0;
}
.notifications-header {
  padding: 10px;
  background: #163365;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notifications-list {
  padding: 10px;
  overflow-y: auto;
}
.notification-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.notification-item.unread {
  background: #f9f9f9;
  font-weight: bold;
}

        .notifications-sidebar.open {
          right: 0;
        }
        .notifications-header {
          padding: 10px;
          background: #163365;
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .notifications-list {
          padding: 10px;
          overflow-y: auto;
        }
        .notification-item {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          cursor: pointer;
        }
        .notification-item.unread {
          background: #f9f9f9;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default Navbar;
