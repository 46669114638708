// Request Notification Permission
export const requestNotificationPermission = async () => {
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        try {
          const permission = await Notification.requestPermission();
          return permission;
        } catch (error) {
          console.error('Failed to request notification permission:', error);
        }
      } else {
        return Notification.permission;
      }
    } else {
      console.error('Notifications are not supported in this browser.');
      return 'denied';
    }
  };
  
  // Show Notification with actions
  export const showNotification = (title, body, icon, id, completed,dueDate) => {
    console.log(`Showing notification for todoId: ${id}`);
    if (Notification.permission === 'granted') {
      const options = {
        body: body,
        icon: icon,
        data: { id: id, completed: completed, dueDate: dueDate},
        actions: [
          { action: 'complete', title: 'Complete' },
          { action: 'snooze', title: 'Snooze' }
        ]
      };
  
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then((registration) => {
            registration.showNotification(title, options);
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      } else {
        new Notification(title, options);
      }
    } else {
      console.error('Notification permission not granted.');
    }
  };
  