import React, { createContext, useContext, useEffect, useState } from "react";
import { UseAuth } from "./auth.jsx"; // Import AuthContext to access user and token

const API_URL = process.env.REACT_APP_API_URL;

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { user, authorizationToken } = UseAuth(); // Get userId and token from Auth
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchNotifications = async () => {
    if (!user) return; // Ensure user is logged in
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/notifications/${user._id}`, {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
        setUnreadCount(data.filter((notification) => !notification.isRead).length);
      } else {
        console.error("Failed to fetch notifications");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/api/notifications/${notificationId}`, {
        method: "PUT",
        headers: {
          Authorization: authorizationToken,
        },
      });
      if (response.ok) {
        // Update the local state after marking as read
        setNotifications((prev) =>
          prev.map((notification) =>
            notification._id === notificationId ? { ...notification, isRead: true } : notification
          )
        );
        setUnreadCount((prev) => Math.max(prev - 1, 0));
      } else {
        console.error("Failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const response = await fetch(`${API_URL}/api/notifications/mark-all/${user.id}`, {
        method: "PUT",
        headers: {
          Authorization: authorizationToken,
        },
      });
      if (response.ok) {
        // Update all notifications to read locally
        setNotifications((prev) =>
          prev.map((notification) => ({ ...notification, isRead: true }))
        );
        setUnreadCount(0);
      } else {
        console.error("Failed to mark all notifications as read");
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [user]); // Refetch notifications when the user changes

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        loading,
        fetchNotifications,
        markNotificationAsRead,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const UseNotifications = () => useContext(NotificationContext);
