import React, { useEffect, useState } from "react";
import { UseAuth } from "../store/auth";
import "./AdminTaskToUser.css";
import Select from "react-select";
import ProfileUserShowHide from "./ProfileUserShowHide";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const AdminTaskToUser = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [task, setTask] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);
  const { authorizationToken } = UseAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newprojectname, setnewproject] = useState("");
  const [team, setteam] = useState([]);
  const [teammembers, setteammembers] = useState([]);
  const [newselectedoption, setnewselectedoption] = useState("");

  const handlebugvalueschange = (newselectedoption) => {
    setnewselectedoption(newselectedoption);
  };
  const [optionss, setOptions] = useState([
    { value: "bug-fix", label: "Bug Fix" },
    { value: "development", label: "Development" },
    { value: "other", label: "Other" },
  ]);

  // Fetch assigned tasks
  const fetchAssignedTasks = async () => {
    try {
      const response = await fetch(`${API_URL}/projects`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Extract unique project names
      const uniqueProjects = [
        ...new Set(data.map((task) => task.projectname).filter((name) => name)),
      ];

      // Set the projects in the state with label and value
      setProjects(
        uniqueProjects.map((project) => ({ value: project, label: project }))
      );
    } catch (error) {
      console.error("Error fetching assigned tasks:", error);
    }
  };

  useEffect(() => {
    fetchAssignedTasks();
  }, [authorizationToken]);

  const getAllUsersData = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/taskToUser`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setUsers(
        data.map((user) => ({ value: user._id, label: user.email, ...user }))
      );
    } catch (error) {
      toast.error("Failed to fetch users data.");
      setError("Failed to fetch users data.");
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, []);

  const handleProjectChange = async (selectedProject) => {
    setSelectedProject(selectedProject);
    try {
      const response = await fetch(`${API_URL}/projectss`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({ projectname: selectedProject.value }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch project details: ${errorText}`);
      }

      const data = await response.json();

      setnewproject(data.projectname);
      setteam(data.selectedUser);
      console.log(data.selectedUser);
      setProjectStartDate(new Date(data.startDate));
      setProjectEndDate(new Date(data.deadline));

      // Send the array of user IDs to the backend to fetch user details
      const userResponse = await fetch(`${API_URL}/users/getDetails`, {
        method: "POST", // Use POST to send an array of IDs
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({ userIds: data.selectedUser }), // Send the array of user IDs
      });

      if (!userResponse.ok) {
        const errorText = await userResponse.text();
        throw new Error(`Failed to fetch user details: ${errorText}`);
      }

      const userData = await userResponse.json();
      setteammembers(userData); // Assuming you want to store user details
    } catch (error) {
      toast.error(error.message);
      setError("Failed to fetch project or user details.");
    }
  };

  const userOptions = teammembers.map((user) => ({
    value: user._id, // Or any unique identifier for the user
    label: user.email, // Display email in the dropdown
  }));

  const handleUserChange = (selectedOptions) => {
    setSelectedUser(selectedOptions);
    const selectedUserIds = selectedOptions.map((option) => option.value);
    const selectedUsersData = users.filter((user) =>
      selectedUserIds.includes(user.value)
    );
    setSelectedUsersData(selectedUsersData);
    console.log(selectedUserIds);
    setIsUserSelected(selectedUsersData.length > 0);
  };

  const handleTaskChange = (e) => {
    setTask(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formattedDeadline = deadline ? deadline.toISOString() : null;

    // Validate that the deadline is within the project start and end dates
    if (projectStartDate && projectEndDate) {
      if (deadline < projectStartDate || deadline > projectEndDate) {
        toast.error(
          `Please select a date between ${projectStartDate.toLocaleDateString()} and ${projectEndDate.toLocaleDateString()}.`
        );
        setLoading(false);
        return; // Stop form submission if date is invalid
      }
    }

    try {
      const response = await fetch(`${API_URL}/assignTask`, {
        method: "POST",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          userIds: selectedUser.map((user) => user.value),
          task: task,
          projectname: newprojectname,
          description: description,
          deadline: formattedDeadline,
          newselectedoption: newselectedoption.value,
        }),
      });

      const response2 = await fetch(`${API_URL}/api/notifications`, {
        method: "POST",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          userIds: selectedUser.map((user) => user.value),
          type:"info",
          message: `You have been assigned Task , Task name:${task}`,
        }),
      });

      if (response.ok && response2.ok) {
        toast.success("Task assigned successfully and notification sent!");
        setSelectedProject(null);

        setSelectedUser([]);
        setTask("");
        setDescription("");
        setDeadline(null);
      } else {
        toast.error("Error assigning task: " + response.statusText);
        setError("Error assigning task.");
      }
    } catch (error) {
      toast.error("Error assigning task: " + error.message);
      setError("Error assigning task.");
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="Container">
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <h3>Task To Team Members</h3>
          {error && <p style={{ color: "red" }}>{error}</p>}

          <form onSubmit={handleSubmit} className="AdminTaskToUserForm">
            <label htmlFor="projectname">Select or Enter Project Name:</label>
            <div className="d-flex align-items-center">
              <Select
                id="projectname"
                options={projects}
                value={selectedProject}
                onChange={handleProjectChange}
                placeholder="Select a project"
                className="w-100"
              />
            </div>
            <br />

            <label htmlFor="users" className="mt-4">
              Select individual or multiple team members:
            </label>
            <Select
              id="users"
              isMulti
              options={userOptions}
              styles={{ width: "338px" }}
              value={selectedUser}
              onChange={handleUserChange}
            />

            <br />
            <label htmlFor="task">Task Name:</label>
            <input
              type="text"
              id="task"
              value={task}
              onChange={handleTaskChange}
              placeholder="Enter task"
            />
            <br />
            <label htmlFor="description">Task Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter task description"
            />
            <br />
            <label htmlFor="projectname">Select </label>
            <div className="d-flex align-items-center">
              <Select
                id="projectname"
                options={optionss}
                value={newselectedoption}
                onChange={handlebugvalueschange}
                placeholder="Select a project"
                className="w-100"
                required
              />
            </div>
            <br />
            <label htmlFor="deadline">Expected to Complete by:</label>
            <DatePicker
              id="deadline"
              selected={deadline}
              onChange={(date) => setDeadline(date)}
              showTimeSelect
              placeholderText="Select date and time"
              className="date-picker form-control"
              dateFormat="dd/MM/yyyy p"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required
              minDate={projectStartDate} // Restrict the minimum date
              maxDate={projectEndDate} // Restrict the maximum date
              calendarClassName="custom-datepicker"
            />

            <br />
            <button
              type="submit"
              disabled={loading}
              className="archived-button"
            >
              {loading ? "Assigning..." : "Assign Task"}
            </button>
          </form>
        </div>

        {isUserSelected && (
          <ProfileUserShowHide selectedUsersData={selectedUsersData} />
        )}
      </div>
    </div>
  );
};

export default AdminTaskToUser;
