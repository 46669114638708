import React, { useEffect, useState } from "react";
import { UseAuth } from "../store/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink } from "react-router-dom";
import ProjectDetails from "./ProjectDetails";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function AdminProjects() {
  const { authorizationToken } = UseAuth();
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  // Fetch assigned tasks
  const fetchAssignedTasks = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setAssignedTasks(data.reverse());

      // Extract unique project names
      const uniqueProjects = [
        ...new Set(data.map((task) => task.projectname).filter((name) => name)),
      ];
      setProjects(uniqueProjects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignedTasks();
  }, [authorizationToken]);

  // Handle project click
  const handleProjectClick = (projectName) => {
    setSelectedProject(projectName);
  };

  // Filter tasks for the selected project
  const getProjectTasks = (projectName) => {
    return assignedTasks.filter((task) => task.projectname === projectName);
  };

  // Get unique team members for the selected project
  const getProjectTeamMembers = (projectName) => {
    const members = new Set();
    getProjectTasks(projectName).forEach((task) => {
      task.assignedTo.forEach((member) => members.add(member.name));
    });
    return Array.from(members);
  };

  // Determine the display status based on conditions
  const determineStatus = (status, validationStatus) => {
    if (
      status === "Completed/Not Validated" &&
      validationStatus === "validated"
    ) {
      return "Completed";
    }
    return status;
  };

  // Define styles for the selected project
  const selectedStyle = {
    backgroundColor: "#E8F1FF",
    color: "#000",
    fontWeight: "500",
  };

  return (
    <>
      <ProjectDetails />

      <div className="container mt-4">
        <h2 className="AllProjectList">All Projects List</h2>
        <div className="row">
          <div className="col-md-2">
            <div className="list-group">
              {projects.map((project, projectIndex) => (
                <button
                  key={projectIndex}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleProjectClick(project)}
                  style={selectedProject === project ? selectedStyle : {}}
                >
                  {/* Add numbering to projects */}
                  {`${projectIndex + 1}. ${project}`}
                </button>
              ))}
            </div>
          </div>

          <div className="col-md-8">
            {selectedProject && (
              <div className="project-details">
                <h3 className="detailsproject">
                  Details for {selectedProject}
                </h3>
                <br />
                {getProjectTasks(selectedProject).length > 0 ? (
                  <div>
                    <h5 className="teammembers">
                      Team Members:
                      <h6 className=" badge text-white bg-success">
                        {getProjectTeamMembers(selectedProject).join(",")}
                      </h6>
                    </h5>

                    <div className="task-table-header d-flex align-items-center justify-content-around">
                      <h5 className="task-table-column">Sr No</h5>
                      <h5 className="task-table-column">Tasks</h5>
                      <h5 className="task-table-column">Created On</h5>
                      <h5 className="task-table-column">Status</h5>
                    </div>

                    <ul className="task-table mb-4">
                      {getProjectTasks(selectedProject).map(
                        (task, taskIndex) => (
                          <li key={taskIndex} className="task-table-row">
                            <div className="task-table-cell">
                              {projects.indexOf(selectedProject) + 1}.0.
                              {taskIndex + 1}
                            </div>

                            <div className="task-table-cell">
                              <Link
                                to={`/admin/reports?highlight=${
                                  task._id
                                }&number=${
                                  projects.indexOf(selectedProject) + 1
                                }.0.${taskIndex + 1}`}
                                className="custom-link"
                              >
                                {task.task}
                              </Link>
                            </div>

                            <div className="task-table-cell">
                              <span className="task-created-badge">
                                {new Date(task.createdAt).toLocaleString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </span>
                            </div>

                            <div className="task-table-cell">
                              <span
                                className={`task-status mobmar badge ${
                                  determineStatus(
                                    task.status,
                                    task.validationStatus
                                  ) === "Completed"
                                    ? "bg-success"
                                    : determineStatus(
                                        task.status,
                                        task.validationStatus
                                      ) === "WIP"
                                    ? "bg-secondary"
                                    : determineStatus(
                                        task.status,
                                        task.validationStatus
                                      ) === "Not Started"
                                    ? "bg-primary"
                                    : "bg-warning"
                                }`}
                              >
                                {determineStatus(
                                  task.status,
                                  task.validationStatus
                                )}
                              </span>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  <p>No tasks found for this project.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProjects;
