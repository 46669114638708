import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { AuthProvider } from './store/auth';
import { NotificationProvider } from "./store/NotificationProvider";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
  <React.StrictMode>
  <NotificationProvider>
      <App />
    </NotificationProvider>
  </React.StrictMode>
  </AuthProvider>
);
// index.js

// Register Service Worker and send dynamic configuration
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {

        // Wait for the service worker to become active
        function sendMessageToWorker() {
          if (registration.active) {
            registration.active.postMessage({
              type: 'CONFIG',
              apiUrl: process.env.REACT_APP_API_URL,
              apiKey: process.env.REACT_APP_API_KEY
            });
          } else if (registration.waiting) {
            registration.waiting.postMessage({
              type: 'CONFIG',
              apiUrl: process.env.REACT_APP_API_URL,
              apiKey: process.env.REACT_APP_API_KEY
            });
          } else {
            console.log('Service Worker is not active yet');
          }
        }

        // Add an event listener for when the service worker becomes active
        registration.addEventListener('updatefound', () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.addEventListener('statechange', () => {
              if (installingWorker.state === 'installed') {
                sendMessageToWorker();
              }
            });
          }
        });

        // If the service worker is already active, send the message immediately
        if (registration.active) {
          sendMessageToWorker();
        }
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}



reportWebVitals();
