import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UseAuth } from "../store/auth";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import Navbar from '../components/Navbar';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export default function TaskDetail() {
    const { taskId } = useParams();
    const navigate = useNavigate();
    const { authorizationToken } = UseAuth();
    const [task, setTask] = useState(null);
    const [error, setError] = useState("");
    const [replies, setReplies] = useState({});
    const [showFullNotes, setShowFullNotes] = useState(false); 
    
    useEffect(() => {
        fetchTaskDetails();
    }, [taskId]);

    const fetchTaskDetails = async () => {
        try {
            const response = await fetch(`${API_URL}/admin/tasks/${taskId}`, {
                method: "GET",
                headers: {
                    "x-api-key": API_KEY,
                    Authorization: authorizationToken,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setTask(data);
            } else {
                setError("Failed to fetch task details.");
            }
        } catch (error) {
            setError("Failed to fetch task details.");
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`${API_URL}/admin/tasks/delete/${taskId}`, {
                method: "DELETE",
                headers: {
                    "x-api-key": API_KEY,
                    Authorization: authorizationToken,
                },
            });
            if (response.ok) {
                toast.success("Task deleted successfully");
                navigate("/admin/tasks");
            } else {
                toast.error("Failed to delete task");
            }
        } catch (error) {
            toast.error("Failed to delete task");
        }
    };

    const handleArchiveToggle = async () => {
        try {
            const response = await fetch(`${API_URL}/admin/tasks/archive/${taskId}`, {
                method: "PATCH",
                headers: {
                    "x-api-key": API_KEY,
                    Authorization: authorizationToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ archived: !task.archived }),
            });
            if (response.ok) {
                toast.success(`Task ${task.archived ? "unarchived" : "archived"} successfully`);
                fetchTaskDetails();
            } else {
                toast.error("Failed to update task archive status");
            }
        } catch (error) {
            toast.error("Error updating task archive status");
        }
    };

    const handleEdit = () => {
        navigate(`/admin/edit-task/${taskId}`, { state: { task } });
    };

    const updateTaskPriority = async (taskId, newPriority) => {
        try {
            const response = await fetch(`${API_URL}/admin/tasks/updatePriority/${taskId}`, {
                method: "PUT",
                headers: {
                    "x-api-key": API_KEY,
                    "Content-Type": "application/json",
                    Authorization: authorizationToken,
                },
                body: JSON.stringify({ priority: newPriority }),
            });

            if (response.ok) {
                toast.success("Priority updated successfully");
                fetchTaskDetails(); // Refresh the task details after updating
            } else {
                toast.error("Failed to update priority.");
            }
        } catch (error) {
            toast.error("Error updating priority.");
        }
    };

    const handleReplySubmit = async (taskId) => {
        const reply = replies[taskId];

        if (!reply) {
            toast.error("Reply cannot be empty.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/task/note/${taskId}/reply`, {
                method: "POST",
                headers: {
                    "x-api-key": API_KEY,
                    "Content-Type": "application/json",
                    Authorization: authorizationToken,
                },
                body: JSON.stringify({ noteReply: reply }),
            });

            if (response.ok) {
                toast.success("Reply submitted successfully");
                setReplies((prevReplies) => ({
                    ...prevReplies,
                    [taskId]: "",
                }));
            } else {
                toast.error("Failed to submit reply");
            }
        } catch (error) {
            toast.error("Error submitting reply");
        }
    };

    const updateTaskValidationStatus = async (taskId, newValidationStatus) => {
        try {
            const response = await fetch(
                `${API_URL}/admin/tasks/${taskId}/validationStatus`,
                {
                    method: "PUT",
                    headers: {
                        "x-api-key": API_KEY,
                        "Content-Type": "application/json",
                        Authorization: authorizationToken,
                    },
                    body: JSON.stringify({
                        validationStatus: newValidationStatus,
                        validationTime: new Date().toISOString(),
                    }),
                }
            );

            if (response.ok) {
                toast.success("Validation status updated successfully");
                fetchTaskDetails(); // Refresh the task details after updating
            } else {
                toast.error("Failed to update validation status");
            }
        } catch (error) {
            toast.error("Error updating validation status");
        }
    };

    if (error) return <div className="text-danger">{error}</div>;
    if (!task) return <div>Loading...</div>;

    return (
        <><ToastContainer/>
            <Navbar />
            <Container className="mt-1 taskdetailspage">
                <Row>
                    <Col md={10}>
                        <Card>
                            <Card.Header>
                                <h2>Task Details</h2>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <p><strong>Task Name:</strong> {task.task}</p>
                                        <p><strong>Assigned By:</strong> {task.assignedByName}</p>
                                        <p><strong>Priority:</strong>
                                            <select
                                                value={task.priority}
                                                onChange={(e) => updateTaskPriority(task._id, e.target.value)}
                                                className="form-select"
                                            >
                                                <option value="low">Low</option>
                                                <option value="medium">Medium</option>
                                                <option value="high">High</option>
                                            </select>
                                        </p>
                                        <p><strong>Status:</strong>
                                            {task.status === "Completed/Not Validated" ? (
                                                <select
                                                    value={task.validationStatus}
                                                    onChange={(e) =>
                                                        updateTaskValidationStatus(
                                                            task._id,
                                                            e.target.value
                                                        )
                                                    }
                                                    className="form-select"
                                                >
                                                    <option value="validation pending">Validation Pending</option>
                                                    <option value="validated">Validated</option>
                                                    <option value="rejected">Rejected</option>
                                                </select>
                                            ) : (
                                                task.status
                                            )}
                                        </p>
                                        <p><strong>Due Date:</strong> {new Date(task.deadline).toLocaleString()}</p>
                                    </Col>
                                    <Col md={6}>
                                        <p><strong>Description:</strong> {task.description}</p>
                                        <p><strong>Assigned To:</strong> {task.assignedTo.map(user => user.name).join(", ")}</p>
                                        <p><strong>Created On:</strong> {new Date(task.createdAt).toLocaleString()}</p>
                                        <p><strong>Notes:</strong></p>
                                        <ul>
                                            {task.note.map((note, index) => (
                                                <li key={index}>
                                                    {note.note} - {new Date(note.date).toLocaleString()}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <div className="form-group">
                                            <label htmlFor={`replyText-${task._id}`} className="form-label">
                                                Reply to Note
                                            </label>
                                            <textarea
                                                id={`replyText-${task._id}`}
                                                value={replies[task._id] || ""}
                                                onChange={(e) =>
                                                    setReplies({
                                                        ...replies,
                                                        [task._id]: e.target.value,
                                                    })
                                                }
                                                placeholder="Reply to Note"
                                                className="form-control"
                                                rows="4"
                                            />
                                        </div>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleReplySubmit(task._id)}
                                            className="mt-2 mx-3"
                                        >
                                            Reply
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                <Button variant="primary" onClick={handleEdit} className="m-2">Edit</Button>
                                <Button variant="danger" onClick={handleDelete} className="m-2">Delete</Button>
                                <Button variant="secondary" onClick={handleArchiveToggle} className="m-2">
                                    {task.archived ? "Unarchive" : "Archive"}
                                </Button>
                                <Button variant="warning" onClick={() => navigate('/admin/tasklist')} className="m-2">Back to Task List</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
        
    );
}
