import React, { useEffect, useState } from "react";
import { UseAuth } from "../store/auth";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./AdminTaskToUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSearch } from "@fortawesome/free-solid-svg-icons";

const Reports = () => {
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const { authorizationToken } = UseAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryByKeyword, setSearchQueryByKeyword] = useState("");
  const [totalHours, setTotalHours] = useState(0);
  const [manDays, setManDays] = useState(0);

  // Function to calculate man-days from hours
  const calculateManDays = (totalHours) => {
    return (totalHours / 8).toFixed(1);
  };

  //export pdf of data

  const exportToPDF = () => {
    // Convert tasks to a format suitable for PDF
    const tasksForPDF = filteredTasks.map((task) => [
      task.task,
      task.assignedByName,
      task.description,
      task.priority,
      task.status === "Completed/Not Validated" &&
      task.validationStatus === "validated"
        ? "Completed"
        : task.status,
      new Date(task.createdAt).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
      new Date(task.deadline).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
      task.assignedTo.map((user) => user.name).join(", "),
      task.projectname,
      task.emailTo.join(", "),
      task.completionNote,
    ]);

    // Create a new jsPDF instance in landscape mode
    const doc = new jsPDF({
      orientation: "landscape",
    });

    // Add title to the PDF
    doc.text("Filtered Tasks", 14, 16);

    // Generate table in PDF
    doc.autoTable({
      head: [
        [
          "Task",
          "Assigned By",
          "Description",
          "Priority",
          "Status",
          "Created At",
          "Deadline",
          "Assigned To",
          "Project Name",
          "Email To",
          "Completion Note",
        ],
      ],
      body: tasksForPDF,
      startY: 20, // Y position for the table
    });

    // Save the PDF file
    doc.save("Filtered_Tasks.pdf");
  };

  useEffect(() => {
    // Calculate man-days whenever hours change
    const calculatedManDays = calculateManDays(totalHours);
    setManDays(calculatedManDays);
  }, [totalHours]); // Dependency array - only run when 'hours' changes

  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  // Get the task ID from query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const highlightTaskId = queryParams.get("highlight");

  const fetchAssignedTasks = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks`, {
        method: "GET",

        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setAssignedTasks(data.reverse());
      const uniqueProjects = [...new Set(data.map((task) => task.projectname))];
      setProjects(uniqueProjects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignedTasks();
  }, [authorizationToken]);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchKeywordChange = (e) => {
    setSearchQueryByKeyword(e.target.value);
  };

  const filterTasks = () => {
    let filteredTasks = assignedTasks;

    if (selectedProject) {
      filteredTasks = filteredTasks.filter(
        (task) => task.projectname === selectedProject
      );
    }

    if (dateFilter) {
      const now = new Date();
      const filterDate = new Date();

      switch (dateFilter) {
        case "today":
          filterDate.setHours(0, 0, 0, 0);
          filteredTasks = filteredTasks.filter(
            (task) =>
              new Date(task.createdAt).toDateString() === now.toDateString()
          );
          break;
        case "week":
          filterDate.setDate(now.getDate() - 7);
          filterDate.setHours(0, 0, 0, 0);
          filteredTasks = filteredTasks.filter(
            (task) =>
              new Date(task.createdAt) >= filterDate &&
              new Date(task.createdAt) <= now
          );
          break;
        case "month":
          const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          const endOfMonth = new Date(
            now.getFullYear(),
            now.getMonth() + 1,
            0,
            23,
            59,
            59
          );
          filteredTasks = filteredTasks.filter(
            (task) =>
              new Date(task.createdAt) >= startOfMonth &&
              new Date(task.createdAt) <= endOfMonth
          );
          break;
        case "quarter":
          const currentQuarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
          const quarterStart = new Date(
            now.getFullYear(),
            currentQuarterStartMonth,
            1
          );
          filteredTasks = filteredTasks.filter(
            (task) =>
              new Date(task.createdAt) >= quarterStart &&
              new Date(task.createdAt) <= now
          );
          break;
        case "sixMonths":
          filterDate.setMonth(now.getMonth() - 6);
          filterDate.setDate(1);
          filterDate.setHours(0, 0, 0, 0);
          filteredTasks = filteredTasks.filter(
            (task) =>
              new Date(task.createdAt) >= filterDate &&
              new Date(task.createdAt) <= now
          );
          break;
        case "year":
          filterDate.setFullYear(now.getFullYear() - 1);
          filterDate.setMonth(0);
          filterDate.setDate(1);
          filterDate.setHours(0, 0, 0, 0);
          filteredTasks = filteredTasks.filter(
            (task) =>
              new Date(task.createdAt) >= filterDate &&
              new Date(task.createdAt) <= now
          );
          break;
        default:
          break;
      }
    }

    if (statusFilter) {
      filteredTasks = filteredTasks.filter((task) => {
        if (statusFilter === "Completed") {
          return (
            task.status === "Completed/Not Validated" &&
            task.validationStatus === "validated"
          );
        }
        if (statusFilter === "Completed/Not Validated") {
          return (
            task.status === "Completed/Not Validated" &&
            task.validationStatus !== "validated"
          );
        }
        return task.status === statusFilter;
      });
    }

    if (searchQuery) {
      const searchString = searchQuery.toLowerCase();
      filteredTasks = filteredTasks.filter((task) => {
        const nameMatch = task.assignedTo.some((user) =>
          user.name.toLowerCase().includes(searchString)
        );
        const emailMatch = task.emailTo.some((email) =>
          email.toLowerCase().includes(searchString)
        );
        return nameMatch || emailMatch;
      });
    }

    if (searchQueryByKeyword) {
      const searchString = searchQueryByKeyword.toLowerCase();
      filteredTasks = filteredTasks.filter((task) => {
        return (
          task.task.toLowerCase().includes(searchString) ||
          task.description.toLowerCase().includes(searchString) ||
          task.assignedTo.some((user) =>
            user.name.toLowerCase().includes(searchString)
          ) ||
          task.emailTo.some((email) =>
            email.toLowerCase().includes(searchString)
          )
        );
      });
    }

    // Highlighted task sorting
    if (highlightTaskId) {
      filteredTasks = filteredTasks.sort((a, b) => {
        if (a._id === highlightTaskId) return -1; // Move highlighted task to the top
        if (b._id === highlightTaskId) return 1; // Move highlighted task to the top
        return 0; // Maintain the original order for other tasks
      });
    }

    return filteredTasks;
  };

  const filteredTasks = filterTasks();

  const calculateTotalHours = (filteredTasks) => {
    let total = 0;

    filteredTasks.forEach((task) => {
      if (task.validatedAt && task.createdAt) {
        total += parseFloat(
          calculateHoursDifference(task.createdAt, task.validatedAt)
        );
      }
    });

    setTotalHours(total);
  };

  useEffect(() => {
    // Calculate total hours whenever filtered tasks change
    calculateTotalHours(filterTasks());
  }, [
    assignedTasks,
    selectedProject,
    dateFilter,
    statusFilter,
    searchQuery,
    searchQueryByKeyword,
  ]);

  // Highlight task logic
  const isHighlighted = (taskId) => {
    return taskId === highlightTaskId;
  };

  const highlightText = (text) => {
    if (!searchQueryByKeyword || typeof text !== "string") return text;

    const parts = text.split(new RegExp(`(${searchQueryByKeyword})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQueryByKeyword.toLowerCase() ? (
        <mark key={index}>{part}</mark>
      ) : (
        part
      )
    );
  };
  const exportToExcel = () => {
    // Convert tasks to a format suitable for Excel
    const tasksForExcel = filteredTasks.map((task) => ({
      task: task.task,
      assignedByName: task.assignedByName,
      description: task.description,
      priority: task.priority,
      status:
        task.status === "Completed/Not Validated" &&
        task.validationStatus === "validated"
          ? "Completed"
          : task.status,
      createdAt: new Date(task.createdAt).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
      deadline: new Date(task.deadline).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
      assignedTo: task.assignedTo.map((user) => user.name).join(", "),
      projectname: task.projectname,
      emailTo: task.emailTo.join(", "),
      completionNote: task.completionNote,
    }));

    // Create a worksheet from the tasks
    const ws = XLSX.utils.json_to_sheet(tasksForExcel, {
      header: [
        "task",
        "assignedByName",
        "description",
        "priority",
        "status",
        "createdAt",
        "deadline",
        "assignedTo",
        "projectname",
        "emailTo",
        "completionNote",
      ],
    });

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tasks");

    // Write the workbook to a file
    XLSX.writeFile(wb, "Filtered_Tasks.xlsx");
  };
  const calculateHoursDifference = (createdAt, validatedAt) => {
    const createdOnDate = new Date(createdAt);
    const validatedOnDate = new Date(validatedAt);
    const hoursDifference = Math.abs(validatedOnDate - createdOnDate) / 36e5; // Convert milliseconds to hours
    return parseFloat(hoursDifference.toFixed(2)); // Convert to a number with 2 decimal places
  };

  const sckask = totalHours; // your total hours calculation here;
  const hours = Math.floor(sckask);
  const minutes = Math.round((sckask - hours) * 60);

  return (
    <>
      {/* <div className="row fixed-column serial-no">
        <div className="col-md-2 mb-2">
          <label htmlFor="projectFilter" className="reports mx-2 mb-2">
            Filter by Project:
          </label>
          <div className="select-wrapper">
            <select
              id="projectFilter"
              onChange={handleProjectChange}
              className="form-control"
            >
              <option value="">All Projects</option>
              {projects.map((project) => (
                <option key={project} value={project}>
                  {project}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
          </div>
        </div>

        <div className="col-md-2">
          <label className="assignefilter mx-2 mb-2" htmlFor="dateFilter">
            Filter by Date:
          </label>
          <div className="select-wrapper">
            <select
              id="dateFilter"
              onChange={handleDateFilterChange}
              className="form-control"
            >
              <option value="">All Dates</option>
              <option value="today">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="quarter">This Quarter</option>
              <option value="sixMonths">Last 6 Months</option>
              <option value="year">Last 12 Months</option>
            </select>
            <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
          </div>
        </div>

        <div className="col-md-2 mb-2">
          <label className="assignefilter  mb-2" htmlFor="statusFilter">
            Filter by Status:
          </label>
          <div className="select-wrapper">
            <select
              id="statusFilter"
              onChange={handleStatusFilterChange}
              className="form-control"
            >
              <option value="">All Statuses</option>
              <option value="Not Started">Not Started</option>
              <option value="WIP">WIP</option>
              <option value="Incomplete">Incomplete</option>
              <option value="Completed/Not Validated">Completed/N-Val</option>
              <option value="Completed">Completed</option>
            </select>
            <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
          </div>
        </div>
        <div className="col-md-2">
          <label className="assignefilter mx-2 mb-2" htmlFor="assigneFilter">
            Filter by Assignee:
          </label>
          <div className="select-wrapper ">
            <input
              type="text"
              placeholder="Filter by Assignee "
              value={searchQuery}
              onChange={handleSearchChange}
              className=" form-control"
              style={{ paddingLeft: "35px", fontSize:"14px"}}
            />
            <FontAwesomeIcon icon={faSearch} className="select-search-icon" />
          </div>
        </div>
        <div className="col-md-2">
          <label className="assignefilter mx-2 mb-2" htmlFor="keywordFilter">
            Filter by Keyword:
          </label>
          <div className="select-wrapper ">
            <input
              type="text"
              placeholder="Search by keyword"
              value={searchQueryByKeyword}
              onChange={handleSearchKeywordChange}
              className="form-control"
              style={{ paddingLeft: "35px",fontSize:"14px"}}
            />
            <FontAwesomeIcon icon={faSearch} className="select-search-icon" />
          </div>
          <div className="buttonscontainer">
    <button className="excelbutton" onClick={exportToExcel}>
      Export to Excel
    </button>

    <button className="excelbutton" onClick={exportToPDF}>
      Export to PDF
    </button>
  </div>
       
        </div>
        <div className="hours d-flex ">
        {" "}
        <div>
          Total Hours: {hours} hours and {minutes} minutes
        </div>
        <div className="mx-5 "> Total Mandays: {manDays} Mandays</div>
      </div>
      </div>
   

      <div>
        {filteredTasks.length === 0 ? (
          <p>No tasks assigned.</p>
        ) : (
          <table className="tableFixHead2 tablecontent">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Task</th>
                <th>Project Name</th>
                <th>Description</th>
                <th>Assigned By</th>
                <th>Assignee</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Task Created On</th>
                <th>Task Deadline</th>
                <th>Hours To Complete</th>
                <th>Challenges/issues</th>
                <th>Assignee Email</th>
              </tr>
            </thead>
            <tbody>
              {filteredTasks.map((task, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: isHighlighted(task._id)
                      ? "yellow"
                      : "transparent",
                  }}
                >
                  <td className="">{index + 1}</td>
                  <td
                    className=""
                    style={{
                      minWidth: "200px",
                      maxWidth: "200px",
                      overflow: "auto",
                    }}
                  >
                    {highlightText(task.task)}
                  </td>
                  <td
                    style={{
                      minWidth: "150px",
                      maxWidth: "150px",
                      overflow: "auto",
                    }}
                  >
                    {highlightText(task.projectname)}
                  </td>
                  <td
                    style={{
                      minWidth: "400px",
                      maxWidth: "400px",
                      overflow: "auto",
                    }}
                  >
                    {highlightText(task.description)}
                  </td>
                  <td>{highlightText(task.assignedByName)}</td>
                  <td>
                    {task.assignedTo
                      .map((user) => highlightText(user.name))
                      .join(", ")}
                  </td>

                  <td>{highlightText(task.priority)}</td>

                  <td>
                    {highlightText(
                      task.status === "Completed/Not Validated" &&
                        task.validationStatus === "validated"
                        ? "Completed"
                        : task.status
                    )}
                  </td>
                  <td>
                    {" "}
                    {highlightText(
                      new Date(task.createdAt).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })
                    )}
                  </td>
                  <td>
                    {highlightText(
                      new Date(task.deadline).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })
                    )}
                  </td>
                  <td>
                    {task.validatedAt && task.createdAt ? (
                      <>
                        {calculateHoursDifference(
                          task.createdAt,
                          task.validatedAt
                        )}{" "}
                        hours
                      </>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                      maxWidth: "200px",
                      overflow: "auto",
                      wordWrap: "break-word",
                    }}
                  >
                    {highlightText(task.completionNote)}
                  </td>

                  <td>{highlightText(task.emailTo.join(", "))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div> */}
      <div className="row fixed-column serial-no">
  <div className="col-md-2 mb-2 filler">
    <label htmlFor="projectFilter" className="reports mx-2 mb-2">
      Filter by Project:
    </label>
    <div className="select-wrapper">
      <select
        id="projectFilter"
        onChange={handleProjectChange}
        className="form-control"
      >
        <option value="">All Projects</option>
        {projects.map((project) => (
          <option key={project} value={project}>
            {project}
          </option>
        ))}
      </select>
      <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
    </div>
  </div>

  <div className="col-md-2 filler">
    <label className="assignefilter mx-2 mb-2" htmlFor="dateFilter">
      Filter by Date:
    </label>
    <div className="select-wrapper">
      <select
        id="dateFilter"
        onChange={handleDateFilterChange}
        className="form-control"
      >
        <option value="">All Dates</option>
        <option value="today">Today</option>
        <option value="week">This Week</option>
        <option value="month">This Month</option>
        <option value="quarter">This Quarter</option>
        <option value="sixMonths">Last 6 Months</option>
        <option value="year">Last 12 Months</option>
      </select>
      <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
    </div>
  </div>

  <div className="col-md-2 mb-2 filler">
    <label className="assignefilter mb-2" htmlFor="statusFilter">
      Filter by Status:
    </label>
    <div className="select-wrapper">
      <select
        id="statusFilter"
        onChange={handleStatusFilterChange}
        className="form-control"
      >
        <option value="">All Statuses</option>
        <option value="Not Started">Not Started</option>
        <option value="WIP">WIP</option>
        <option value="Incomplete">Incomplete</option>
        <option value="Completed/Not Validated">Completed/N-Val</option>
        <option value="Completed">Completed</option>
      </select>
      <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
    </div>
  </div>

  <div className="col-md-2">
    <label className="assignefilter mx-2 mb-2" htmlFor="assigneFilter">
      Filter by Assignee:
    </label>
    <div className="select-wrapper">
      <input
        type="text"
        placeholder="Filter by Assignee"
        value={searchQuery}
        onChange={handleSearchChange}
        className="form-control"
        style={{ paddingLeft: "35px", fontSize: "14px" }}
      />
      <FontAwesomeIcon icon={faSearch} className="select-search-icon" />
    </div>
  </div>

  <div className="col-md-2">
    <label className="assignefilter mx-2 mb-2" htmlFor="keywordFilter">
      Filter by Keyword:
    </label>
    <div className="select-wrapper">
      <input
        type="text"
        placeholder="Search by keyword"
        value={searchQueryByKeyword}
        onChange={handleSearchKeywordChange}
        className="form-control"
        style={{ paddingLeft: "35px", fontSize: "14px" }}
      />
      <FontAwesomeIcon icon={faSearch} className="select-search-icon" />
    </div>
    <div className="buttonscontainer">
      <button className="excelbutton" onClick={exportToExcel}>
        Export to Excel
      </button>

      <button className="excelbutton" onClick={exportToPDF}>
        Export to PDF
      </button>
    </div>
  </div>

  <div className="hours d-flex">
    <div>
      Total Hours: {hours} hours and {minutes} minutes
    </div>
    <div className="mx-5">Total Mandays: {manDays} Mandays</div>
  </div>
</div>


<div className="table-container">
  {filteredTasks.length === 0 ? (
    <p className="mobSize">No tasks assigned.</p>
  ) : (
    <table className="tableFixHead2 tablecontent">
      <thead>
        <tr>
          <th>Serial No</th>
          <th>Task</th>
          <th>Project Name</th>
          <th>Description</th>
          <th>Assigned By</th>
          <th>Assignee</th>
          <th>Priority</th>
          <th>Status</th>
          <th>Task Created On</th>
          <th>Task Deadline</th>
          <th>Hours To Complete</th>
          <th>Challenges/issues</th>
          <th>Assignee Email</th>
        </tr>
      </thead>
      <tbody>
        {filteredTasks.map((task, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: isHighlighted(task._id)
                ? "yellow"
                : "transparent",
            }}
          >
            <td>{index + 1}</td>
            <td style={{}}>
              {highlightText(task.task)}
            </td>
            <td style={{  }}>
              {highlightText(task.projectname)}
            </td>
            <td style={{}}>
              {highlightText(task.description)}
            </td>
            <td>{highlightText(task.assignedByName)}</td>
            <td>
              {task.assignedTo
                .map((user) => highlightText(user.name))
                .join(", ")}
            </td>
            <td>{highlightText(task.priority)}</td>
            <td>
              {highlightText(
                task.status === "Completed/Not Validated" &&
                  task.validationStatus === "validated"
                  ? "Completed"
                  : task.status
              )}
            </td>
            <td>
              {highlightText(
                new Date(task.createdAt).toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })
              )}
            </td>
            <td>
              {highlightText(
                new Date(task.deadline).toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                })
              )}
            </td>
            <td>
              {task.validatedAt && task.createdAt ? (
                <>
                  {calculateHoursDifference(task.createdAt, task.validatedAt)}{" "}
                  hours
                </>
              ) : (
                "N/A"
              )}
            </td>
            <td
              style={{
                minWidth: "200px",
                maxWidth: "200px",
                whiteSpace:"normal",
                wordWrap: "break-word",
                textAlign:"left",
              }}
            >
              {highlightText(task.completionNote)}
            </td>
            <td>{highlightText(task.emailTo.join(", "))}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
</div>
    </>
  );
};

export default Reports;


// import React, { useEffect, useState } from "react";
// import { UseAuth } from "../store/auth";
// import { useLocation } from "react-router-dom";
// import * as XLSX from "xlsx";
// import "./AdminTaskToUser.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleDown, faSearch } from "@fortawesome/free-solid-svg-icons";
// const Reports = () => {
//   const [assignedTasks, setAssignedTasks] = useState([]);
//   const [projects, setProjects] = useState([]);
//   const [selectedProject, setSelectedProject] = useState("");
//   const [dateFilter, setDateFilter] = useState("");
//   const [statusFilter, setStatusFilter] = useState("");
//   const { authorizationToken } = UseAuth();
//   const [searchQuery, setSearchQuery] = useState("");
//   const [searchQueryByKeyword, setSearchQueryByKeyword] = useState("");
//   const API_URL = process.env.REACT_APP_API_URL;
//   const API_KEY = process.env.REACT_APP_API_KEY;

//   // Get the task ID from query parameters
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const highlightTaskId = queryParams.get("highlight");

//   const fetchAssignedTasks = async () => {
//     try {
//       const response = await fetch(`${API_URL}/admin/tasks`, {
//         method: "GET",

//         headers: {
//           "x-api-key": API_KEY,
//           Authorization: authorizationToken,
//         },
//       });
//       const data = await response.json();
//       setAssignedTasks(data.reverse());
//       const uniqueProjects = [...new Set(data.map((task) => task.projectname))];
//       setProjects(uniqueProjects);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchAssignedTasks();
//   }, [authorizationToken]);

//   const handleProjectChange = (e) => {
//     setSelectedProject(e.target.value);
//   };

//   const handleDateFilterChange = (e) => {
//     setDateFilter(e.target.value);
//   };

//   const handleStatusFilterChange = (e) => {
//     setStatusFilter(e.target.value);
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSearchKeywordChange = (e) => {
//     setSearchQueryByKeyword(e.target.value);
//   };

//   const filterTasks = () => {
//     let filteredTasks = assignedTasks;

//     if (selectedProject) {
//       filteredTasks = filteredTasks.filter(
//         (task) => task.projectname === selectedProject
//       );
//     }

//     if (dateFilter) {
//       const now = new Date();
//       const filterDate = new Date();

//       switch (dateFilter) {
//         case "today":
//           filterDate.setHours(0, 0, 0, 0);
//           filteredTasks = filteredTasks.filter(
//             (task) =>
//               new Date(task.createdAt).toDateString() === now.toDateString()
//           );
//           break;
//         case "week":
//           filterDate.setDate(now.getDate() - 7);
//           filterDate.setHours(0, 0, 0, 0);
//           filteredTasks = filteredTasks.filter(
//             (task) =>
//               new Date(task.createdAt) >= filterDate &&
//               new Date(task.createdAt) <= now
//           );
//           break;
//         case "month":
//           const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
//           const endOfMonth = new Date(
//             now.getFullYear(),
//             now.getMonth() + 1,
//             0,
//             23,
//             59,
//             59
//           );
//           filteredTasks = filteredTasks.filter(
//             (task) =>
//               new Date(task.createdAt) >= startOfMonth &&
//               new Date(task.createdAt) <= endOfMonth
//           );
//           break;
//         case "quarter":
//           const currentQuarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
//           const quarterStart = new Date(
//             now.getFullYear(),
//             currentQuarterStartMonth,
//             1
//           );
//           filteredTasks = filteredTasks.filter(
//             (task) =>
//               new Date(task.createdAt) >= quarterStart &&
//               new Date(task.createdAt) <= now
//           );
//           break;
//         case "sixMonths":
//           filterDate.setMonth(now.getMonth() - 6);
//           filterDate.setDate(1);
//           filterDate.setHours(0, 0, 0, 0);
//           filteredTasks = filteredTasks.filter(
//             (task) =>
//               new Date(task.createdAt) >= filterDate &&
//               new Date(task.createdAt) <= now
//           );
//           break;
//         case "year":
//           filterDate.setFullYear(now.getFullYear() - 1);
//           filterDate.setMonth(0);
//           filterDate.setDate(1);
//           filterDate.setHours(0, 0, 0, 0);
//           filteredTasks = filteredTasks.filter(
//             (task) =>
//               new Date(task.createdAt) >= filterDate &&
//               new Date(task.createdAt) <= now
//           );
//           break;
//         default:
//           break;
//       }
//     }

//     // if (statusFilter) {
//     //   filteredTasks = filteredTasks.filter(
//     //     (task) => task.status === statusFilter
//     //   );
//     // }

//     if (statusFilter) {
//       filteredTasks = filteredTasks.filter((task) => {
//         if (statusFilter === "Completed") {
//           return (
//             task.status === "Completed/Not Validated" &&
//             task.validationStatus === "validated"
//           );
//         }
//         if (statusFilter === "Completed/Not Validated") {
//           return (
//             task.status === "Completed/Not Validated" &&
//             task.validationStatus !== "validated"
//           );
//         }
//         return task.status === statusFilter;
//       });
//     }

//     if (searchQuery) {
//       const searchString = searchQuery.toLowerCase();
//       filteredTasks = filteredTasks.filter((task) => {
//         const nameMatch = task.assignedTo.some((user) =>
//           user.name.toLowerCase().includes(searchString)
//         );
//         const emailMatch = task.emailTo.some((email) =>
//           email.toLowerCase().includes(searchString)
//         );
//         return nameMatch || emailMatch;
//       });
//     }

//     if (searchQueryByKeyword) {
//       const searchString = searchQueryByKeyword.toLowerCase();
//       filteredTasks = filteredTasks.filter((task) => {
//         return (
//           task.task.toLowerCase().includes(searchString) ||
//           task.description.toLowerCase().includes(searchString) ||
//           task.assignedTo.some((user) =>
//             user.name.toLowerCase().includes(searchString)
//           ) ||
//           task.emailTo.some((email) =>
//             email.toLowerCase().includes(searchString)
//           )
//         );
//       });
//     }

//     // Highlighted task sorting
//     if (highlightTaskId) {
//       filteredTasks = filteredTasks.sort((a, b) => {
//         if (a._id === highlightTaskId) return -1; // Move highlighted task to the top
//         if (b._id === highlightTaskId) return 1; // Move highlighted task to the top
//         return 0; // Maintain the original order for other tasks
//       });
//     }

//     return filteredTasks;
//   };

//   const filteredTasks = filterTasks();

//   // Highlight task logic
//   const isHighlighted = (taskId) => {
//     return taskId === highlightTaskId;
//   };

//   const highlightText = (text) => {
//     if (!searchQueryByKeyword || typeof text !== "string") return text;

//     const parts = text.split(new RegExp(`(${searchQueryByKeyword})`, "gi"));
//     return parts.map((part, index) =>
//       part.toLowerCase() === searchQueryByKeyword.toLowerCase() ? (
//         <mark key={index}>{part}</mark>
//       ) : (
//         part
//       )
//     );
//   };
//   const exportToExcel = () => {
//     // Convert tasks to a format suitable for Excel
//     const tasksForExcel = filteredTasks.map((task) => ({
//       task: task.task,
//       assignedByName: task.assignedByName,
//       description: task.description,
//       priority: task.priority,
//       status:
//         task.status === "Completed/Not Validated" &&
//         task.validationStatus === "validated"
//           ? "Completed"
//           : task.status,
//       createdAt: new Date(task.createdAt).toLocaleString("en-GB", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "2-digit",
//         hour: "2-digit",
//         minute: "2-digit",
//         second: "2-digit",
//         hour12: true,
//       }),
//       deadline: new Date(task.deadline).toLocaleString("en-GB", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "2-digit",
//         hour: "2-digit",
//         minute: "2-digit",
//         second: "2-digit",
//         hour12: true,
//       }),
//       assignedTo: task.assignedTo.map((user) => user.name).join(", "),
//       projectname: task.projectname,
//       emailTo: task.emailTo.join(", "),
//       completionNote: task.completionNote,
//     }));

//     // Create a worksheet from the tasks
//     const ws = XLSX.utils.json_to_sheet(tasksForExcel, {
//       header: [
//         "task",
//         "assignedByName",
//         "description",
//         "priority",
//         "status",
//         "createdAt",
//         "deadline",
//         "assignedTo",
//         "projectname",
//         "emailTo",
//         "completionNote",
//       ],
//     });

//     // Create a new workbook and append the worksheet
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Tasks");

//     // Write the workbook to a file
//     XLSX.writeFile(wb, "Filtered_Tasks.xlsx");
//   };

//   return (
//     <>
//         <div className="AdminPage">
//   {/* Header Section */}
//   <div className="header">
//     <div className="header-left">
//       <img src="user-profile-image-url" alt="User Profile" className="profile-pic" />
//       <span className="user-name">Rahul.khaire</span>
//       <span class="material-symbols-outlined">
// notifications
// </span>
//     </div>
//     {/* <div className="header-right">
//       <span className="material-symbols-outlined notification-icon">notifications</span>
//       <span className="current-time">09:55 AM</span>
//     </div> */}
//   </div>
//   </div>
//       <div className="row fixed-column serial-no">
//         <div className="col-md-2">
//           <label htmlFor="projectFilter" className="assignefilter mx-2 mb-2">
//             Filter by Project:
//           </label>
//           <div className="select-wrapper">
//             <select
//               id="projectFilter"
//               onChange={handleProjectChange}
//               className="form-control"
//               style={{fontSize:"14px" }}
//             >
//               <option value="">All Projects</option>
//               {projects.map((project) => (
//                 <option key={project} value={project}>
//                   {project}
//                 </option>
//               ))}
//             </select>
//             <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
//           </div>
//         </div>

//         <div className="col-md-2">
//           <label className="assignefilter mx-2 mb-2" htmlFor="dateFilter">
//             Filter by Date:
//           </label>
//           <div className="select-wrapper">
//             <select
//               id="dateFilter"
//               onChange={handleDateFilterChange}
//               className="form-control"
//               style={{fontSize:"14px" }}
//             >
//               <option value="">All Dates</option>
//               <option value="today">Today</option>
//               <option value="week">This Week</option>
//               <option value="month">This Month</option>
//               <option value="quarter">This Quarter</option>
//               <option value="sixMonths">Last 6 Months</option>
//               <option value="year">Last 12 Months</option>
//             </select>
//             <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
//           </div>
//         </div>

//         <div className="col-md-2">
//           <label className="assignefilter mx-2 mb-2" htmlFor="statusFilter">
//             Filter by Status:
//           </label>
//           <div className="select-wrapper">
//             <select
//               id="statusFilter"
//               onChange={handleStatusFilterChange}
//               className="form-control"
//               style={{  fontSize:"14px" }}
//             >
//               <option value="">All Statuses</option>
//               <option value="Not Started">Not Started</option>
//               <option value="WIP">WIP</option>
//               <option value="Incomplete">Incomplete</option>
//               <option value="Completed/Not Validated">Completed/N-Val</option>
//               <option value="Completed">Completed</option>
//             </select>
//             <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
//           </div>
//         </div>
//         <div className="col-md-2">
//           <label className="assignefilter mx-2 mb-2" htmlFor="assigneFilter">
//             Filter by Assignee:
//           </label>
//           <div className="select-wrapper ">
//             <input
//               type="text"
//               placeholder="Filter by Assignee "
//               value={searchQuery}
//               onChange={handleSearchChange}
//               className=" form-control"
//               style={{ paddingLeft: "35px", fontSize:"14px" }}
//             />
//             <FontAwesomeIcon icon={faSearch} className="select-search-icon" />
//           </div>
//         </div>
//         <div className="col-md-2">
//           <label className="assignefilter mx-2 mb-2" htmlFor="keywordFilter">
//             Filter by Keyword:
//           </label>
//           <div className="select-wrapper ">
//             <input
//               type="text"
//               placeholder="Search by keyword"
//               value={searchQueryByKeyword}
//               onChange={handleSearchKeywordChange}
//               className="form-control"
//               style={{ paddingLeft: "35px",fontSize:"14px"  }}
//             />
//             <FontAwesomeIcon icon={faSearch} className="select-search-icon" />
//           </div>
//         </div>

//         <div className="col-md-2 mb-2 mt-2 ">
//           <button
//             className="exportbutton"
//             onClick={exportToExcel}
//           >
//             Export to Excel
//           </button>
//            <button className="excelbutton" onClick={exportToPDF}>
//       Export to PDF
//     </button>
//         </div>
//       </div>

//       {/* <div>
//         {filteredTasks.length === 0 ? (
//           <p>No tasks assigned.</p>
//         ) : (
//           <table className="tableFixHead2 tablecontent">
//             <thead>
//               <tr>
//                 <th className="fixed-column serial-no">Sr No</th>
//                 <th className="fixed-column task">Task</th>
//                 <th>Project Name</th>
//                 <th>Description</th>
//                 <th>Assigned By</th>
//                 <th>Assignee</th>
//                 <th>Priority</th>
//                 <th>Status</th>
//                 <th>Task Created On</th>
//                 <th>Task Deadline</th>
//                 <th>Challenges/issues</th>
//                 <th>Assignee Email</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredTasks.map((task, index) => (
//                 <tr
//                   key={index}
//                   style={{
//                     backgroundColor: isHighlighted(task._id)
//                       ? "yellow"
//                       : "transparent",
//                   }}
//                 >
//                   <td className="fixed-column serial-no sr">{index + 1}</td>
//                   <td
//                     className="fixed-column task sr"
//                     style={{
//                       minWidth: "200px",
//                       maxWidth: "200px",
//                       overflow: "auto",
//                     }}
//                   >
//                     {highlightText(task.task)}
//                   </td>
//                   <td
//                     style={{
//                       minWidth: "150px",
//                       maxWidth: "150px",
//                       overflow: "auto",
//                     }}
//                   >
//                     {highlightText(task.projectname)}
//                   </td>
//                   <td
//                     style={{
//                       minWidth: "400px",
//                       maxWidth: "400px",
//                       overflow: "auto",
//                     }}
//                   >
//                     {highlightText(task.description)}
//                   </td>
//                   <td>{highlightText(task.assignedByName)}</td>
//                   <td>
//                     {task.assignedTo
//                       .map((user) => highlightText(user.name))
//                       .join(", ")}
//                   </td>

//                   <td>{highlightText(task.priority)}</td>

//                   <td>
//                     {highlightText(
//                       task.status === "Completed/Not Validated" &&
//                         task.validationStatus === "validated"
//                         ? "Completed"
//                         : task.status
//                     )}
//                   </td>
//                   <td>
//                     {" "}
//                     {highlightText(
//                       new Date(task.createdAt).toLocaleString("en-GB", {
//                         day: "2-digit",
//                         month: "2-digit",
//                         year: "2-digit",
//                         hour: "2-digit",
//                         minute: "2-digit",
//                         second: "2-digit",
//                         hour12: true,
//                       })
//                     )}
//                   </td>
//                   <td>
//                     {highlightText(
//                       new Date(task.deadline).toLocaleString("en-GB", {
//                         day: "2-digit",
//                         month: "2-digit",
//                         year: "2-digit",
//                         hour: "2-digit",
//                         minute: "2-digit",
//                         second: "2-digit",
//                         hour12: true,
//                       })
//                     )}
//                   </td>

//                   <td
//                     style={{
//                       minWidth: "200px",
//                       maxWidth: "200px",
//                       overflow: "auto",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     {highlightText(task.completionNote)}
//                   </td>

//                   <td>{highlightText(task.emailTo.join(", "))}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         )}
//       </div> */}
//  <div>
//   {filteredTasks.length === 0 ? (
//     <p>No tasks assigned.</p>
//   ) : (
//     // <div style={{  overflowY: 'auto', paddingLeft: '20px' }}> {/* Scrollable container */}
//     <div className="scrollbarscontainer">
//       {/* <table className="tablecontent" style={{ width: '100%', borderCollapse: 'collapse' }}>
//         <thead>
//           <tr>
//             <th>Sr No</th>
//             <th>Task</th>
//             <th>Project Name</th>
//             <th>Description</th>
//             <th>Assigned By</th>
//             <th>Assignee</th>
//             <th>Priority</th>
//             <th>Status</th>
//             <th>Task Created</th>
//             <th>Task Deadline</th>
//             <th style={{ width: "300px" }}>Challenges/issues</th>
//             <th>Assignee Email</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredTasks.map((task, index) => (
//             <tr
//               key={index}
//               style={{
//                 backgroundColor: isHighlighted(task._id) ? "yellow" : "transparent",
//               }}
//             >
//               <td>{index + 1}</td>
//               <td style={{ minWidth: "200px", maxWidth: "200px", overflow: "auto" }}>
//                 {highlightText(task.task)}
//               </td>
//               <td style={{ minWidth: "150px", maxWidth: "150px", overflow: "auto" }}>
//                 {highlightText(task.projectname)}
//               </td>
//               <td style={{ minWidth: "400px", maxWidth: "400px", overflow: "auto" }}>
//                 {highlightText(task.description)}
//               </td>
//               <td>{highlightText(task.assignedByName)}</td>
//               <td>
//                 {task.assignedTo.map((user) => highlightText(user.name)).join(", ")}
//               </td>
//               <td>{highlightText(task.priority)}</td>
//               <td>
//                 {highlightText(
//                   task.status === "Completed/Not Validated" && task.validationStatus === "validated"
//                     ? "Completed"
//                     : task.status
//                 )}
//               </td>
//               <td>
//                 {highlightText(
//                   new Date(task.createdAt).toLocaleString("en-GB", {
//                     day: "2-digit",
//                     month: "2-digit",
//                     year: "2-digit",
//                     hour: "2-digit",
//                     minute: "2-digit",
//                     second: "2-digit",
//                     hour12: true,
//                   })
//                 )}
//               </td>
//               <td>
//                 {highlightText(
//                   new Date(task.deadline).toLocaleString("en-GB", {
//                     day: "2-digit",
//                     month: "2-digit",
//                     year: "2-digit",
//                     hour: "2-digit",
//                     minute: "2-digit",
//                     second: "2-digit",
//                     hour12: true,
//                   })
//                 )}
//               </td>
             
//                   <td style={{ width: "300px" }}>{highlightText(task.completionNote)}</td>
//               <td>{highlightText(task.emailTo.join(", "))}
                
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table> */}
//       <table className="tablecontent" style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
//   <thead>
//     <tr>
//       <th style={{ width: "50px" }}>Sr No</th>
//       <th style={{ width: "200px" }}>Task</th>
//       <th style={{ width: "150px" }}>Project Name</th>
//       <th style={{ width: "400px" }}>Description</th>
//       <th style={{ width: "150px" }}>Assigned By</th>
//       <th style={{ width: "200px" }}>Assignee</th>
//       <th style={{ width: "100px" }}>Priority</th>
//       <th style={{ width: "150px" }}>Status</th>
//       <th style={{ width: "200px" }}>Task Created</th>
//       <th style={{ width: "200px" }}>Task Deadline</th>
//       <th style={{ width: "300px" }}>Challenges/issues</th>
//       <th style={{ width: "200px" }}>Assignee Email</th>
//     </tr>
//   </thead>
//   <tbody>
//     {filteredTasks.map((task, index) => (
//       <tr key={index} style={{ backgroundColor: isHighlighted(task._id) ? "yellow" : "transparent" }}>
//         <td style={{ width: "50px" }}>{index + 1}</td>
//         <td style={{ width: "200px" }}>{highlightText(task.task)}</td>
//         <td style={{ width: "150px" }}>{highlightText(task.projectname)}</td>
//         <td style={{ width: "400px" }}>{highlightText(task.description)}</td>
//         <td style={{ width: "150px" }}>{highlightText(task.assignedByName)}</td>
//         <td style={{ width: "200px" }}>
//           {task.assignedTo.map((user) => highlightText(user.name)).join(", ")}
//         </td>
//         <td style={{ width: "100px" }}>{highlightText(task.priority)}</td>
//         <td style={{ width: "150px" }}>
//           {highlightText(
//             task.status === "Completed/Not Validated" && task.validationStatus === "validated"
//               ? "Completed"
//               : task.status
//           )}
//         </td>
//         <td style={{ width: "200px" }}>
//           {highlightText(
//             new Date(task.createdAt).toLocaleString("en-GB", {
//               day: "2-digit",
//               month: "2-digit",
//               year: "2-digit",
//               hour: "2-digit",
//               minute: "2-digit",
//               second: "2-digit",
//               hour12: true,
//             })
//           )}
//         </td>
//         <td style={{ width: "200px" }}>
//           {highlightText(
//             new Date(task.deadline).toLocaleString("en-GB", {
//               day: "2-digit",
//               month: "2-digit",
//               year: "2-digit",
//               hour: "2-digit",
//               minute: "2-digit",
//               second: "2-digit",
//               hour12: true,
//             })
//           )}
//         </td>
//         <td style={{ width: "300px" }}>{highlightText(task.completionNote)}</td>
//         <td style={{ width: "200px" }}>{highlightText(task.emailTo.join(", "))}</td>
//       </tr>
//     ))}
//   </tbody>
// </table>

//     </div>
//   )}
// </div>


//     </>
//   );
// };

// export default Reports;
