import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFile,
} from "@fortawesome/free-solid-svg-icons";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const FileHandler = ({ file, index, handleImageClick }) => {
  const fileUrl = `${API_URL}/${file}`;
  const fileExt = file.split(".").pop().toLowerCase();

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (["jpg", "jpeg", "png", "gif"].includes(fileExt)) {
      const fetchImageWithHeaders = async () => {
        try {
          const response = await fetch(fileUrl, {
            headers: {
              "x-api-key": API_KEY,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch image");
          }

          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          setImageSrc(objectUrl);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      };

      fetchImageWithHeaders();
    }
  }, [fileUrl, fileExt]);

  const downloadFileWithApiKey = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl, {
        headers: {
          "x-api-key": API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = file.split("/").pop(); // Extract file name from URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl); // Clean up
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  if (["jpg", "jpeg", "png", "gif"].includes(fileExt)) {
    // Handle image files
    return (
      <img
        key={index}
        src={imageSrc}
        alt={`Task ${index} image`}
        style={{
          width: "30px",
          height: "30px",
          cursor: "pointer",
          margin: "2px",
        }}
        onClick={() => handleImageClick(imageSrc)}
      />
    );
  } else if (["pdf", "doc", "docx", "xls", "xlsx"].includes(fileExt)) {
    // Handle downloadable files (PDF, Word, Excel)
    return (
      <a
        key={index}
        href="#"
        style={{ margin: "2px" }}
        onClick={(e) => {
          e.preventDefault();
          downloadFileWithApiKey(fileUrl);
        }}
      >
        <FontAwesomeIcon
          icon={
            fileExt === "pdf"
              ? faFilePdf
              : fileExt === "doc" || fileExt === "docx"
              ? faFileWord
              : fileExt === "xls" || fileExt === "xlsx"
              ? faFileExcel
              : faFile
          }
          style={{ fontSize: "20px" }}
        />
      </a>
    );
  } else {
    // Handle unknown file types
    return (
      <a
        key={index}
        href="#"
        style={{ margin: "2px" }}
        onClick={(e) => {
          e.preventDefault();
          downloadFileWithApiKey(fileUrl);
        }}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "20px" }} />
      </a>
    );
  }
};

export default FileHandler;
