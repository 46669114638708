import React, { useState, useEffect } from "react";
import { UseAuth } from "../store/auth";
import DatePicker from "react-datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMinus, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const ProjectDetails = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [error, setError] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [deadline, setDeadline] = useState(new Date());
  const [showForm, setShowForm] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const { authorizationToken } = UseAuth();
  const [alldetails, setalldetails] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null); // State to manage open accordion
  const [reason, setReason] = useState("");
  const [newdeadline, setnewDeadline] = useState(new Date());
  const [showExtendForm, setShowExtendForm] = useState(false);
  const [proname, setprojectname] = useState("");
  const [showAddTeamForm, setShowAddTeamForm] = useState(false);

  const getAllUsersData = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/taskToUser`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setUsers(
        data.map((user) => ({ value: user._id, label: user.email, ...user }))
      );
    } catch (error) {
      toast.error("Failed to fetch users data.");
      setError("Failed to fetch users data.");
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, []);

  const handleUserChange = (selectedOptions) => {
    setSelectedUser(selectedOptions);
    const selectedUserIds = selectedOptions.map((option) => option.value);
    const selectedUsersData = users.filter((user) =>
      selectedUserIds.includes(user.value)
    );
    setSelectedUsersData(selectedUsersData);
    setIsUserSelected(selectedUsersData.length > 0);
  };

  // Submit project deadline extension request
  const submitExtensionRequest = async (projectId) => {
    try {
      const response = await fetch(`${API_URL}/projects/extend/${projectId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          newDeadline: newdeadline,
          reason,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Extension request submitted for approval!");
        setReason("");
        setShowExtendForm(false);
        fetchProjectDetails(); // Fetch updated project details
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.log("Error submitting extension request:", error);
    }
  };

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index); // Toggle accordion open/close
  };

  // Fetch assigned tasks
  const fetchAssignedTasks = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/tasks`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setalldetails(data);
      const uniqueProjects = [
        ...new Set(data.map((task) => task.projectname).filter((name) => name)),
      ];
      setProjects(
        uniqueProjects.map((project) => ({ value: project, label: project }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Submit project details
  const submitProjectDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/projectsdetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          projectname: proname,
          description,
          startDate,
          deadline,
          selectedUser,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Project details saved successfully!");
        setDescription("");
        setSelectedProject("");
        setStartDate(new Date());
        setDeadline(new Date());
        setShowForm(false);
        fetchProjectDetails(); // Fetch updated project details
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.log("Error submitting project details:", error);
    }
  };

  // Fetch project details
  const fetchProjectDetails = async () => {
    try {
      const response = await fetch(`${API_URL}/projects`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });
      const data = await response.json();
      setProjectDetails(data);
    } catch (error) {
      console.log("Error fetching project details:", error);
    }
  };

  const calculateWorkHoursAndDays = (startDate, deadline) => {
    const start = new Date(startDate);
    const end = new Date(deadline);

    // Calculate total milliseconds between two dates
    const totalMilliseconds = end - start;

    // Convert milliseconds to total days
    const totalDays = Math.ceil(totalMilliseconds / (1000 * 60 * 60 * 24));

    // Calculate total hours (8 hours per workday)
    const totalHours = totalDays * 8;

    return { totalDays, totalHours };
  };
  // Function to convert total hours to hours and minutes
  const convertHoursToHrsMins = (totalHours) => {
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);
    return `${hours}h ${minutes}m`;
  };
  // Function to calculate workdays
  const calculateWorkDays = (totalHours) => {
    return Math.floor(totalHours / 8);
  };

  // Calculate task hours for a specific project
  const calculateTaskHours = (tasks, projectName) => {
    let totalTaskHours = 0;
    let totalTaskDays = 0;

    tasks.forEach((task) => {
      if (
        task.createdAt &&
        task.validatedAt &&
        task.projectname === projectName
      ) {
        const start = new Date(task.createdAt);
        const end = new Date(task.validatedAt);

        // Calculate time difference in milliseconds
        const timeDifference = end - start;

        // Convert milliseconds to hours and days
        totalTaskHours += timeDifference / (1000 * 60 * 60);
        totalTaskDays += Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      }
    });

    return { totalTaskDays, totalTaskHours };
  };

  useEffect(() => {
    fetchAssignedTasks();
    fetchProjectDetails(); // Fetch project details on component mount
  }, [authorizationToken]);

  const handleDeleteTeamMember = async (projectId, userId) => {
    try {
      const response = await fetch(
        `${API_URL}/projects/${projectId}/team/${userId}`,
        {
          method: "DELETE",
          headers: {
            "x-api-key": API_KEY,
            Authorization: authorizationToken,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success("Team member removed successfully!");
        fetchProjectDetails(); // Fetch updated project details
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.log("Error removing team member:", error);
      toast.error("Error removing team member.");
    }
  };

  const addTeamMembers = async (projectId) => {
    try {
      const response = await fetch(`${API_URL}/projects/${projectId}/team`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          selectedUser: selectedUser.map((user) => user.value), // Sending user IDs
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("Team members added successfully!");
        fetchProjectDetails(); // Refresh project details
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      console.log("Error adding team members:", error);
      toast.error("Error adding team members.");
    }
  };

  return (
    <div className="container" style={{ padding: "20px" }}>
      <h2 className="projectdetails">Project Details </h2>
      {/* Plus Button to toggle form */}

      <button
        className="Add_Project"
        onClick={() => setShowForm(!showForm)}
      >
        {showForm ? "Cancel" : "Add New Project"}
      </button>
      {showForm && (
        <form onSubmit={submitProjectDetails}>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              New Project Name:
            </label>
            <textarea
              id="name"
              className="form-control"
              value={proname}
              onChange={(e) => setprojectname(e.target.value)}
              required
            />
          </div>

          {/* Start Date */}
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date:
            </label>
            <DatePicker
              id="start-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              placeholderText="Select start date and time"
              className="date-picker form-control"
              dateFormat="dd/MM/yyyy p"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required
              calendarClassName="custom-datepicker"
            />
          </div>

          {/* Deadline */}
          <div className="mb-3">
            <label htmlFor="deadline" className="form-label">
              Project Deadline:
            </label>
            <DatePicker
              id="deadline"
              selected={deadline}
              onChange={(date) => setDeadline(date)}
              showTimeSelect
              placeholderText="Select deadline date and time"
              className="date-picker form-control"
              dateFormat="dd/MM/yyyy p"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required
              calendarClassName="custom-datepicker"
            />
          </div>

          <div>
            <label htmlFor="users" className="mb-3">
              Add Team Members :
            </label>
            <Select
              id="users"
              isMulti
              options={users}
              styles={{ width: "338px" }}
              value={selectedUser}
              onChange={handleUserChange}
            />
          </div>

          {/* Description */}
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Project Description:
            </label>
            <textarea
              id="description"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="btn btn-primary mx-2">
            Save Project Details
          </button>
        </form>
      )}
      {/* Project Details Section */}
      <h3 className="ProjectsList">Project List</h3>

      <div className="accordion" id="projectAccordion">
        {projectDetails.map((project, index) => {
          const { totalDays, totalHours } = calculateWorkHoursAndDays(
            project.startDate,
            project.deadline
          );

          const relatedTasks = alldetails.filter(
            (task) => task.projectname === project.projectname
          );
          const taskHours = calculateTaskHours(
            relatedTasks,
            project.projectname
          );

          const formattedTotalTaskHours = convertHoursToHrsMins(
            taskHours.totalTaskHours
          );
          const workDays = calculateWorkDays(taskHours.totalTaskHours);

          const status =
            taskHours.totalTaskHours < totalHours
              ? "Ahead of Schedule"
              : taskHours.totalTaskHours > totalHours
                ? "Behind Schedule"
                : "On Schedule";

          const statusColor =
            taskHours.totalTaskHours < totalHours
              ? "success"
              : taskHours.totalTaskHours > totalHours
                ? "danger"
                : "info";

          return (
            <div className="card mb-3" key={project._id}>
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="projectname">{project.projectname}</h5>

                <button
                  className="btn d-flex align-items-center"
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={openAccordion === index}
                  style={{ textDecoration: "none", outline: "none" }}
                >
                  <span className={`badge bg-${statusColor} color-badge`}>{status}</span>
                  <i
                    className={`fas fa-chevron-${openAccordion === index ? "up" : "down"
                      } ms-2 text-dark`}
                  ></i>
                </button>
              </div>
              <div
                id={`collapse${index}`}
                className={`collapse ${openAccordion === index ? "show" : ""}`}
                aria-labelledby={`heading${index}`}
                data-bs-parent="#projectAccordion"
              >
                <div className="card-body">
                  <div className="row text-center">
                    <div className="col-md-2">
                      <p>
                        <strong>Start Date:</strong>{" "}
                        {new Date(project.startDate).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p>
                        <strong>End Date:</strong>{" "}
                        {new Date(project.deadline).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <strong>Estimated Completion:</strong> {totalDays} days
                        ({convertHoursToHrsMins(totalHours)})
                      </p>
                    </div>

                    <div className="col-md-4">
                      <p>
                        <strong>Total Task Hours:</strong>{" "}
                        {formattedTotalTaskHours} ({workDays} Man days)
                      </p>
                    </div>
                  </div><br /><br />
                  <div className="d-flexu downstream">
                    <div>
                      <label>
                        Team Members:{" "}
                        <button
                          className="AddMembers"
                          onClick={() => setShowAddTeamForm(!showAddTeamForm)}
                        >
                          {/* Show text for large screens */}
                          <span className="d-none d-md-inline">
                            {showAddTeamForm ? "Hide" : "Add Team Members"}
                          </span>

                          {/* Show icon for small screens */}
                          <FontAwesomeIcon icon={faUserPlus} className="d-md-none" />
                        </button>{" "}
                      </label>
                      <ul className="removemembers">
                        {project.selectedUserDetails.map((user) => (
                          <li key={user._id}>
                            {user.name} - {user.email}

                            <button
                              className="removemember"
                              onClick={() =>
                                handleDeleteTeamMember(project._id, user._id)
                              }
                            >
                              <span className="d-none d-md-inline">Remove</span> {/* Text visible on larger screens */}
                              <FontAwesomeIcon icon={faUserMinus} className="d-md-none" /> {/* Icon visible on small screens */}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <button
                        className="ExtendDeadline"
                        onClick={() => setShowExtendForm(!showExtendForm)}
                      >
                        {showExtendForm
                          ? "Cancel Extension"
                          : "Extend Deadline"}
                      </button>
                    </div>
                  </div>
                  {showAddTeamForm && (
                    <div>
                      <Select
                        isMulti
                        options={users} // Assuming `users` contains all available users
                        value={selectedUser}
                        onChange={handleUserChange} // Handle user selection
                      />
                      <button
                        className="btn btn-success mt-3 mx-5 mb-1 custom-btn"
                        onClick={() => addTeamMembers(project._id)}
                      >
                        Save Team Members
                      </button>
                    </div>
                  )}
                  <div className="d-flex">
                    {/* <div>
                      <button
                        className="ExtendDeadline"
                        onClick={() => setShowExtendForm(!showExtendForm)}
                      >
                        {showExtendForm
                          ? "Cancel Extension"
                          : "Extend Deadline"}
                      </button>
                    </div> */}
                  </div>
                  <div>
                    <p className="mx-5">
                      <strong>Description:</strong> {project.description}
                    </p>
                  </div>

                  {showExtendForm && (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        submitExtensionRequest(project._id);
                      }}
                    >
                      <div className="mb-3">
                        <label htmlFor="extend-reason" className="form-label">
                          Reason for Extension:
                        </label>
                        <textarea
                          id="extend-reason"
                          className="form-control"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="new-deadline" className="form-label">
                          New Deadline:
                        </label>
                        <DatePicker
                          id="new-deadline"
                          selected={newdeadline}
                          onChange={(date) => setnewDeadline(date)}
                          showTimeSelect
                          placeholderText="Select new deadline"
                          className="form-control"
                          dateFormat="dd/MM/yyyy p"
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-primary mx-5 custom-btn">
                        Submit Extension Request
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Toast Container for Notifications */}
      <ToastContainer />
    </div>
  );
};

export default ProjectDetails;
